var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "hero-twoPart overflow-hide"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "d-none d-xl-block",
    staticStyle: {
      "margin-top": "252px",
      "margin-left": "-100px"
    }
  }, [_c('HeroPCLeft')], 1), _c('div', {
    staticClass: "d-none d-xl-block",
    staticStyle: {
      "margin-top": "118px",
      "margin-right": "-170px"
    }
  }, [_c('HeroPCRight', {
    staticStyle: {
      "transform": "translateX(-40px)"
    }
  })], 1), _c('div', {
    staticClass: "d-none d-md-block d-xl-none",
    staticStyle: {
      "transform": "translate(-20px, 270px)"
    }
  }, [_c('HeroTabletLeft')], 1), _c('div', {
    staticClass: "d-none d-md-block d-xl-none",
    staticStyle: {
      "transform": "translate(-50px, 200px)"
    }
  }, [_c('HeroTabletRight')], 1)]), _c('div', {
    staticClass: "hero-content"
  }, [_c('h1', {
    staticClass: "hero-header light-theme-title text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.hero.header")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.$t("careers.hero.header2")))])]), _c('h4', {
    staticClass: "subtitle-bodyLarge-to-mobile-body-regular hero-subtitle text-center font-weight-normal text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.hero.subheader")) + " ")]), _c('div', {
    staticClass: "d-md-none"
  }, [_c('HeroMobileIcon')], 1), _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.gotoJobs
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.hero.find-button")) + " ")])])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }