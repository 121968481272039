var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M15.9999 7H7.99988C7.44759 7 6.99988 7.44772 6.99988 8V16C6.99988 16.5523 7.44759 17 7.99988 17H15.9999C16.5522 17 16.9999 16.5523 16.9999 16V8C16.9999 7.44772 16.5522 7 15.9999 7ZM7.99988 5C6.34302 5 4.99988 6.34315 4.99988 8V16C4.99988 17.6569 6.34302 19 7.99988 19H15.9999C17.6567 19 18.9999 17.6569 18.9999 16V8C18.9999 6.34315 17.6567 5 15.9999 5H7.99988Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.9999 11V13H12.9999V11H10.9999ZM9.99988 9C9.44759 9 8.99988 9.44772 8.99988 10V14C8.99988 14.5523 9.44759 15 9.99988 15H13.9999C14.5522 15 14.9999 14.5523 14.9999 14V10C14.9999 9.44772 14.5522 9 13.9999 9H9.99988Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M9 1.99805C8.44772 1.99805 8 2.44576 8 2.99805V4.99805H10V2.99805C10 2.44576 9.55229 1.99805 9 1.99805Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M10 20.998V18.998H8V20.998C8 21.5503 8.44772 21.998 9 21.998C9.55229 21.998 10 21.5503 10 20.998Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M14 2.99805C14 2.44576 14.4477 1.99805 15 1.99805C15.5523 1.99805 16 2.44576 16 2.99805V4.99805H14V2.99805Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 20.998V18.998H14V20.998C14 21.5503 14.4477 21.998 15 21.998C15.5523 21.998 16 21.5503 16 20.998Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M21 7.99805C21.5523 7.99805 22 8.44576 22 8.99805C22 9.55033 21.5523 9.99805 21 9.99805H19V7.99805H21Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 9.99805H5V7.99805H3C2.44772 7.99805 2 8.44576 2 8.99805C2 9.55033 2.44772 9.99805 3 9.99805Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M21 13.998C21.5523 13.998 22 14.4458 22 14.998C22 15.5503 21.5523 15.998 21 15.998H19V13.998H21Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 15.998H5V13.998H3C2.44772 13.998 2 14.4458 2 14.998C2 15.5503 2.44772 15.998 3 15.998Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }