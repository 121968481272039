var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "646",
      "height": "634",
      "viewBox": "0 0 646 634",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_464_38044",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M319.801 0C329.774 0 337.859 8.08509 337.859 18.0586V78.8859C337.859 88.8594 329.774 96.9445 319.801 96.9445H315.078V116.003L298.047 96.9445H186.058C176.084 96.9445 167.999 88.8594 167.999 78.8859V18.0586C167.999 8.0851 176.084 0 186.058 0H319.801Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M319.801 0C329.774 0 337.859 8.08509 337.859 18.0586V78.8859C337.859 88.8594 329.774 96.9445 319.801 96.9445H315.078V116.003L298.047 96.9445H186.058C176.084 96.9445 167.999 88.8594 167.999 78.8859V18.0586C167.999 8.0851 176.084 0 186.058 0H319.801Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M315.078 96.9445H312.078V93.9445H315.078V96.9445ZM315.078 116.003H318.078V123.862L312.841 118.002L315.078 116.003ZM298.047 96.9445V93.9445H299.39L300.284 94.9455L298.047 96.9445ZM334.859 18.0586C334.859 9.74195 328.117 3 319.801 3V-3C331.431 -3 340.859 6.42823 340.859 18.0586H334.859ZM334.859 78.8859V18.0586H340.859V78.8859H334.859ZM319.801 93.9445C328.117 93.9445 334.859 87.2025 334.859 78.8859H340.859C340.859 90.5163 331.431 99.9445 319.801 99.9445V93.9445ZM315.078 93.9445H319.801V99.9445H315.078V93.9445ZM318.078 96.9445V116.003H312.078V96.9445H318.078ZM312.841 118.002L295.81 98.9435L300.284 94.9455L317.315 114.004L312.841 118.002ZM186.058 93.9445H298.047V99.9445H186.058V93.9445ZM170.999 78.8859C170.999 87.2026 177.741 93.9445 186.058 93.9445V99.9445C174.428 99.9445 164.999 90.5163 164.999 78.8859H170.999ZM170.999 18.0586V78.8859H164.999V18.0586H170.999ZM186.058 3C177.741 3 170.999 9.74196 170.999 18.0586H164.999C164.999 6.42824 174.428 -3 186.058 -3V3ZM319.801 3H186.058V-3H319.801V3Z",
      "fill": "#ED6400",
      "mask": "url(#path-1-inside-1_464_38044)"
    }
  }), _c('path', {
    attrs: {
      "d": "M241.874 46.9569C241.874 43.2716 242.567 39.9958 243.953 37.1636C245.339 34.3313 247.286 32.1133 249.728 30.4754C252.171 28.8716 255.009 28.0527 258.177 28.0527C261.411 28.0527 264.216 28.8716 266.692 30.4754C269.134 32.0792 271.081 34.3313 272.467 37.1977C273.853 40.064 274.546 43.3057 274.546 46.9569C274.546 47.0934 274.513 47.2299 274.513 47.3664H280.189C280.189 47.2299 280.189 47.0934 280.189 46.9569C280.189 43.5105 279.661 40.3029 278.572 37.3342C277.483 34.3996 275.965 31.8062 273.985 29.6223C272.005 27.4043 269.695 25.6982 266.989 24.5038C264.315 23.2754 261.378 22.6953 258.144 22.6953C254.976 22.6953 252.039 23.3095 249.365 24.5038C246.692 25.7323 244.349 27.4384 242.369 29.6223C240.389 31.8403 238.871 34.3996 237.815 37.3342C236.759 40.2688 236.23 43.4764 236.23 46.9569C236.23 47.0934 236.23 47.2299 236.23 47.3664H241.841C241.907 47.2299 241.874 47.0934 241.874 46.9569Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M264.482 47.3672C264.416 50.8478 263.756 53.953 262.403 56.7169C261.017 59.5833 259.069 61.8013 256.627 63.4392C254.185 65.043 251.347 65.862 248.113 65.862C244.944 65.862 242.106 65.043 239.664 63.4392C237.222 61.8354 235.275 59.5833 233.889 56.7169C232.536 53.9871 231.876 50.8478 231.81 47.3672H226.199C226.232 50.6771 226.76 53.7482 227.783 56.5805C228.839 59.515 230.357 62.1084 232.338 64.2923C234.318 66.5103 236.628 68.2165 239.334 69.4108C242.007 70.6392 244.944 71.2193 248.113 71.2193C251.347 71.2193 254.284 70.6051 256.957 69.4108C259.63 68.1823 261.974 66.4762 263.954 64.2923C265.934 62.0743 267.452 59.515 268.541 56.5805C269.597 53.7482 270.125 50.6771 270.158 47.3672H264.482Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M267.188 42.3788C268.773 42.3788 270.059 41.0497 270.059 39.4101C270.059 37.7705 268.773 36.4414 267.188 36.4414C265.602 36.4414 264.316 37.7705 264.316 39.4101C264.316 41.0497 265.602 42.3788 267.188 42.3788Z",
      "fill": "#07B53B"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter0_d_464_38044)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M263.706 223.874C258.357 222.417 252.754 221.747 246.82 221.838C220.263 222.341 194.383 234.523 172.579 248.882C152.229 262.231 134.668 279.603 120.06 299.137C75.6159 358.585 59.387 451.58 117.011 508.463C129.805 521.08 145.364 530.765 162.217 536.958C225.01 559.9 287.24 524.832 314.759 466.983C343.964 405.507 344.988 320.655 309.275 261.517C298.461 243.544 283.137 229.072 263.706 223.874Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M269.381 230.516C264.314 227.445 258.837 225.048 253.009 223.482C247.718 222.061 242.109 221.37 236.216 221.477C209.852 221.952 184.169 234.057 162.497 248.283C155.293 252.995 148.445 258.237 141.969 263.877",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M312.045 289.504C308.289 279.413 303.693 269.774 298.217 260.714C294.938 255.274 291.22 250.165 287.105 245.512",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M324.864 353.988C323.791 331.736 319.602 309.761 312.055 289.512",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M307.58 455.942C320.473 425.042 326.556 389.15 324.855 353.98",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M294.542 481.159C297.912 475.939 300.953 470.446 303.674 464.739C305.047 461.848 306.344 458.933 307.582 455.943",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M124.338 519.901C132.99 525.817 142.39 530.612 152.228 534.215C194.845 549.788 237.216 538.46 268.46 511.371",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M92.2773 486.93C93.6675 488.653 95.1241 490.343 96.6473 491.998",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M128.854 276.328C122.233 283.198 116.052 290.515 110.325 298.144C73.3994 347.51 56.1022 420.227 84.9563 475.723",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter1_d_464_38044)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M349.527 142.162C344.427 144.086 339.58 146.781 334.908 150.275C314.049 165.983 300.463 190.601 291.378 214.603C282.868 236.964 278.898 260.919 278.525 284.895C277.402 357.852 318.083 441.257 396.757 453.337C414.218 456.01 432.19 454.754 449.18 449.971C512.406 432.044 541.745 368.249 530.314 306.321C518.133 240.534 470.038 172.376 407.511 145.871C388.541 137.792 367.996 135.101 349.527 142.162Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M357.911 144.202C352.107 144.678 346.363 145.926 340.82 148.038C335.787 149.956 330.923 152.639 326.292 156.121C305.573 171.696 292.099 196.14 283.042 219.96C280.02 227.864 277.589 235.986 275.684 244.209",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M425.89 166.585C417.082 160.715 407.866 155.689 398.283 151.63C392.536 149.188 386.63 147.263 380.672 145.93",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M473.264 210.544C459.583 193.444 443.58 178.36 425.898 166.586",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M518.282 301.692C510.737 269.655 494.893 237.568 473.266 210.543",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M522.424 329.292C522.098 323.192 521.354 317.065 520.231 310.953C519.657 307.859 519.01 304.79 518.272 301.696",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M409.221 458.25C419.52 457.974 429.769 456.374 439.68 453.572C482.593 441.407 509.803 407.964 519.067 368.382",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M364.688 450.477C366.788 451.048 368.922 451.553 371.089 451.994",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M272.429 261.676C271.117 270.963 270.412 280.352 270.25 289.728C269.302 350.323 297.444 418.198 352.409 445.757",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter2_d_464_38044)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M302.527 284.158C297.427 286.082 292.58 288.777 287.908 292.271C267.049 307.979 253.463 332.597 244.378 356.599C235.868 378.96 231.898 402.915 231.525 426.891C230.402 499.848 271.083 583.253 349.757 595.333C367.218 598.006 385.19 596.75 402.18 591.967C465.406 574.04 494.745 510.245 483.314 448.317C471.133 382.53 423.038 314.372 360.511 287.867C341.541 279.788 320.996 277.097 302.527 284.158Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M310.911 286.198C305.107 286.674 299.363 287.922 293.82 290.034C288.787 291.952 283.923 294.636 279.292 298.117C258.573 313.692 245.099 338.136 236.042 361.956C233.02 369.861 230.589 377.982 228.684 386.206",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M378.89 308.581C370.082 302.711 360.866 297.685 351.283 293.626C345.536 291.184 339.63 289.259 333.672 287.926",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M426.264 352.54C412.583 335.44 396.58 320.356 378.898 308.582",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M471.282 443.688C463.737 411.651 447.893 379.564 426.266 352.539",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M475.424 471.288C475.098 465.188 474.354 459.062 473.231 452.949C472.657 449.855 472.01 446.786 471.272 443.692",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M362.221 600.246C372.52 599.97 382.769 598.37 392.68 595.568C435.593 583.403 462.803 549.96 472.067 510.378",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M317.688 592.473C319.788 593.044 321.922 593.55 324.089 593.99",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M225.429 403.672C224.117 412.959 223.412 422.349 223.25 431.724C222.302 492.319 250.444 560.194 305.409 587.753",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "250.483",
      "cy": "188.686",
      "r": "5.97161",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "511.397",
      "cy": "564.436",
      "r": "5.97161",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "170.057",
      "cy": "206.597",
      "r": "6.76839",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "501.288",
      "cy": "535.964",
      "r": "4.47161",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "112.222",
      "cy": "168.936",
      "r": "6.76839",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter3_d_464_38044)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M502.531 247.07C496.693 247.331 490.856 248.464 484.931 250.468C458.445 259.529 436.402 280.091 419.151 301.524C403.033 321.475 391.01 344.564 382.646 368.872C357.205 442.841 370.884 541.381 447.031 579.89C463.934 588.429 482.666 593.133 501.572 593.917C571.97 596.705 623.112 541.468 632.086 474.555C641.583 403.461 615.271 317.99 560.382 270.158C543.741 255.608 523.702 246.024 502.531 247.07Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M510.428 251.924C504.354 250.476 498.086 249.835 491.734 250.141C485.967 250.42 480.116 251.534 474.238 253.54C447.938 262.511 426.068 282.933 408.906 304.19C403.194 311.238 398.013 318.705 393.332 326.45",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M572.254 297.364C565.233 288.449 557.516 280.258 549.102 272.931C544.059 268.529 538.682 264.601 533.055 261.258",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M605.877 357.939C597.63 335.957 586.347 315.257 572.25 297.371",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M621.389 465.812C624.37 430.652 618.91 392.679 605.871 357.938",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M616.426 495.316C618.125 488.992 619.407 482.501 620.298 475.898C620.744 472.555 621.106 469.211 621.385 465.812",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M458.137 589.031C468.724 592.179 479.7 593.962 490.733 594.408C538.513 596.302 577.378 571.284 600 534.035",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M415.34 566.266C417.29 567.547 419.296 568.773 421.358 569.943",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M384.186 343.164C379.756 352.191 375.911 361.524 372.624 371.024C351.478 432.455 357.552 510.993 404.384 557.38",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter4_d_464_38044)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M140.804 229.068C135.081 229.325 129.359 230.435 123.551 232.399C97.5872 241.282 75.9789 261.438 59.0681 282.449C43.2676 302.007 31.4813 324.64 23.2821 348.469C-1.65708 420.981 11.752 517.577 86.3987 555.327C102.968 563.697 121.331 568.31 139.864 569.078C208.874 571.811 259.008 517.663 267.806 452.069C277.115 382.376 251.322 298.591 197.515 251.702C181.202 237.438 161.558 228.044 140.804 229.068Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M148.487 233.814C142.533 232.393 136.388 231.765 130.161 232.066C124.508 232.339 118.772 233.431 113.01 235.398C87.2284 244.192 65.7894 264.21 48.966 285.049C43.3672 291.958 38.2874 299.278 33.6992 306.87",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M209.079 278.36C202.196 269.62 194.631 261.591 186.383 254.408C181.44 250.093 176.169 246.242 170.652 242.965",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M242.046 337.733C233.962 316.185 222.901 295.893 209.082 278.359",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M257.259 443.478C260.181 409.012 254.828 371.787 242.047 337.73",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M252.395 472.407C254.06 466.207 255.317 459.844 256.191 453.371C256.628 450.094 256.983 446.816 257.256 443.484",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M97.2227 564.271C107.601 567.357 118.361 569.105 129.176 569.542C176.014 571.399 214.113 546.874 236.289 510.359",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M55.2695 541.965C57.1813 543.221 59.1477 544.423 61.1687 545.57",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M24.7287 323.262C20.3863 332.11 16.6174 341.26 13.3947 350.573C-7.33419 410.793 -1.38043 487.782 44.529 533.254",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "51.6777",
      "cy": "554.654",
      "r": "9.32227",
      "fill": "#ED6400"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_464_38044",
      "x": "69.3474",
      "y": "221.832",
      "width": "277.618",
      "height": "343.379",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "10.5745"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "5.28724"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_464_38044"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_464_38044",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_d_464_38044",
      "x": "268.109",
      "y": "138.09",
      "width": "275.024",
      "height": "337.419",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "10.395"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "5.19752"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_464_38044"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_464_38044",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter2_d_464_38044",
      "x": "221.109",
      "y": "280.086",
      "width": "275.024",
      "height": "337.419",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "10.395"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "5.19752"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_464_38044"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_464_38044",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter3_d_464_38044",
      "x": "359.797",
      "y": "246.992",
      "width": "285.339",
      "height": "369.315",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "11.144"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "5.57201"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_464_38044"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_464_38044",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter4_d_464_38044",
      "x": "0.884272",
      "y": "228.992",
      "width": "279.716",
      "height": "362.032",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "10.9243"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "5.46216"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_464_38044"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_464_38044",
      "result": "shape"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }