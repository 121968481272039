var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "95",
      "height": "101",
      "viewBox": "0 0 95 101",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M27.3058 10.8108C28.301 10.8108 29.1077 10.0041 29.1077 9.00894C29.1077 8.01377 28.301 7.20703 27.3058 7.20703C26.3106 7.20703 25.5039 8.01377 25.5039 9.00894C25.5039 10.0041 26.3106 10.8108 27.3058 10.8108Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M83.1652 3.60381C84.1604 3.60381 84.9671 2.79707 84.9671 1.8019C84.9671 0.80674 84.1604 0 83.1652 0C82.17 0 81.3633 0.80674 81.3633 1.8019C81.3633 2.79707 82.17 3.60381 83.1652 3.60381Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M92.9209 25.9728C92.4601 25.9728 91.9988 25.7967 91.647 25.4449L86.5512 20.3491C85.8475 19.6454 85.8475 18.5048 86.5512 17.8012C87.2548 17.0975 88.3954 17.0975 89.0991 17.8012L94.1949 22.897C94.8985 23.6006 94.8985 24.7412 94.1949 25.4449C93.843 25.7971 93.3817 25.9728 92.9209 25.9728Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M87.8251 25.9728C87.3643 25.9728 86.903 25.7967 86.5512 25.4449C85.8475 24.7412 85.8475 23.6006 86.5512 22.897L91.647 17.8012C92.3506 17.0975 93.4912 17.0975 94.1949 17.8012C94.8985 18.5048 94.8985 19.6454 94.1949 20.3491L89.0991 25.4449C88.7472 25.7971 88.2864 25.9728 87.8251 25.9728Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M49.5987 19.7951C47.0544 17.6968 43.4006 17.6256 40.7761 19.6235L2.08203 50.45L45.3998 81.0824L88.5735 50.45L49.5987 19.7951Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M45.3977 82.8865C45.0333 82.8865 44.6693 82.7765 44.3576 82.5558L1.0398 51.9234C0.577159 51.5959 0.295612 51.0698 0.278044 50.5044C0.262277 49.9377 0.513642 49.3967 0.957361 49.0431L39.6524 18.2161C42.9836 15.6795 47.5235 15.7664 50.726 18.394L89.685 49.0359C90.1318 49.3877 90.3872 49.9287 90.3728 50.4972C90.3589 51.0648 90.0773 51.5937 89.6142 51.9216L46.4406 82.554C46.128 82.7756 45.7635 82.8865 45.3977 82.8865ZM5.08012 50.3661L45.3959 78.8763L85.5622 50.3783L48.4826 21.2145C48.4722 21.2054 48.4614 21.1969 48.4493 21.1879C46.5401 19.613 43.8355 19.5594 41.8665 21.0595L5.08012 50.3661Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.0938 25.2305H79.5661V75.6838H11.0938V25.2305Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M79.5634 77.4829H11.091C10.095 77.4829 9.28906 76.6761 9.28906 75.681V25.2277C9.28906 24.2326 10.095 23.4258 11.091 23.4258H79.5634C80.5594 23.4258 81.3653 24.2326 81.3653 25.2277V75.681C81.3653 76.6761 80.5594 77.4829 79.5634 77.4829ZM12.8929 73.8791H77.7615V27.0296H12.8929V73.8791Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.5273 79.2836L88.575 50.4531V99.1046L43.5273 79.2836Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M88.5721 100.907C88.3257 100.907 88.0793 100.857 87.8473 100.755L42.7997 80.9341C42.1906 80.6665 41.7789 80.0858 41.728 79.4232C41.6771 78.7614 41.9938 78.1244 42.5533 77.7663L87.6009 48.9358C88.1568 48.5813 88.8609 48.5583 89.4379 48.8723C90.015 49.189 90.374 49.7953 90.374 50.4535V99.1049C90.374 99.714 90.0659 100.281 89.5577 100.614C89.26 100.809 88.9167 100.907 88.5721 100.907ZM47.3288 78.9898L86.7702 96.3435V53.746L47.3288 78.9898Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.5258 79.2875L2.08203 50.457V99.1085L43.5258 79.2875Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M2.07925 100.908C1.745 100.908 1.41074 100.814 1.11838 100.631C0.594029 100.301 0.277344 99.7243 0.277344 99.1058V50.4543C0.277344 49.7831 0.650337 49.1682 1.24497 48.8574C1.83959 48.5466 2.55765 48.5916 3.10859 48.9754L44.5524 77.8059C45.0785 78.1717 45.3705 78.7916 45.3195 79.4299C45.2668 80.0696 44.8794 80.6335 44.3006 80.9106L2.85677 100.732C2.61036 100.85 2.34503 100.908 2.07925 100.908ZM3.88115 53.9032V96.2461L39.9508 78.9952L3.88115 53.9032Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M71.4551 18.0196C67.9779 18.0196 65.1484 15.1901 65.1484 11.7129C65.1484 8.23569 67.9779 5.40625 71.4551 5.40625C74.9323 5.40625 77.7618 8.23569 77.7618 11.7129C77.7618 15.1901 74.9319 18.0196 71.4551 18.0196ZM71.4551 9.01006C69.9645 9.01006 68.7522 10.2223 68.7522 11.7129C68.7522 13.2035 69.9645 14.4158 71.4551 14.4158C72.9457 14.4158 74.158 13.2035 74.158 11.7129C74.158 10.2223 72.9453 9.01006 71.4551 9.01006Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M64.9068 99.1067H88.5735L49.9915 71.8686C47.1107 69.8352 43.2605 69.841 40.3865 71.884L2.08203 99.1067H57.2595",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M88.5708 100.907H64.905C63.909 100.907 63.1031 100.1 63.1031 99.1054C63.1031 98.1103 63.909 97.3035 64.905 97.3035H82.8943L48.9487 73.3395C46.6981 71.7507 43.675 71.7547 41.428 73.3508L7.72428 97.3035H57.2573C58.2533 97.3035 59.0592 98.1103 59.0592 99.1054C59.0592 100.1 58.2533 100.907 57.2573 100.907H2.07936C1.29463 100.907 0.601346 100.401 0.362143 99.6536C0.124742 98.9067 0.397279 98.0909 1.03605 97.6368L39.3405 70.4137C42.8281 67.9315 47.5265 67.9239 51.0281 70.3952L89.6105 97.6332C90.2511 98.0864 90.5254 98.9018 90.288 99.6509C90.0506 100.399 89.3555 100.907 88.5708 100.907Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M44.5016 63.0032C51.9583 63.0032 58.0032 56.9583 58.0032 49.5016C58.0032 42.0449 51.9583 36 44.5016 36C37.0449 36 31 42.0449 31 49.5016C31 56.9583 37.0449 63.0032 44.5016 63.0032Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M41.1275 57.3784C40.8291 57.3783 40.5429 57.2597 40.332 57.0487L35.8315 52.5482C35.4051 52.1067 35.4112 51.405 35.8452 50.971C36.2792 50.537 36.9809 50.5309 37.4224 50.9573L41.1275 54.6623L51.5833 44.2065C52.0248 43.7801 52.7265 43.7862 53.1605 44.2202C53.5945 44.6542 53.6006 45.3559 53.1742 45.7974L41.9229 57.0487C41.712 57.2597 41.4258 57.3783 41.1275 57.3784Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }