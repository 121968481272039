var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.99988 18H10.9999V20H12.9999V18H14.9999V20H15.9999C16.5522 20 16.9999 20.4477 16.9999 21C16.9999 21.5523 16.5522 22 15.9999 22H7.99988C7.44759 22 6.99988 21.5523 6.99988 21C6.99988 20.4477 7.44759 20 7.99988 20H8.99988V18Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4 6L4 14C4 15.1046 4.89543 16 6 16L18 16C19.1046 16 20 15.1046 20 14L20 6C20 4.89543 19.1046 4 18 4L6 4C4.89543 4 4 4.89543 4 6ZM2 14C2 16.2091 3.79086 18 6 18L18 18C20.2091 18 22 16.2091 22 14L22 6C22 3.79086 20.2091 2 18 2L6 2C3.79086 2 2 3.79086 2 6L2 14Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8H14C14.5523 8 15 7.55228 15 7C15 6.44772 14.5523 6 14 6H7Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H9C9.55228 12 10 11.5523 10 11C10 10.4477 9.55228 10 9 10H7Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }