var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-3"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handleSubmit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "textarea"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.new-client.nameAndSurname")) + "* ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "input",
    class: _vm.$v.name.$error ? 'is-invalid' : '',
    attrs: {
      "id": "name",
      "type": "text",
      "placeholder": "Eg.John Doe"
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.name = $event.target.value;
      }
    }
  }), !_vm.$v.name.required & _vm.$v.name.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "textarea"
    }
  }, [_vm._v(_vm._s(_vm.$t("help.form.new-client.email")) + "*")]), _c('b-form-input', {
    staticClass: "input",
    class: _vm.$v.email.$error ? 'is-invalid' : '',
    attrs: {
      "id": "email",
      "type": "email",
      "placeholder": "Eg.john.doe@gmail.com"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), !_vm.$v.email.required & _vm.$v.email.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e(), _vm.$v.email.required & !_vm.$v.email.email ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Your email is not valid ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("help.form.new-client.mobile")) + "*")]), _c('MazPhoneNumberInput', {
    staticClass: "mb-32px",
    class: _vm.$v.phoneNumber.$error ? 'is-invalid-1' : '',
    attrs: {
      "type": "tel"
    },
    on: {
      "update": function ($event) {
        _vm.results = $event;
      }
    },
    model: {
      value: _vm.phoneNumber,
      callback: function ($$v) {
        _vm.phoneNumber = $$v;
      },
      expression: "phoneNumber"
    }
  }), !_vm.$v.phoneNumber.required & _vm.$v.phoneNumber.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "textarea"
    }
  }, [_vm._v("Cover letter*")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.cover_letter,
      expression: "cover_letter"
    }],
    staticClass: "input",
    class: _vm.$v.cover_letter.$error ? 'is-invalid' : '',
    staticStyle: {
      "height": "156px !important"
    },
    attrs: {
      "id": "textarea",
      "rows": "8",
      "max-rows": "6"
    },
    domProps: {
      "value": _vm.cover_letter
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.cover_letter = $event.target.value;
      }
    }
  }), !_vm.$v.cover_letter.required & _vm.$v.cover_letter.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group d-flex flex-column"
  }, [_c('label', {
    staticClass: "cvUpload"
  }, [_vm._v(" Upload your CV / Resume* ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-file', {
    class: _vm.$v.file.$error ? 'is-invalid-2' : '',
    attrs: {
      "placeholder": "Choose file",
      "drop-placeholder": "Drop file here...",
      "accept": ".pdf,.doc,.docx"
    },
    on: {
      "change": function ($event) {
        return _vm.handleFileUpload($event);
      }
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }), !_vm.file ? _c('div', {
    staticStyle: {
      "margin": "8px auto auto 8px !important"
    }
  }, [_c('NoFile', {
    staticStyle: {
      "margin-right": "4px"
    }
  }), _c('span', {
    staticClass: "noFile"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.form.no-file")) + " ")])], 1) : _vm._e()], 1), !_vm.$v.file.required & _vm.$v.file.$dirty ? _c('span', {
    staticClass: "red-info-file"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e()]), _c('div', {
    staticClass: "apply-buttons d-flex flex-column flex-md-row mt-5 justify-content-center",
    staticStyle: {
      "gap": "14px"
    }
  }, [_c('b-button', {
    staticClass: "round-btn-16-24-outline order-1 order-md-0 text-nowrap",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        _vm.$emit('hideTitle');

        _vm.handleChangeStep();
      }
    }
  }, [_c('p', {
    staticClass: "button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.current-jobs.buttons.back")) + " ")])]), _c('b-button', {
    staticClass: "round-btn-16-24 order-0 order-md-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.form.submit")) + " ")])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }