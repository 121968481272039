var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M19.5858 15.5858C18.8047 16.3668 18.8047 17.6332 19.5858 18.4142L25.1716 24L19.5858 29.5858C18.8047 30.3668 18.8047 31.6332 19.5858 32.4142C20.3668 33.1953 21.6332 33.1953 22.4142 32.4142L29.4142 25.4142C30.1953 24.6332 30.1953 23.3668 29.4142 22.5858L22.4142 15.5858C21.6332 14.8047 20.3668 14.8047 19.5858 15.5858Z",
      "fill": "#616A70"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }