var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.0052 16.668C13.6871 16.668 16.6719 13.6832 16.6719 10.0013C16.6719 6.3194 13.6871 3.33464 10.0052 3.33464C6.32331 3.33464 3.33854 6.3194 3.33854 10.0013C3.33854 13.6832 6.32331 16.668 10.0052 16.668ZM10.0052 18.3346C14.6076 18.3346 18.3385 14.6037 18.3385 10.0013C18.3385 5.39893 14.6076 1.66797 10.0052 1.66797C5.40284 1.66797 1.67188 5.39893 1.67188 10.0013C1.67188 14.6037 5.40284 18.3346 10.0052 18.3346Z",
      "fill": "#FF5959"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M9.9974 5.83398C9.53716 5.83398 9.16406 6.20708 9.16406 6.66732C9.16406 7.12756 9.53716 7.50065 9.9974 7.50065C10.4576 7.50065 10.8307 7.12756 10.8307 6.66732C10.8307 6.20708 10.4576 5.83398 9.9974 5.83398ZM9.9974 9.16732C9.53716 9.16732 9.16406 9.54041 9.16406 10.0007V13.334C9.16406 13.7942 9.53716 14.1673 9.9974 14.1673C10.4576 14.1673 10.8307 13.7942 10.8307 13.334V10.0007C10.8307 9.54041 10.4576 9.16732 9.9974 9.16732Z",
      "fill": "#FF5959"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }