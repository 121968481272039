var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1058",
      "height": "541",
      "viewBox": "0 0 1058 541",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "filter": "url(#filter0_d_504_49839)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M356.018 140.919C352.362 142.291 348.886 144.216 345.537 146.712C330.587 157.935 320.858 175.536 314.356 192.698C308.267 208.687 305.434 225.819 305.182 242.969C304.421 295.152 333.651 354.836 390.087 363.525C402.611 365.448 415.501 364.56 427.684 361.149C473.02 348.366 494.024 302.753 485.787 258.45C477.01 211.386 442.472 162.604 397.609 143.607C383.997 137.817 369.26 135.879 356.018 140.919Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M362.103 142.439C357.937 142.779 353.815 143.672 349.837 145.182C346.225 146.554 342.734 148.473 339.411 150.962C324.541 162.099 314.872 179.578 308.372 196.611C306.203 202.264 304.459 208.071 303.091 213.951",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M410.889 158.446C404.568 154.248 397.954 150.654 391.076 147.752C386.952 146.006 382.714 144.629 378.438 143.676",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M444.887 189.878C435.069 177.651 423.584 166.864 410.895 158.445",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M477.194 255.057C471.779 232.148 460.408 209.204 444.887 189.879",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M480.167 274.792C479.933 270.43 479.398 266.049 478.593 261.678C478.181 259.466 477.716 257.271 477.187 255.058",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M398.925 367.005C406.316 366.807 413.671 365.663 420.784 363.66C451.581 354.961 471.109 331.046 477.758 302.743",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M366.965 361.445C368.472 361.854 370.004 362.215 371.559 362.53",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M300.753 226.442C299.812 233.083 299.306 239.797 299.19 246.501C298.509 289.831 318.706 338.366 358.152 358.072",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "349.53",
      "cy": "83.0224",
      "r": "5.97161",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "610.444",
      "cy": "458.768",
      "r": "5.97161",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "269.104",
      "cy": "100.932",
      "r": "6.76839",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "600.335",
      "cy": "430.296",
      "r": "4.47161",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "211.268",
      "cy": "63.2684",
      "r": "6.76839",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "377.549",
      "cy": "464.74",
      "r": "10.4432",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter1_d_504_49839)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M518.454 234.09C515.246 233.695 511.957 233.773 508.54 234.317C493.253 236.806 479.32 245.959 467.919 256.028C457.273 265.395 448.569 276.846 441.747 289.297C420.994 327.188 419.29 382.046 457.236 410.007C465.659 416.208 475.437 420.493 485.675 422.661C523.806 430.663 556.834 405.329 567.951 369.76C579.741 331.965 573.341 283.052 547.856 251.978C540.134 242.531 530.097 235.472 518.454 234.09Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M522.265 237.506C519.085 236.157 515.723 235.229 512.229 234.809C509.056 234.428 505.76 234.493 502.366 235.04C487.183 237.488 473.355 246.57 462.018 256.542C458.248 259.847 454.728 263.428 451.455 267.206",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M551.788 267.922C548.784 262.426 545.332 257.259 541.42 252.498C539.077 249.638 536.507 247.006 533.746 244.668",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M564.518 303.964C562.057 291.251 557.819 278.953 551.785 267.926",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M562.98 364.051C567.867 345.21 568.409 324.059 564.516 303.965",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M557.533 379.631C559.047 376.35 560.349 372.939 561.447 369.431C562.001 367.655 562.508 365.87 562.976 364.048",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M462.466 415.96C467.951 418.65 473.776 420.634 479.755 421.896C505.652 427.341 529.18 417.329 544.98 399.167",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M441.223 399.629C442.168 400.506 443.149 401.358 444.165 402.185",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M444.914 275.443C441.66 279.942 438.696 284.661 436.021 289.523C418.785 320.97 414.816 364.233 436.07 393.781",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter2_d_504_49839)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M691.061 249.052C688.759 250.527 686.662 252.354 684.735 254.553C676.146 264.427 672.071 277.906 670.141 290.613C668.321 302.455 668.899 314.604 671.247 326.388C678.397 362.244 707.187 398.852 747.113 396.55C755.973 396.036 764.669 393.543 772.511 389.425C801.679 374.039 809.358 339.723 797.205 310.582C784.276 279.627 753.454 251.264 719.938 244.812C709.766 242.836 699.389 243.664 691.061 249.052Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M695.453 249.199C692.649 250.04 689.958 251.254 687.455 252.869C685.184 254.336 683.075 256.16 681.165 258.351C672.62 268.151 668.57 281.534 666.624 294.149C665.971 298.337 665.63 302.569 665.559 306.796",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M731.219 253.028C726.272 251.076 721.213 249.581 716.076 248.598C712.995 248.004 709.889 247.681 706.82 247.652",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M759.128 269.593C750.605 262.653 741.152 256.944 731.223 253.031",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M790.841 309.517C783.762 294.618 772.598 280.566 759.125 269.598",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M795.782 322.594C794.981 319.641 793.97 316.719 792.775 313.843C792.168 312.388 791.526 310.953 790.838 309.515",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M753.717 397.616C758.75 396.401 763.619 394.543 768.196 392.132C788.008 381.676 797.863 362.445 798.252 342.089",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M731.004 398.473C732.096 398.532 733.198 398.556 734.31 398.544",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M665.792 315.695C666.124 320.381 666.766 325.053 667.673 329.662C673.582 359.436 694.556 389.727 724.471 397.461",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter3_d_504_49839)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1020.05 288.347C1018.31 287.096 1016.37 286.096 1014.21 285.326C1004.51 281.915 993.465 282.817 983.604 285.058C974.408 287.13 965.676 291.034 957.72 296.119C933.515 311.595 914.976 343.015 928.134 371.372C931.057 377.664 935.381 383.27 940.65 387.789C960.3 404.576 987.639 400.319 1005.49 383.131C1024.45 364.86 1036.36 334.327 1031.46 308.118C1029.98 300.159 1026.39 292.854 1020.05 288.347Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M1021.2 291.553C1019.78 289.757 1018.12 288.149 1016.22 286.794C1014.49 285.563 1012.55 284.554 1010.4 283.795C1000.77 280.396 989.81 281.292 980.015 283.499C976.761 284.226 973.564 285.193 970.449 286.354",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1028.66 318.649C1028.67 314.493 1028.32 310.387 1027.56 306.37C1027.11 303.96 1026.46 301.61 1025.6 299.371",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1024.54 343.686C1027.18 335.499 1028.65 326.989 1028.66 318.648",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1004.41 378.199C1013.29 368.777 1020.38 356.629 1024.54 343.688",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M996.256 385.545C998.188 384.115 1000.04 382.541 1001.8 380.847C1002.69 379.988 1003.56 379.11 1004.41 378.197",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M929.252 376.5C931.586 379.81 934.344 382.816 937.423 385.451C950.765 396.851 967.674 398.495 982.69 392.936",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M922.105 360.238C922.375 361.05 922.674 361.857 923.002 362.662",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M964 289.077C960.665 290.663 957.428 292.47 954.314 294.452C934.21 307.293 918.051 331.232 920.974 355.197",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter4_d_504_49839)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M373.533 190.487C368.359 190.717 363.186 191.718 357.935 193.487C334.461 201.489 314.926 219.646 299.637 238.572C285.352 256.19 274.696 276.579 267.283 298.044C244.736 363.363 256.859 450.378 324.346 484.384C339.326 491.924 355.927 496.079 372.683 496.771C435.074 499.233 480.4 450.455 488.353 391.368C496.77 328.588 473.451 253.113 424.804 210.875C410.056 198.026 392.296 189.563 373.533 190.487Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M380.481 194.762C375.098 193.483 369.542 192.917 363.913 193.187C358.802 193.433 353.617 194.417 348.407 196.189C325.098 204.111 305.715 222.144 290.506 240.915C285.444 247.139 280.851 253.732 276.703 260.572",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M435.26 234.892C429.038 227.019 422.199 219.786 414.742 213.316C410.273 209.429 405.507 205.96 400.52 203.008",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M465.064 288.375C457.756 268.964 447.756 250.685 435.262 234.891",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M478.819 383.629C481.461 352.582 476.622 319.05 465.066 288.371",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M474.422 409.69C475.928 404.105 477.064 398.373 477.854 392.543C478.249 389.59 478.57 386.638 478.817 383.637",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M334.133 492.443C343.516 495.223 353.244 496.797 363.022 497.191C405.367 498.864 439.812 476.772 459.861 443.879",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M296.203 472.348C297.932 473.479 299.709 474.562 301.536 475.595",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M268.592 275.336C264.666 283.307 261.258 291.549 258.345 299.938C239.604 354.185 244.987 423.538 286.493 464.5",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-52-inside-1_504_49839",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M403.059 0C393.085 0 385 8.08509 385 18.0586V78.8859C385 88.8594 393.085 96.9445 403.059 96.9445H407.781V116.003L424.812 96.9445H536.801C546.775 96.9445 554.86 88.8594 554.86 78.8859V18.0586C554.86 8.0851 546.775 0 536.801 0H403.059Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M403.059 0C393.085 0 385 8.08509 385 18.0586V78.8859C385 88.8594 393.085 96.9445 403.059 96.9445H407.781V116.003L424.812 96.9445H536.801C546.775 96.9445 554.86 88.8594 554.86 78.8859V18.0586C554.86 8.0851 546.775 0 536.801 0H403.059Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M407.781 96.9445H410.781V93.9445H407.781V96.9445ZM407.781 116.003H404.781V123.862L410.018 118.002L407.781 116.003ZM424.812 96.9445V93.9445H423.47L422.575 94.9455L424.812 96.9445ZM388 18.0586C388 9.74195 394.742 3 403.059 3V-3C391.428 -3 382 6.42823 382 18.0586H388ZM388 78.8859V18.0586H382V78.8859H388ZM403.059 93.9445C394.742 93.9445 388 87.2025 388 78.8859H382C382 90.5163 391.428 99.9445 403.059 99.9445V93.9445ZM407.781 93.9445H403.059V99.9445H407.781V93.9445ZM404.781 96.9445V116.003H410.781V96.9445H404.781ZM410.018 118.002L427.049 98.9435L422.575 94.9455L405.544 114.004L410.018 118.002ZM536.801 93.9445H424.812V99.9445H536.801V93.9445ZM551.86 78.8859C551.86 87.2026 545.118 93.9445 536.801 93.9445V99.9445C548.432 99.9445 557.86 90.5163 557.86 78.8859H551.86ZM551.86 18.0586V78.8859H557.86V18.0586H551.86ZM536.801 3C545.118 3 551.86 9.74196 551.86 18.0586H557.86C557.86 6.42824 548.432 -3 536.801 -3V3ZM403.059 3H536.801V-3H403.059V3Z",
      "fill": "#ED6400",
      "mask": "url(#path-52-inside-1_504_49839)"
    }
  }), _c('path', {
    attrs: {
      "d": "M458.343 45.5721C458.343 41.9002 459.036 38.6362 460.422 35.8142C461.808 32.9923 463.755 30.7823 466.197 29.1503C468.639 27.5524 471.477 26.7364 474.646 26.7364C477.88 26.7364 480.685 27.5524 483.16 29.1503C485.602 30.7483 487.55 32.9923 488.936 35.8482C490.322 38.7042 491.015 41.9342 491.015 45.5721C491.015 45.7081 490.982 45.8441 490.982 45.9801H496.658C496.658 45.8441 496.658 45.7081 496.658 45.5721C496.658 42.1382 496.13 38.9422 495.041 35.9842C493.952 33.0603 492.434 30.4763 490.454 28.3003C488.474 26.0904 486.163 24.3904 483.457 23.2004C480.784 21.9764 477.847 21.3984 474.613 21.3984C471.444 21.3984 468.507 22.0104 465.834 23.2004C463.161 24.4244 460.818 26.1244 458.838 28.3003C456.858 30.5103 455.339 33.0603 454.283 35.9842C453.227 38.9082 452.699 42.1042 452.699 45.5721C452.699 45.7081 452.699 45.8441 452.699 45.9801H458.31C458.376 45.8441 458.343 45.7081 458.343 45.5721Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M480.951 45.9805C480.885 49.4484 480.224 52.5424 478.871 55.2963C477.485 58.1523 475.538 60.3623 473.096 61.9943C470.654 63.5922 467.816 64.4082 464.581 64.4082C461.413 64.4082 458.575 63.5922 456.133 61.9943C453.691 60.3963 451.744 58.1523 450.357 55.2963C449.004 52.5764 448.344 49.4484 448.278 45.9805H442.668C442.701 49.2784 443.229 52.3384 444.252 55.1604C445.308 58.0843 446.826 60.6683 448.806 62.8442C450.787 65.0542 453.097 66.7542 455.803 67.9442C458.476 69.1682 461.413 69.7462 464.581 69.7462C467.816 69.7462 470.753 69.1342 473.426 67.9442C476.099 66.7202 478.442 65.0202 480.422 62.8442C482.403 60.6343 483.921 58.0843 485.01 55.1604C486.066 52.3384 486.594 49.2784 486.627 45.9805H480.951Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M483.656 41.0097C485.242 41.0097 486.527 39.6853 486.527 38.0517C486.527 36.4181 485.242 35.0938 483.656 35.0938C482.071 35.0938 480.785 36.4181 480.785 38.0517C480.785 39.6853 482.071 41.0097 483.656 41.0097Z",
      "fill": "#07B53B"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter5_d_504_49839)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M269.799 114.343C263.304 112.583 256.501 111.777 249.295 111.895C217.044 112.531 185.603 127.31 159.111 144.723C134.384 160.911 113.041 181.967 95.2806 205.64C41.2474 277.682 21.4423 390.32 91.3602 459.149C106.883 474.417 125.767 486.129 146.227 493.612C222.456 521.331 298.063 478.797 331.542 408.709C367.072 334.228 368.404 231.465 325.096 159.883C311.984 138.127 293.39 120.617 269.799 114.343Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M286.571 122.496C280.415 118.778 273.759 115.876 266.678 113.98C260.249 112.259 253.433 111.423 246.273 111.552C214.238 112.128 183.031 126.783 156.697 144.007C147.943 149.711 139.622 156.058 131.754 162.885",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M338.414 193.914C333.849 181.697 328.265 170.027 321.611 159.057C317.627 152.471 313.109 146.286 308.109 140.652",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M353.99 271.983C352.686 245.042 347.596 218.437 338.426 193.922",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M332.985 395.421C348.652 358.01 356.043 314.557 353.977 271.977",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M317.146 425.947C321.241 419.627 324.937 412.977 328.243 406.067C329.911 402.567 331.487 399.038 332.991 395.418",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M110.332 472.854C120.845 480.016 132.266 485.822 144.221 490.185C196.005 509.039 247.49 495.324 285.455 462.526",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M71.375 432.938C73.0642 435.024 74.8341 437.07 76.6849 439.074",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M115.816 177.96C107.772 186.278 100.261 195.136 93.3029 204.373C48.434 264.14 27.4161 352.179 62.4768 419.367",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_504_49839",
      "x": "297.708",
      "y": "138.02",
      "width": "197.295",
      "height": "241.424",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.46017"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.73009"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_49839"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_49839",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_d_504_49839",
      "x": "421.004",
      "y": "233.844",
      "width": "158.817",
      "height": "202.638",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "6.16836"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.08418"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_49839"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_49839",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter2_d_504_49839",
      "x": "661.391",
      "y": "243.832",
      "width": "149.027",
      "height": "168.171",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.67552"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.83776"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_49839"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_49839",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter3_d_504_49839",
      "x": "916.094",
      "y": "283.066",
      "width": "124.144",
      "height": "130.703",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.67552"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.83776"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_49839"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_49839",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter4_d_504_49839",
      "x": "245.986",
      "y": "190.418",
      "width": "254.981",
      "height": "328.29",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "10.9243"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "5.46216"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_49839"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_49839",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter5_d_504_49839",
      "x": "35.8435",
      "y": "111.883",
      "width": "332.645",
      "height": "411.442",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "10.5745"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "5.28724"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_49839"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_49839",
      "result": "shape"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }