var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11.6667 5C7.98477 5 5 7.98477 5 11.6667V31.6667C5 35.3486 7.98477 38.3333 11.6667 38.3333H28.3333C32.0152 38.3333 35 35.3486 35 31.6667V11.6667C35 7.98477 32.0152 5 28.3333 5V8.33333C30.1743 8.33333 31.6667 9.82572 31.6667 11.6667V31.6667C31.6667 33.5076 30.1743 35 28.3333 35H11.6667C9.82572 35 8.33333 33.5076 8.33333 31.6667V11.6667C8.33333 9.82572 9.82572 8.33333 11.6667 8.33333V5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.3307 18.3333C14.2512 18.3333 14.9974 17.5871 14.9974 16.6667C14.9974 15.7462 14.2512 15 13.3307 15C12.4103 15 11.6641 15.7462 11.6641 16.6667C11.6641 17.5871 12.4103 18.3333 13.3307 18.3333Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M19.9974 15C19.0769 15 18.3307 15.7462 18.3307 16.6667C18.3307 17.5871 19.0769 18.3333 19.9974 18.3333H26.6641C27.5845 18.3333 28.3307 17.5871 28.3307 16.6667C28.3307 15.7462 27.5845 15 26.6641 15H19.9974Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M19.9974 21.6667C19.0769 21.6667 18.3307 22.4129 18.3307 23.3333C18.3307 24.2538 19.0769 25 19.9974 25H26.6641C27.5845 25 28.3307 24.2538 28.3307 23.3333C28.3307 22.4129 27.5845 21.6667 26.6641 21.6667H19.9974Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M18.3307 30C18.3307 29.0795 19.0769 28.3333 19.9974 28.3333H23.3307C24.2512 28.3333 24.9974 29.0795 24.9974 30C24.9974 30.9205 24.2512 31.6667 23.3307 31.6667H19.9974C19.0769 31.6667 18.3307 30.9205 18.3307 30Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.9974 23.3333C14.9974 24.2538 14.2512 25 13.3307 25C12.4103 25 11.6641 24.2538 11.6641 23.3333C11.6641 22.4129 12.4103 21.6667 13.3307 21.6667C14.2512 21.6667 14.9974 22.4129 14.9974 23.3333Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.3307 31.6667C14.2512 31.6667 14.9974 30.9205 14.9974 30C14.9974 29.0795 14.2512 28.3333 13.3307 28.3333C12.4103 28.3333 11.6641 29.0795 11.6641 30C11.6641 30.9205 12.4103 31.6667 13.3307 31.6667Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M25.0039 5.0013H15.0039C14.0834 5.0013 13.3372 5.74749 13.3372 6.66797C13.3372 7.58844 14.0834 8.33463 15.0039 8.33463H25.0039C25.9244 8.33463 26.6706 7.58844 26.6706 6.66797C26.6706 5.74749 25.9244 5.0013 25.0039 5.0013ZM15.0039 1.66797C12.2425 1.66797 10.0039 3.90654 10.0039 6.66797C10.0039 9.42939 12.2425 11.668 15.0039 11.668H25.0039C27.7653 11.668 30.0039 9.42939 30.0039 6.66797C30.0039 3.90654 27.7653 1.66797 25.0039 1.66797H15.0039Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }