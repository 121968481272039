<template>
  <div>
    <Hero :gotoJobs="gotoJobs" />
    <Principles />
    <JobOpenings ref="jobs" />
  </div>
</template>

<script>
import JobOpenings from "./components/sections/JobOpenings.vue";
import Principles from "./components/sections/Principles.vue";
import Hero from "./components/sections/Hero.vue";
export default {
  components: {
    Hero,
    Principles,
    JobOpenings,
  },
  methods: {
    gotoJobs() {
      window.scrollTo({
        top: this.$refs.jobs.$el.offsetTop,
        behavior: "smooth",
      });
    },
  },
  metaInfo: {
    title: 'NOZAX: Join the NOZAX family',
    meta: [
      { name: 'description', content: "Let's build the future of fintech together! Grow your skills in a multicultural, international team where your accomplishments are recognized and rewarded. " },
    ]
  }
};
</script>
