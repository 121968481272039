var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "283",
      "height": "833",
      "viewBox": "0 0 283 833",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "8",
      "cy": "288",
      "r": "8",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M256.125 534.875H236.875C235.356 534.875 234.125 536.106 234.125 537.625V551.375C234.125 552.894 235.356 554.125 236.875 554.125H256.125C257.644 554.125 258.875 552.894 258.875 551.375V537.625C258.875 536.106 257.644 534.875 256.125 534.875ZM236.875 532.125C233.837 532.125 231.375 534.587 231.375 537.625V551.375C231.375 554.413 233.837 556.875 236.875 556.875H256.125C259.163 556.875 261.625 554.413 261.625 551.375V537.625C261.625 534.587 259.163 532.125 256.125 532.125H236.875Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M247.875 544.5C247.875 540.703 250.953 537.625 254.75 537.625H260.25C261.009 537.625 261.625 538.241 261.625 539C261.625 539.759 261.009 540.375 260.25 540.375H254.75C252.472 540.375 250.625 542.222 250.625 544.5C250.625 546.778 252.472 548.625 254.75 548.625H260.25C261.009 548.625 261.625 549.241 261.625 550C261.625 550.759 261.009 551.375 260.25 551.375H254.75C250.953 551.375 247.875 548.297 247.875 544.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M256.125 544.5C256.125 545.259 255.509 545.875 254.75 545.875C253.991 545.875 253.375 545.259 253.375 544.5C253.375 543.741 253.991 543.125 254.75 543.125C255.509 543.125 256.125 543.741 256.125 544.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M59.9999 42.332C65.1546 42.332 69.3333 38.1534 69.3333 32.9987C69.3333 27.844 65.1546 23.6654 59.9999 23.6654C54.8453 23.6654 50.6666 27.844 50.6666 32.9987C50.6666 38.1534 54.8453 42.332 59.9999 42.332ZM59.9999 44.6654C66.4432 44.6654 71.6666 39.442 71.6666 32.9987C71.6666 26.5554 66.4432 21.332 59.9999 21.332C53.5566 21.332 48.3333 26.5554 48.3333 32.9987C48.3333 39.442 53.5566 44.6654 59.9999 44.6654Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M62.0379 40.2048C62.8997 38.4811 63.5001 35.9367 63.5001 32.9987C63.5001 30.0607 62.8997 27.5163 62.0379 25.7926C61.1063 23.9294 60.251 23.6654 60.0001 23.6654C59.7492 23.6654 58.8939 23.9294 57.9623 25.7926C57.1005 27.5163 56.5001 30.0607 56.5001 32.9987C56.5001 35.9367 57.1005 38.4811 57.9623 40.2048C58.8939 42.068 59.7492 42.332 60.0001 42.332C60.251 42.332 61.1063 42.068 62.0379 40.2048ZM60.0001 44.6654C63.2217 44.6654 65.8334 39.442 65.8334 32.9987C65.8334 26.5554 63.2217 21.332 60.0001 21.332C56.7784 21.332 54.1667 26.5554 54.1667 32.9987C54.1667 39.442 56.7784 44.6654 60.0001 44.6654Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M71.609 34.1673C71.6471 33.7836 71.6666 33.3944 71.6666 33.0007C71.6666 32.6069 71.6471 32.2177 71.609 31.834H48.3909C48.3528 32.2177 48.3333 32.6069 48.3333 33.0007C48.3333 33.3944 48.3528 33.7836 48.3909 34.1673H71.609Z",
      "fill": "#07B53B"
    }
  }), _c('circle', {
    attrs: {
      "cx": "185.972",
      "cy": "285.972",
      "r": "5.0529",
      "stroke": "#07B53B",
      "stroke-width": "1.83742"
    }
  }), _c('circle', {
    attrs: {
      "cx": "204.972",
      "cy": "31.9716",
      "r": "5.0529",
      "stroke": "#07B53B",
      "stroke-width": "1.83742"
    }
  }), _c('circle', {
    attrs: {
      "cx": "117.972",
      "cy": "548.972",
      "r": "5.0529",
      "stroke": "#07B53B",
      "stroke-width": "1.83742"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-11-inside-1_2402_47171",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M225.578 779C228.572 779 231 781.428 231 784.422V802.685C231 805.68 228.572 808.107 225.578 808.107H224.16V813.831L219.045 808.107H185.423C182.428 808.107 180.001 805.68 180.001 802.685V784.422C180.001 781.428 182.428 779 185.423 779H225.578Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M225.578 779C228.572 779 231 781.428 231 784.422V802.685C231 805.68 228.572 808.107 225.578 808.107H224.16V813.831L219.045 808.107H185.423C182.428 808.107 180.001 805.68 180.001 802.685V784.422C180.001 781.428 182.428 779 185.423 779H225.578Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M224.16 808.107H222.16V806.107H224.16V808.107ZM224.16 813.831H226.16V819.07L222.669 815.163L224.16 813.831ZM219.045 808.107V806.107H219.94L220.537 806.774L219.045 808.107ZM229 784.422C229 782.532 227.468 781 225.578 781V777C229.677 777 233 780.323 233 784.422H229ZM229 802.685V784.422H233V802.685H229ZM225.578 806.107C227.468 806.107 229 804.575 229 802.685H233C233 806.784 229.677 810.107 225.578 810.107V806.107ZM224.16 806.107H225.578V810.107H224.16V806.107ZM226.16 808.107V813.831H222.16V808.107H226.16ZM222.669 815.163L217.554 809.44L220.537 806.774L225.651 812.498L222.669 815.163ZM185.423 806.107H219.045V810.107H185.423V806.107ZM182.001 802.685C182.001 804.575 183.533 806.107 185.423 806.107V810.107C181.323 810.107 178.001 806.784 178.001 802.685H182.001ZM182.001 784.422V802.685H178.001V784.422H182.001ZM185.423 781C183.533 781 182.001 782.532 182.001 784.422H178.001C178.001 780.323 181.323 777 185.423 777V781ZM225.578 781H185.423V777H225.578V781Z",
      "fill": "#ED6400",
      "mask": "url(#path-11-inside-1_2402_47171)"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-13-inside-2_2402_47171",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M111.04 0C108.809 0 107 1.80875 107 4.03995V17.6479C107 19.8791 108.809 21.6878 111.04 21.6878H112.098V25.9498L115.906 21.6878H140.96C143.191 21.6878 145 19.8791 145 17.6479V4.03995C145 1.80875 143.191 0 140.96 0H111.04Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M111.04 0C108.809 0 107 1.80875 107 4.03995V17.6479C107 19.8791 108.809 21.6878 111.04 21.6878H112.098V25.9498L115.906 21.6878H140.96C143.191 21.6878 145 19.8791 145 17.6479V4.03995C145 1.80875 143.191 0 140.96 0H111.04Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M112.098 21.6878H114.098V19.6878H112.098V21.6878ZM112.098 25.9498H110.098V31.1895L113.589 27.2825L112.098 25.9498ZM115.906 21.6878V19.6878H115.011L114.415 20.3551L115.906 21.6878ZM109 4.03995C109 2.91332 109.913 2 111.04 2V-2C107.704 -2 105 0.70418 105 4.03995H109ZM109 17.6479V4.03995H105V17.6479H109ZM111.04 19.6878C109.913 19.6878 109 18.7745 109 17.6479H105C105 20.9836 107.704 23.6878 111.04 23.6878V19.6878ZM112.098 19.6878H111.04V23.6878H112.098V19.6878ZM110.098 21.6878V25.9498H114.098V21.6878H110.098ZM113.589 27.2825L117.398 23.0205L114.415 20.3551L110.606 24.6172L113.589 27.2825ZM140.96 19.6878H115.906V23.6878H140.96V19.6878ZM143 17.6479C143 18.7745 142.087 19.6878 140.96 19.6878V23.6878C144.296 23.6878 147 20.9836 147 17.6479H143ZM143 4.03995V17.6479H147V4.03995H143ZM140.96 2C142.087 2 143 2.91332 143 4.03995H147C147 0.70418 144.296 -2 140.96 -2V2ZM111.04 2H140.96V-2H111.04V2Z",
      "fill": "#ED6400",
      "mask": "url(#path-13-inside-2_2402_47171)"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-15-inside-3_2402_47171",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M244.484 274C242.008 274 240 276.008 240 278.484V293.588C240 296.065 242.008 298.072 244.484 298.072H245.656V302.804L249.884 298.072H277.694C280.17 298.072 282.178 296.065 282.178 293.588V278.484C282.178 276.008 280.17 274 277.694 274H244.484Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M244.484 274C242.008 274 240 276.008 240 278.484V293.588C240 296.065 242.008 298.072 244.484 298.072H245.656V302.804L249.884 298.072H277.694C280.17 298.072 282.178 296.065 282.178 293.588V278.484C282.178 276.008 280.17 274 277.694 274H244.484Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M245.656 298.072H247.656V296.072H245.656V298.072ZM245.656 302.804H243.656V308.043L247.148 304.136L245.656 302.804ZM249.884 298.072V296.072H248.99L248.393 296.74L249.884 298.072ZM242 278.484C242 277.112 243.112 276 244.484 276V272C240.903 272 238 274.903 238 278.484H242ZM242 293.588V278.484H238V293.588H242ZM244.484 296.072C243.112 296.072 242 294.96 242 293.588H238C238 297.169 240.903 300.072 244.484 300.072V296.072ZM245.656 296.072H244.484V300.072H245.656V296.072ZM243.656 298.072V302.804H247.656V298.072H243.656ZM247.148 304.136L251.376 299.405L248.393 296.74L244.165 301.471L247.148 304.136ZM277.694 296.072H249.884V300.072H277.694V296.072ZM280.178 293.588C280.178 294.96 279.066 296.072 277.694 296.072V300.072C281.275 300.072 284.178 297.169 284.178 293.588H280.178ZM280.178 278.484V293.588H284.178V278.484H280.178ZM277.694 276C279.066 276 280.178 277.112 280.178 278.484H284.178C284.178 274.903 281.275 272 277.694 272V276ZM244.484 276H277.694V272H244.484V276Z",
      "fill": "#ED6400",
      "mask": "url(#path-15-inside-3_2402_47171)"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-17-inside-4_2402_47171",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M70.7236 543C72.6108 543 74.1406 544.53 74.1406 546.417V557.927C74.1406 559.814 72.6108 561.344 70.7236 561.344H69.8281V564.95L66.6055 561.344H45.4168C43.5296 561.344 41.9997 559.814 41.9997 557.927V546.417C41.9997 544.53 43.5296 543 45.4168 543H70.7236Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M70.7236 543C72.6108 543 74.1406 544.53 74.1406 546.417V557.927C74.1406 559.814 72.6108 561.344 70.7236 561.344H69.8281V564.95L66.6055 561.344H45.4168C43.5296 561.344 41.9997 559.814 41.9997 557.927V546.417C41.9997 544.53 43.5296 543 45.4168 543H70.7236Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M69.8281 561.344H67.8281V559.344H69.8281V561.344ZM69.8281 564.95H71.8281V570.19L68.3368 566.283L69.8281 564.95ZM66.6055 561.344V559.344H67.5005L68.0968 560.011L66.6055 561.344ZM72.1406 546.417C72.1406 545.634 71.5062 545 70.7236 545V541C73.7153 541 76.1406 543.425 76.1406 546.417H72.1406ZM72.1406 557.927V546.417H76.1406V557.927H72.1406ZM70.7236 559.344C71.5062 559.344 72.1406 558.709 72.1406 557.927H76.1406C76.1406 560.919 73.7153 563.344 70.7236 563.344V559.344ZM69.8281 559.344H70.7236V563.344H69.8281V559.344ZM71.8281 561.344V564.95H67.8281V561.344H71.8281ZM68.3368 566.283L65.1142 562.676L68.0968 560.011L71.3194 563.617L68.3368 566.283ZM45.4168 559.344H66.6055V563.344H45.4168V559.344ZM43.9997 557.927C43.9997 558.709 44.6342 559.344 45.4168 559.344V563.344C42.425 563.344 39.9997 560.919 39.9997 557.927H43.9997ZM43.9997 546.417V557.927H39.9997V546.417H43.9997ZM45.4168 545C44.6342 545 43.9997 545.634 43.9997 546.417H39.9997C39.9997 543.425 42.425 541 45.4168 541V545ZM70.7236 545H45.4168V541H70.7236V545Z",
      "fill": "#ED6400",
      "mask": "url(#path-17-inside-4_2402_47171)"
    }
  }), _c('circle', {
    attrs: {
      "cx": "150.972",
      "cy": "795.972",
      "r": "5.0529",
      "stroke": "#07B53B",
      "stroke-width": "1.83742"
    }
  }), _c('circle', {
    attrs: {
      "cx": "17.9716",
      "cy": "826.972",
      "r": "5.0529",
      "stroke": "#07B53B",
      "stroke-width": "1.83742"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M128.857 277.905C133.853 277.905 137.904 273.854 137.904 268.858C137.904 263.861 133.853 259.811 128.857 259.811C123.86 259.811 119.81 263.861 119.81 268.858C119.81 273.854 123.86 277.905 128.857 277.905ZM134.02 266.783C134.373 266.43 134.373 265.857 134.02 265.504C133.667 265.15 133.094 265.15 132.741 265.504L127.952 270.292L125.878 268.218C125.524 267.865 124.951 267.865 124.598 268.218C124.245 268.571 124.245 269.144 124.598 269.497L127.312 272.212C127.666 272.565 128.238 272.565 128.592 272.212L134.02 266.783Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M84.8567 804.905C89.8533 804.905 93.9038 800.854 93.9038 795.858C93.9038 790.861 89.8533 786.811 84.8567 786.811C79.8601 786.811 75.8096 790.861 75.8096 795.858C75.8096 800.854 79.8601 804.905 84.8567 804.905ZM90.02 793.783C90.3733 793.43 90.3733 792.857 90.02 792.504C89.6667 792.15 89.0938 792.15 88.7405 792.504L83.952 797.292L81.8776 795.218C81.5243 794.865 80.9514 794.865 80.5981 795.218C80.2448 795.571 80.2448 796.144 80.5981 796.497L83.3123 799.212C83.6656 799.565 84.2384 799.565 84.5917 799.212L90.02 793.783Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }