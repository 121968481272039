var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "principles section"
  }, [_c('h2', {
    staticClass: "text-center light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.focus.title")) + " ")]), _c('h4', {
    staticClass: "text-center subtitle-bodyLarge text-grey-primary-main-500",
    class: _vm.$i18n.locale == 'en' ? 'maxWidth440' : 'maxWidth640'
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.focus.subtitle")) + " ")]), _c('div', {
    staticClass: "principles-content position-relative"
  }, [_c('div', {
    staticClass: "d-none d-md-flex d-xl-none justify-content-center"
  }, [_c('PrinciplesTabletIcon')], 1), _c('div', {
    staticClass: "d-none d-xl-flex justify-content-center"
  }, [_c('PrinciplesPCIcon')], 1), _c('div', {
    staticClass: "d-md-none w-100 d-flex justify-content-center position-absolute",
    staticStyle: {
      "top": "20%",
      "z-index": "-1"
    }
  }, [_c('PrinciplesMobileIcon', {
    staticStyle: {
      "margin": "-5px auto auto auto"
    }
  })], 1), _c('div', {
    staticClass: "principles-item career-path"
  }, [_c('div', {
    staticClass: "pc pc-ca",
    class: _vm.$i18n.locale == 'ja' ? 'pc-ca-JP' : ''
  }, [_c('h3', {
    staticClass: "text-center",
    class: _vm.$i18n.locale == 'ja' ? 'h3-JP' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.message.path.title")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1-to-mobile-body-regular text-center text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.message.path.text")) + " ")])]), _c('LeftLeft', {
    staticClass: "d-md-none w-100"
  }), _c('LeftLeftEx', {
    staticClass: "d-none d-md-block"
  })], 1), _c('div', {
    staticClass: "principles-item freedom"
  }, [_c('div', {
    staticClass: "pc pc-fe",
    class: _vm.$i18n.locale == 'ja' ? 'pc-fe-JP' : ''
  }, [_c('h3', {
    staticClass: "text-center",
    class: _vm.$i18n.locale == 'ja' ? 'h3-JP' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.message.freedom.title")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1-to-mobile-body-regular text-center text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.message.freedom.text")) + " ")])]), _c('LeftRight', {
    staticClass: "d-md-none w-100"
  }), _c('RightRightFreedom', {
    staticClass: "d-none d-md-block"
  })], 1), _c('div', {
    staticClass: "principles-item diversity"
  }, [_c('div', {
    staticClass: "pc pc-di",
    class: _vm.$i18n.locale == 'ja' ? 'pc-di-JP' : ''
  }, [_c('h3', {
    staticClass: "text-center",
    class: _vm.$i18n.locale == 'ja' ? 'h3-JP' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.message.culture.title")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1-to-mobile-body-regular text-center text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.message.culture.text")) + " ")])]), _c('LeftLeft', {
    staticClass: "d-md-none w-100"
  }), _c('RightRight', {
    staticClass: "d-none d-md-block"
  })], 1), _c('div', {
    staticClass: "principles-item collaboration"
  }, [_c('div', {
    staticClass: "pc pc-co",
    class: _vm.$i18n.locale == 'ja' ? 'pc-co-JP' : ''
  }, [_c('h3', {
    staticClass: "text-center",
    class: _vm.$i18n.locale == 'ja' ? 'h3-JP' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.message.collaboration.title")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1-to-mobile-body-regular text-center text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.message.collaboration.text")) + " ")])]), _c('LeftRight', {
    staticClass: "d-md-none w-100"
  }), _c('LeftLeftCollab', {
    staticClass: "d-none d-md-block"
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }