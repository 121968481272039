var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "jobs section"
  }, [_c('h2', {
    staticClass: "light-theme-title text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.current-jobs.title")) + " ")]), _c('div', {
    staticClass: "jobs-items"
  }, _vm._l(_vm.$t('careers.current-jobs.jobs'), function (job, index) {
    return _c('div', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal",
        value: ("modal-" + index),
        expression: "`modal-${index}`"
      }],
      key: index,
      staticClass: "jobs-item d-flex align-items-center justify-content-between"
    }, [_c('span', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', [_c(("Icon" + index), {
      tag: "component",
      staticClass: "icon"
    })], 1), _c('h3', {
      staticClass: "h3trans light-theme-title"
    }, [_vm._v(" " + _vm._s(job.title) + " ")])]), _c('span', {
      staticClass: "d-none d-md-inline-block",
      staticStyle: {
        "margin-right": "0"
      }
    }, [_c('font-awesome-icon', {
      staticStyle: {
        "height": "18px",
        "width": "11px"
      },
      attrs: {
        "icon": "chevron-right"
      }
    })], 1)]);
  }), 0), _c('h3', {
    staticClass: "text-center light-theme-title let"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.current-jobs.subtitle")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("careers.current-jobs.subtitle2")) + " ")])]), _vm._l(_vm.$t('careers.current-jobs.jobs'), function (job, index) {
    return _c('b-modal', {
      key: index,
      attrs: {
        "id": ("modal-" + index),
        "size": "lg",
        "hide-header": true,
        "hide-footer": true,
        "no-close-on-backdrop": true,
        "no-close-on-esc": true
      }
    }, [_c('span', {
      staticClass: "position-absolute close-icon d-flex justify-content-center align-items-center close-icon",
      on: {
        "click": function ($event) {
          return _vm.handleCloseModal(("modal-" + index));
        }
      }
    }, [_c('CloseIcon')], 1), _c('div', {
      staticClass: "jobs-description"
    }, [_c('div', {
      staticClass: "d-none d-md-block"
    }, [_c('p', {
      staticClass: "light-theme-title d-none d-md-block subtitle-bodyLarge",
      staticStyle: {
        "margin-bottom": "8px !important"
      }
    }, [_c('span', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s(job.title))])])]), _c('div', {
      staticClass: "d-md-none"
    }, [_c('h1', {
      staticClass: "text-primary"
    }, [_vm._v(" " + _vm._s(job.title) + " ")])]), _c('div', {
      staticClass: "body-paragraph-1 text-grey-primary-main-500 text-description",
      staticStyle: {
        "margin-bottom": "35px !important"
      }
    }, [_c('p', {
      staticStyle: {
        "margin-bottom": "0 !important"
      }
    }, [_vm._v(_vm._s(job.location))]), _c('p', {
      staticStyle: {
        "margin-bottom": "0 !important"
      }
    }, [_vm._v(_vm._s(job.contract_type))])]), _c('div', [_c('p', {
      staticClass: "body-paragraph-1 text-description"
    }, [_vm._v(_vm._s(job.description_0))]), _c('p', {
      staticClass: "body-paragraph-1 text-description"
    }, [_vm._v(_vm._s(job.description_1))]), _c('p', {
      staticClass: "body-paragraph-1 text-description"
    }, [_vm._v(_vm._s(job.description_2))])]), _vm.currentStep === 'jobs-description' ? _c('div', [_c('div', {
      staticClass: "jobs-requirements",
      staticStyle: {
        "margin-top": "35px !important"
      }
    }, [_c('h3', {
      staticClass: "text-primary text-subheader"
    }, [_vm._v(" " + _vm._s(job.requirements.title) + " ")]), _vm._l(job.requirements.all, function (requirement) {
      return _c('p', {
        key: requirement,
        staticClass: "text-grey-primary-main-500 body-paragraph-1 text-content"
      }, [_c('span', [_c('SubtractIcon')], 1), _vm._v(" " + _vm._s(requirement) + " ")]);
    })], 2), _c('div', {
      staticClass: "jobs-responsibilities",
      staticStyle: {
        "margin-top": "24px !important"
      }
    }, [_c('h3', {
      staticClass: "text-primary text-subheader"
    }, [_vm._v(" " + _vm._s(job.responsibilities.title) + " ")]), _vm._l(job.responsibilities.all, function (responsibility) {
      return _c('p', {
        key: responsibility,
        staticClass: "text-grey-primary-main-500 body-paragraph-1 text-content"
      }, [_c('span', [_c('SubtractIcon')], 1), _vm._v(" " + _vm._s(responsibility) + " ")]);
    })], 2), _c('div', {
      staticClass: "jobs-benefits",
      staticStyle: {
        "margin-top": "24px !important"
      }
    }, [_c('h3', {
      staticClass: "text-primary text-subheader"
    }, [_vm._v(" " + _vm._s(job.benefits.title) + " ")]), _vm._l(job.benefits.all, function (ben) {
      return _c('p', {
        key: ben,
        staticClass: "text-grey-primary-main-500 body-paragraph-1 text-content"
      }, [_c('span', [_c('SubtractIcon')], 1), _vm._v(" " + _vm._s(ben) + " ")]);
    })], 2), _c('div', {
      staticClass: "jobs-buttons d-flex justify-content-center",
      staticStyle: {
        "gap": "16px"
      }
    }, [_c('b-button', {
      staticClass: "round-btn-16-24",
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          _vm.modifiedTitle = true;
          _vm.currentStep = 'apply';
        }
      }
    }, [_c('span', {
      staticClass: "text-white-button button-medium"
    }, [_vm._v(" " + _vm._s(_vm.$t("careers.current-jobs.buttons.apply")) + " "), _c('span', {
      staticClass: "d-none d-md-inline"
    }, [_vm._v(_vm._s(_vm.$t("careers.current-jobs.buttons.apply2")))])])])], 1)]) : _vm._e(), _vm.currentStep === 'apply' ? _c('div', [_c('ApplicationForm', {
      attrs: {
        "title": job.title,
        "index": index,
        "currentStep": "jobs-descriptions"
      },
      on: {
        "changeStep": _vm.handleChangeStep,
        "modifyTitle": function ($event) {
          _vm.modifiedTitle = true;
        },
        "hideTitle": function ($event) {
          _vm.modifiedTitle = false;
        }
      }
    })], 1) : _vm._e()])]);
  }), _c('b-modal', {
    staticClass: "job-application-form",
    attrs: {
      "centered": "",
      "id": "job-application-form",
      "size": "md",
      "hide-header": true,
      "hide-footer": true,
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    }
  }, [_c('p', {
    staticClass: "text-right",
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('job-application-form');
      }
    }
  }, [_c('CloseForm')], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('JobApplication', {
    staticClass: "mb-22px"
  }), _c('p', {
    staticClass: "job-application-form__title roboto"
  }, [_vm._v("Thank you!")]), _c('p', {
    staticClass: "job-application-form__body"
  }, [_vm._v(" Your resume has been successfully submitted. ")]), _c('b-button', {
    staticClass: "button-done",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('job-application-form');
      }
    }
  }, [_c('span', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" Done ")])])], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }