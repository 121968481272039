var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "16",
      "height": "20",
      "viewBox": "0 0 16 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M0.5 4.16732C0.5 2.32637 1.99238 0.833984 3.83333 0.833984H10.786C11.67 0.833984 12.5179 1.18517 13.143 1.8103L14.5237 3.19101C15.1488 3.81613 15.5 4.66397 15.5 5.54803V15.834C15.5 17.6749 14.0076 19.1673 12.1667 19.1673H3.83333C1.99238 19.1673 0.5 17.6749 0.5 15.834V4.16732ZM13.8333 6.66732V15.834C13.8333 16.7545 13.0871 17.5006 12.1667 17.5006H3.83333C2.91286 17.5006 2.16667 16.7545 2.16667 15.834V4.16732C2.16667 3.24684 2.91286 2.50065 3.83333 2.50065H9.66667V4.16732C9.66667 5.54803 10.786 6.66732 12.1667 6.66732H13.8333ZM13.7409 5.00065C13.659 4.76531 13.5247 4.54905 13.3452 4.36952L11.9645 2.98881C11.7849 2.80928 11.5687 2.67494 11.3333 2.5931V4.16732C11.3333 4.62755 11.7064 5.00065 12.1667 5.00065H13.7409Z",
      "fill": "#777E91"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }