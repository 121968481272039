var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "295",
      "height": "201",
      "viewBox": "0 0 295 201",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_504_49643",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M263.03 0C280.311 0 294.32 14.0092 294.32 31.2904V136.687C294.32 153.968 280.311 167.977 263.03 167.977H254.84V201.001L225.33 167.977H31.2917C14.0105 167.977 0.00134277 153.968 0.00134277 136.687V31.2904C0.00134277 14.0092 14.0105 0 31.2917 0H263.03Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M263.03 0C280.311 0 294.32 14.0092 294.32 31.2904V136.687C294.32 153.968 280.311 167.977 263.03 167.977H254.84V201.001L225.33 167.977H31.2917C14.0105 167.977 0.00134277 153.968 0.00134277 136.687V31.2904C0.00134277 14.0092 14.0105 0 31.2917 0H263.03Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M254.84 167.977H250.84V163.977H254.84V167.977ZM254.84 201.001H258.84V211.48L251.858 203.666L254.84 201.001ZM225.33 167.977V163.977H227.12L228.312 165.312L225.33 167.977ZM290.32 31.2904C290.32 16.2183 278.102 4 263.03 4V-4C282.52 -4 298.32 11.8001 298.32 31.2904H290.32ZM290.32 136.687V31.2904H298.32V136.687H290.32ZM263.03 163.977C278.102 163.977 290.32 151.759 290.32 136.687H298.32C298.32 156.177 282.52 171.977 263.03 171.977V163.977ZM254.84 163.977H263.03V171.977H254.84V163.977ZM258.84 167.977V201.001H250.84V167.977H258.84ZM251.858 203.666L222.347 170.642L228.312 165.312L257.823 198.336L251.858 203.666ZM31.2917 163.977H225.33V171.977H31.2917V163.977ZM4.00134 136.687C4.00134 151.759 16.2197 163.977 31.2917 163.977V171.977C11.8014 171.977 -3.99866 156.177 -3.99866 136.687H4.00134ZM4.00134 31.2904V136.687H-3.99866V31.2904H4.00134ZM31.2917 4C16.2196 4 4.00134 16.2183 4.00134 31.2904H-3.99866C-3.99866 11.8 11.8013 -4 31.2917 -4V4ZM263.03 4H31.2917V-4H263.03V4Z",
      "fill": "#ED6400",
      "mask": "url(#path-1-inside-1_504_49643)"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }