<template>
  <div class="">
    <div class="hero-twoPart overflow-hide">
      <div class="d-flex justify-content-between">
        <!-- PC Hero Icons -->

        <div
          class="d-none d-xl-block"
          style="margin-top: 252px; margin-left: -100px"
        >
          <HeroPCLeft />
        </div>
        <div
          class="d-none d-xl-block"
          style="margin-top: 118px; margin-right: -170px"
        >
          <HeroPCRight style="transform: translateX(-40px)" />
        </div>

        <!-- Tablet Hero Icons -->

        <div
          class="d-none d-md-block d-xl-none"
          style="transform: translate(-20px, 270px)"
        >
          <HeroTabletLeft />
        </div>
        <div
          class="d-none d-md-block d-xl-none"
          style="transform: translate(-50px, 200px)"
        >
          <HeroTabletRight />
        </div>
      </div>
      <div class="hero-content">
        <h1 class="hero-header light-theme-title text-center">
          {{ $t("careers.hero.header") }}&nbsp;<span class="text-primary">{{
            $t("careers.hero.header2")
          }}</span>
        </h1>
        <h4
          class="subtitle-bodyLarge-to-mobile-body-regular hero-subtitle text-center font-weight-normal text-grey-primary-main-500"
        >
          {{ $t("careers.hero.subheader") }}
        </h4>
        <div class="d-md-none">
          <HeroMobileIcon />
        </div>
        <b-button variant="primary" class="round-btn-16-24" @click="gotoJobs">
          <p class="text-white-button button-medium">
            {{ $t("careers.hero.find-button") }}
          </p></b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import HeroPCLeft from "../svg/hero/HeroPCLeft.vue";
import HeroPCRight from "../svg/hero/HeroPCRight.vue";
import HeroTabletLeft from "../svg/hero/HeroTabletLeft.vue";
import HeroTabletRight from "../svg/hero/HeroTabletRight.vue";
import HeroMobileIcon from "../svg/hero/HeroMobileIcon.vue";
export default {
  components: {
    HeroPCLeft,
    HeroPCRight,
    HeroTabletRight,
    HeroTabletLeft,
    HeroMobileIcon,
  },
  props: ["gotoJobs"],
};
</script>

<style lang="scss" scoped>
h1 {
  @media (max-width: 767px) {
    margin-top: 40px !important;
    max-width: 341px !important;
    font-size: 38px !important;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: 596px !important;
    margin-top: -20px !important;
  }
  @media (min-width: 1200px) {
    max-width: 596px !important;
    margin-top: 20px !important;
  }
}
h4 {
  @media (max-width: 767px) {
    max-width: 310px !important;
    margin: auto !important;
    margin-bottom: 40px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    max-width: 464px !important;
  }
  @media (min-width: 1200px) {
    max-width: 464px !important;
  }
}

.round-btn-16-24 {
  @media (max-width: 767px) {
    margin-top: 16px;
    margin-bottom: 65px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 24px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 24px !important;
  }
}
.overflow-hide {
  @media (max-width: 767px) {
    overflow: hidden;
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    // overflow: hidden;
  }
}
</style>
