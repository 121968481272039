var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "738",
      "height": "647",
      "viewBox": "0 0 738 647",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_2238_43266",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M563.375 55C574.766 55 584 64.2342 584 75.6252V145.098C584 156.489 574.766 165.723 563.375 165.723H557.977V187.489L538.526 165.723H410.624C399.233 165.723 389.999 156.489 389.999 145.098V75.6252C389.999 64.2342 399.233 55 410.624 55H563.375Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M563.375 55C574.766 55 584 64.2342 584 75.6252V145.098C584 156.489 574.766 165.723 563.375 165.723H557.977V187.489L538.526 165.723H410.624C399.233 165.723 389.999 156.489 389.999 145.098V75.6252C389.999 64.2342 399.233 55 410.624 55H563.375Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M557.977 165.723H554.977V162.723H557.977V165.723ZM557.977 187.489H560.977V195.349L555.74 189.488L557.977 187.489ZM538.526 165.723V162.723H539.868L540.763 163.724L538.526 165.723ZM581 75.6252C581 65.8911 573.109 58 563.375 58V52C576.423 52 587 62.5774 587 75.6252H581ZM581 145.098V75.6252H587V145.098H581ZM563.375 162.723C573.109 162.723 581 154.832 581 145.098H587C587 158.145 576.423 168.723 563.375 168.723V162.723ZM557.977 162.723H563.375V168.723H557.977V162.723ZM560.977 165.723V187.489H554.977V165.723H560.977ZM555.74 189.488L536.289 167.722L540.763 163.724L560.214 185.49L555.74 189.488ZM410.624 162.723H538.526V168.723H410.624V162.723ZM392.999 145.098C392.999 154.832 400.89 162.723 410.624 162.723V168.723C397.576 168.723 386.999 158.145 386.999 145.098H392.999ZM392.999 75.6252V145.098H386.999V75.6252H392.999ZM410.624 58C400.89 58 392.999 65.8911 392.999 75.6252H386.999C386.999 62.5774 397.576 52 410.624 52V58ZM563.375 58H410.624V52H563.375V58Z",
      "fill": "#ED6400",
      "mask": "url(#path-1-inside-1_2238_43266)"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-3-inside-2_2238_43266",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M435.627 296C444.67 296 452 303.33 452 312.373V367.521C452 376.563 444.67 383.894 435.627 383.894H431.344V401.173L415.902 383.894H314.371C305.329 383.894 297.999 376.563 297.999 367.521V312.373C297.999 303.33 305.329 296 314.371 296H435.627Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M435.627 296C444.67 296 452 303.33 452 312.373V367.521C452 376.563 444.67 383.894 435.627 383.894H431.344V401.173L415.902 383.894H314.371C305.329 383.894 297.999 376.563 297.999 367.521V312.373C297.999 303.33 305.329 296 314.371 296H435.627Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M431.344 383.894H428.344V380.894H431.344V383.894ZM431.344 401.173H434.344V409.033L429.107 403.172L431.344 401.173ZM415.902 383.894V380.894H417.245L418.139 381.895L415.902 383.894ZM449 312.373C449 304.987 443.013 299 435.627 299V293C446.327 293 455 301.673 455 312.373H449ZM449 367.521V312.373H455V367.521H449ZM435.627 380.894C443.013 380.894 449 374.906 449 367.521H455C455 378.22 446.327 386.894 435.627 386.894V380.894ZM431.344 380.894H435.627V386.894H431.344V380.894ZM434.344 383.894V401.173H428.344V383.894H434.344ZM429.107 403.172L413.665 385.893L418.139 381.895L433.581 399.174L429.107 403.172ZM314.371 380.894H415.902V386.894H314.371V380.894ZM300.999 367.521C300.999 374.906 306.986 380.894 314.371 380.894V386.894C303.672 386.894 294.999 378.22 294.999 367.521H300.999ZM300.999 312.373V367.521H294.999V312.373H300.999ZM314.371 299C306.986 299 300.999 304.987 300.999 312.373H294.999C294.999 301.673 303.672 293 314.371 293V299ZM435.627 299H314.371V293H435.627V299Z",
      "fill": "#ED6400",
      "mask": "url(#path-3-inside-2_2238_43266)"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-5-inside-3_2238_43266",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M89.6946 559C83.2359 559 78 564.236 78 570.695V610.086C78 616.545 83.2359 621.78 89.6946 621.78H92.7539V634.121L103.782 621.78H176.305C182.764 621.78 188 616.545 188 610.086V570.695C188 564.236 182.764 559 176.305 559H89.6946Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M89.6946 559C83.2359 559 78 564.236 78 570.695V610.086C78 616.545 83.2359 621.78 89.6946 621.78H92.7539V634.121L103.782 621.78H176.305C182.764 621.78 188 616.545 188 610.086V570.695C188 564.236 182.764 559 176.305 559H89.6946Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M92.7539 621.78H95.7539V618.78H92.7539V621.78ZM92.7539 634.121H89.7539V641.981L94.9909 636.12L92.7539 634.121ZM103.782 621.78V618.78H102.44L101.545 619.782L103.782 621.78ZM81 570.695C81 565.893 84.8927 562 89.6946 562V556C81.579 556 75 562.579 75 570.695H81ZM81 610.086V570.695H75V610.086H81ZM89.6946 618.78C84.8927 618.78 81 614.888 81 610.086H75C75 618.201 81.579 624.78 89.6946 624.78V618.78ZM92.7539 618.78H89.6946V624.78H92.7539V618.78ZM89.7539 621.78V634.121H95.7539V621.78H89.7539ZM94.9909 636.12L106.019 623.779L101.545 619.782L90.5169 632.122L94.9909 636.12ZM176.305 618.78H103.782V624.78H176.305V618.78ZM185 610.086C185 614.888 181.107 618.78 176.305 618.78V624.78C184.421 624.78 191 618.202 191 610.086H185ZM185 570.695V610.086H191V570.695H185ZM176.305 562C181.107 562 185 565.893 185 570.695H191C191 562.579 184.421 556 176.305 556V562ZM89.6946 562H176.305V556H89.6946V562Z",
      "fill": "#ED6400",
      "mask": "url(#path-5-inside-3_2238_43266)"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M545.857 242.905C550.853 242.905 554.904 238.854 554.904 233.858C554.904 228.861 550.853 224.811 545.857 224.811C540.86 224.811 536.81 228.861 536.81 233.858C536.81 238.854 540.86 242.905 545.857 242.905ZM551.02 231.783C551.373 231.43 551.373 230.857 551.02 230.504C550.667 230.15 550.094 230.15 549.741 230.504L544.952 235.292L542.878 233.218C542.524 232.865 541.951 232.865 541.598 233.218C541.245 233.571 541.245 234.144 541.598 234.497L544.312 237.212C544.666 237.565 545.238 237.565 545.592 237.212L551.02 231.783Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.8567 553.905C15.8533 553.905 19.9038 549.854 19.9038 544.858C19.9038 539.861 15.8533 535.811 10.8567 535.811C5.8601 535.811 1.80957 539.861 1.80957 544.858C1.80957 549.854 5.8601 553.905 10.8567 553.905ZM16.02 542.783C16.3733 542.43 16.3733 541.857 16.02 541.504C15.6667 541.15 15.0938 541.15 14.7405 541.504L9.95198 546.292L7.87757 544.218C7.52426 543.865 6.95143 543.865 6.59811 544.218C6.2448 544.571 6.2448 545.144 6.59811 545.497L9.31225 548.212C9.66556 548.565 10.2384 548.565 10.5917 548.212L16.02 542.783Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M671.579 181.629C683.206 181.629 692.631 172.204 692.631 160.577C692.631 148.95 683.206 139.525 671.579 139.525C659.952 139.525 650.527 148.95 650.527 160.577C650.527 172.204 659.952 181.629 671.579 181.629ZM671.579 186.892C686.112 186.892 697.894 175.11 697.894 160.577C697.894 146.043 686.112 134.262 671.579 134.262C657.045 134.262 645.264 146.043 645.264 160.577C645.264 175.11 657.045 186.892 671.579 186.892Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M676.174 176.831C678.118 172.943 679.472 167.204 679.472 160.577C679.472 153.95 678.118 148.211 676.174 144.323C674.073 140.12 672.143 139.525 671.578 139.525C671.012 139.525 669.082 140.12 666.981 144.323C665.037 148.211 663.683 153.95 663.683 160.577C663.683 167.204 665.037 172.943 666.981 176.831C669.082 181.034 671.012 181.629 671.578 181.629C672.143 181.629 674.073 181.034 676.174 176.831ZM671.578 186.892C678.844 186.892 684.735 175.11 684.735 160.577C684.735 146.043 678.844 134.262 671.578 134.262C664.311 134.262 658.42 146.043 658.42 160.577C658.42 175.11 664.311 186.892 671.578 186.892Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M697.764 163.21C697.85 162.345 697.894 161.467 697.894 160.579C697.894 159.691 697.85 158.813 697.764 157.947H645.394C645.308 158.813 645.264 159.691 645.264 160.579C645.264 161.467 645.308 162.345 645.394 163.21H697.764Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M133.579 219.629C145.206 219.629 154.631 210.204 154.631 198.577C154.631 186.95 145.206 177.525 133.579 177.525C121.952 177.525 112.527 186.95 112.527 198.577C112.527 210.204 121.952 219.629 133.579 219.629ZM133.579 224.892C148.112 224.892 159.894 213.11 159.894 198.577C159.894 184.043 148.112 172.262 133.579 172.262C119.045 172.262 107.264 184.043 107.264 198.577C107.264 213.11 119.045 224.892 133.579 224.892Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M138.174 214.831C140.118 210.943 141.472 205.204 141.472 198.577C141.472 191.95 140.118 186.211 138.174 182.323C136.073 178.12 134.143 177.525 133.578 177.525C133.012 177.525 131.082 178.12 128.981 182.323C127.037 186.211 125.683 191.95 125.683 198.577C125.683 205.204 127.037 210.943 128.981 214.831C131.082 219.034 133.012 219.629 133.578 219.629C134.143 219.629 136.073 219.034 138.174 214.831ZM133.578 224.892C140.844 224.892 146.735 213.11 146.735 198.577C146.735 184.043 140.844 172.262 133.578 172.262C126.311 172.262 120.42 184.043 120.42 198.577C120.42 213.11 126.311 224.892 133.578 224.892Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M159.764 201.21C159.85 200.345 159.894 199.467 159.894 198.579C159.894 197.691 159.85 196.813 159.764 195.947H107.394C107.308 196.813 107.264 197.691 107.264 198.579C107.264 199.467 107.308 200.345 107.394 201.21H159.764Z",
      "fill": "#07B53B"
    }
  }), _c('circle', {
    attrs: {
      "cx": "374.972",
      "cy": "444.972",
      "r": "4.47161",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "324.972",
      "cy": "223.972",
      "r": "5.97161",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "23.9727",
      "cy": "222",
      "r": "16",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "503",
      "cy": "429",
      "r": "16",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "452",
      "cy": "8",
      "r": "8",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M725.101 599.396H690.395C687.656 599.396 685.437 601.615 685.437 604.354V629.144C685.437 631.883 687.656 634.102 690.395 634.102H725.101C727.84 634.102 730.06 631.883 730.06 629.144V604.354C730.06 601.615 727.84 599.396 725.101 599.396ZM690.395 594.438C684.918 594.438 680.479 598.877 680.479 604.354V629.144C680.479 634.621 684.918 639.06 690.395 639.06H725.101C730.578 639.06 735.018 634.621 735.018 629.144V604.354C735.018 598.877 730.578 594.438 725.101 594.438H690.395Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M710.229 616.749C710.229 609.903 715.778 604.354 722.624 604.354H732.54C733.909 604.354 735.019 605.463 735.019 606.833C735.019 608.202 733.909 609.312 732.54 609.312H722.624C718.516 609.312 715.187 612.641 715.187 616.749C715.187 620.856 718.516 624.186 722.624 624.186H732.54C733.909 624.186 735.019 625.296 735.019 626.665C735.019 628.034 733.909 629.144 732.54 629.144H722.624C715.778 629.144 710.229 623.594 710.229 616.749Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M725.102 616.749C725.102 618.118 723.992 619.228 722.623 619.228C721.253 619.228 720.144 618.118 720.144 616.749C720.144 615.379 721.253 614.27 722.623 614.27C723.992 614.27 725.102 615.379 725.102 616.749Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }