var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "695",
      "height": "408",
      "viewBox": "0 0 695 408",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "filter": "url(#filter0_d_504_50065)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M196.545 124.418C193.956 125.385 191.495 126.744 189.125 128.505C178.541 136.426 171.66 148.856 167.065 160.979C162.76 172.272 160.764 184.375 160.595 196.492C160.085 233.361 180.823 275.546 220.802 281.716C229.674 283.082 238.803 282.462 247.431 280.059C279.536 271.052 294.387 238.836 288.527 207.53C282.284 174.273 257.793 139.788 226.005 126.34C216.361 122.241 205.922 120.864 196.545 124.418Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M200.907 125.535C197.955 125.775 195.033 126.405 192.214 127.472C189.654 128.441 187.18 129.796 184.825 131.555C174.287 139.421 167.434 151.767 162.828 163.799C161.291 167.791 160.054 171.893 159.085 176.047",
      "stroke": "#07B53B",
      "stroke-width": "3.96527",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M235.483 136.831C231.003 133.866 226.315 131.328 221.441 129.278C218.519 128.044 215.515 127.072 212.484 126.398",
      "stroke": "#07B53B",
      "stroke-width": "3.96527",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M259.575 159.039C252.616 150.401 244.478 142.783 235.484 136.836",
      "stroke": "#07B53B",
      "stroke-width": "3.96527",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M282.47 205.077C278.633 188.895 270.574 172.689 259.574 159.039",
      "stroke": "#07B53B",
      "stroke-width": "3.96527",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M284.579 219.013C284.413 215.932 284.034 212.838 283.463 209.751C283.171 208.188 282.842 206.638 282.467 205.075",
      "stroke": "#07B53B",
      "stroke-width": "3.96527",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M227.003 284.152C232.242 284.013 237.454 283.205 242.495 281.79C264.321 275.645 278.16 258.753 282.873 238.761",
      "stroke": "#07B53B",
      "stroke-width": "3.96527",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M204.355 280.219C205.424 280.507 206.509 280.762 207.611 280.984",
      "stroke": "#07B53B",
      "stroke-width": "3.96527",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M157.428 184.868C156.761 189.559 156.403 194.301 156.32 199.037C155.838 229.643 170.151 263.925 198.107 277.845",
      "stroke": "#07B53B",
      "stroke-width": "3.96527",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "192.006",
      "cy": "85.779",
      "r": "4.23208",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "376.916",
      "cy": "352.072",
      "r": "4.23208",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "135.004",
      "cy": "98.477",
      "r": "5.20871",
      "stroke": "#07B53B",
      "stroke-width": "1.30218"
    }
  }), _c('circle', {
    attrs: {
      "cx": "369.752",
      "cy": "331.892",
      "r": "3.58099",
      "stroke": "#07B53B",
      "stroke-width": "1.30218"
    }
  }), _c('circle', {
    attrs: {
      "cx": "94.02",
      "cy": "71.7817",
      "r": "5.20871",
      "stroke": "#07B53B",
      "stroke-width": "1.30218"
    }
  }), _c('circle', {
    attrs: {
      "cx": "211.867",
      "cy": "356.304",
      "r": "7.81307",
      "stroke": "#07B53B",
      "stroke-width": "1.30218"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter1_d_504_50065)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M311.757 190.241C309.485 189.962 307.155 190.019 304.734 190.404C293.905 192.168 284.032 198.64 275.952 205.759C268.408 212.381 262.238 220.474 257.401 229.274C242.687 256.052 241.459 294.812 268.328 314.554C274.292 318.932 281.217 321.956 288.468 323.484C315.476 329.124 338.88 311.213 346.768 286.078C355.134 259.37 350.618 224.813 332.577 202.868C327.111 196.196 320.003 191.212 311.757 190.241Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M314.424 192.677C312.17 191.724 309.788 191.068 307.312 190.772C305.063 190.502 302.727 190.548 300.322 190.935C289.561 192.664 279.761 199.079 271.727 206.123C269.055 208.457 266.561 210.986 264.241 213.655",
      "stroke": "#07B53B",
      "stroke-width": "3.27864",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M335.345 214.164C333.216 210.281 330.77 206.632 327.997 203.269C326.337 201.249 324.515 199.39 322.559 197.738",
      "stroke": "#07B53B",
      "stroke-width": "3.27864",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M344.368 239.624C342.623 230.643 339.62 221.957 335.344 214.168",
      "stroke": "#07B53B",
      "stroke-width": "3.27864",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M343.275 282.063C346.738 268.754 347.122 253.814 344.363 239.621",
      "stroke": "#07B53B",
      "stroke-width": "3.27864",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M339.416 293.065C340.489 290.747 341.412 288.338 342.19 285.86C342.582 284.606 342.942 283.345 343.273 282.058",
      "stroke": "#07B53B",
      "stroke-width": "3.27864",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M272.042 318.734C275.929 320.634 280.057 322.036 284.294 322.927C302.648 326.773 319.322 319.701 330.519 306.872",
      "stroke": "#07B53B",
      "stroke-width": "3.27864",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M256.988 307.191C257.658 307.811 258.354 308.412 259.074 308.996",
      "stroke": "#07B53B",
      "stroke-width": "3.27864",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M259.603 219.478C257.297 222.656 255.197 225.989 253.301 229.423C241.086 251.636 238.273 282.194 253.336 303.066",
      "stroke": "#07B53B",
      "stroke-width": "3.27864",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter2_d_504_50065)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M434.02 200.804C432.39 201.844 430.905 203.134 429.541 204.687C423.461 211.657 420.58 221.177 419.22 230.152C417.936 238.516 418.352 247.099 420.022 255.424C425.105 280.757 445.521 306.631 473.807 305.026C480.084 304.666 486.244 302.91 491.798 300.005C512.455 289.151 517.878 264.914 509.253 244.322C500.078 222.449 478.226 202.397 454.477 197.823C447.269 196.422 439.917 197.002 434.02 200.804Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M437.156 200.937C435.17 201.531 433.262 202.389 431.489 203.53C429.879 204.566 428.384 205.854 427.031 207.402C420.975 214.323 418.104 223.777 416.726 232.687C416.262 235.645 416.021 238.635 415.971 241.621",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M462.502 203.644C458.996 202.266 455.411 201.21 451.77 200.515C449.587 200.096 447.386 199.868 445.211 199.848",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M482.285 215.339C476.244 210.437 469.545 206.404 462.508 203.641",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M504.759 243.541C499.742 233.017 491.83 223.091 482.281 215.344",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M508.257 252.779C507.688 250.693 506.972 248.629 506.125 246.598C505.695 245.57 505.24 244.556 504.753 243.541",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M478.449 305.77C482.015 304.911 485.466 303.599 488.71 301.896C502.751 294.51 509.735 280.927 510.011 266.549",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M462.352 306.379C463.126 306.421 463.906 306.438 464.694 306.429",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M416.136 247.9C416.372 251.21 416.827 254.51 417.469 257.765C421.657 278.796 436.521 300.192 457.722 305.654",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter3_d_504_50065)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M667.217 228.574C665.983 227.691 664.608 226.986 663.075 226.442C656.202 224.035 648.377 224.676 641.389 226.261C634.872 227.727 628.683 230.487 623.044 234.08C605.887 245.018 592.742 267.216 602.058 287.243C604.128 291.686 607.191 295.644 610.923 298.835C624.843 310.688 644.218 307.673 656.872 295.528C670.309 282.618 678.762 261.048 675.293 242.537C674.249 236.915 671.708 231.756 667.217 228.574Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M668.006 230.861C666.999 229.592 665.822 228.456 664.475 227.499C663.252 226.63 661.876 225.917 660.351 225.381C653.528 222.98 645.759 223.613 638.818 225.172C636.512 225.685 634.246 226.369 632.038 227.189",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M673.298 250.003C673.305 247.068 673.052 244.167 672.517 241.331C672.198 239.628 671.734 237.968 671.125 236.387",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M670.375 267.689C672.242 261.906 673.283 255.895 673.293 250.004",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M656.112 292.061C662.403 285.406 667.424 276.825 670.375 267.684",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M650.33 297.252C651.699 296.241 653.01 295.13 654.259 293.933C654.89 293.327 655.505 292.706 656.111 292.062",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M602.846 290.861C604.501 293.199 606.455 295.322 608.637 297.184C618.093 305.236 630.076 306.398 640.718 302.471",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M597.781 279.375C597.973 279.948 598.184 280.519 598.416 281.088",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M627.467 229.108C625.104 230.228 622.81 231.505 620.603 232.905C606.355 241.975 594.903 258.884 596.975 275.812",
      "stroke": "#07B53B",
      "stroke-width": "4.07973",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter4_d_504_50065)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M209.014 159.443C205.347 159.606 201.681 160.313 197.96 161.562C181.324 167.214 167.479 180.039 156.644 193.408C146.52 205.853 138.968 220.254 133.715 235.416C117.735 281.553 126.327 343.016 174.155 367.036C184.771 372.362 196.537 375.296 208.412 375.786C252.628 377.525 284.751 343.071 290.387 301.335C296.352 256.99 279.825 203.679 245.35 173.844C234.898 164.769 222.311 158.791 209.014 159.443Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M213.922 162.483C210.107 161.58 206.17 161.18 202.18 161.371C198.558 161.545 194.884 162.24 191.191 163.491C174.672 169.087 160.936 181.824 150.157 195.083C146.57 199.48 143.315 204.137 140.375 208.968",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M252.746 190.826C248.336 185.265 243.489 180.156 238.204 175.586C235.037 172.84 231.66 170.39 228.125 168.305",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M273.867 228.607C268.687 214.896 261.6 201.984 252.746 190.828",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M283.614 295.891C285.486 273.961 282.057 250.275 273.867 228.605",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M280.5 314.297C281.567 310.353 282.372 306.304 282.932 302.185C283.212 300.1 283.44 298.015 283.615 295.895",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M181.078 372.748C187.728 374.712 194.622 375.824 201.552 376.102C231.562 377.284 255.973 361.679 270.182 338.445",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M154.195 358.555C155.42 359.354 156.68 360.119 157.975 360.849",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M134.629 219.398C131.847 225.029 129.432 230.85 127.367 236.776C114.086 275.093 117.9 324.08 147.316 353.013",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-52-inside-1_504_50065",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M153.798 0C146.73 0 141 5.72991 141 12.7981V55.9064C141 62.9746 146.73 68.7045 153.798 68.7045H157.145V82.2137L169.217 68.7045H248.582C255.65 68.7045 261.38 62.9746 261.38 55.9064V12.7981C261.38 5.72991 255.65 0 248.582 0H153.798Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M153.798 0C146.73 0 141 5.72991 141 12.7981V55.9064C141 62.9746 146.73 68.7045 153.798 68.7045H157.145V82.2137L169.217 68.7045H248.582C255.65 68.7045 261.38 62.9746 261.38 55.9064V12.7981C261.38 5.72991 255.65 0 248.582 0H153.798Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M157.145 68.7045H159.979V65.8697H157.145V68.7045ZM157.145 82.2137H154.31V89.6403L159.258 84.1026L157.145 82.2137ZM169.217 68.7045V65.8697H167.948L167.103 66.8156L169.217 68.7045ZM143.835 12.7981C143.835 7.29553 148.296 2.8348 153.798 2.8348V-2.8348C145.164 -2.8348 138.165 4.16429 138.165 12.7981H143.835ZM143.835 55.9064V12.7981H138.165V55.9064H143.835ZM153.798 65.8697C148.296 65.8697 143.835 61.409 143.835 55.9064H138.165C138.165 64.5402 145.164 71.5393 153.798 71.5393V65.8697ZM157.145 65.8697H153.798V71.5393H157.145V65.8697ZM154.31 68.7045V82.2137H159.979V68.7045H154.31ZM159.258 84.1026L171.33 70.5934L167.103 66.8156L155.031 80.3248L159.258 84.1026ZM248.582 65.8697H169.217V71.5393H248.582V65.8697ZM258.545 55.9064C258.545 61.409 254.084 65.8697 248.582 65.8697V71.5393C257.215 71.5393 264.215 64.5402 264.215 55.9064H258.545ZM258.545 12.7981V55.9064H264.215V12.7981H258.545ZM248.582 2.8348C254.084 2.8348 258.545 7.29553 258.545 12.7981H264.215C264.215 4.16429 257.215 -2.8348 248.582 -2.8348V2.8348ZM153.798 2.8348H248.582V-2.8348H153.798V2.8348Z",
      "fill": "#ED6400",
      "mask": "url(#path-52-inside-1_504_50065)"
    }
  }), _c('path', {
    attrs: {
      "d": "M192.984 30.3875C192.984 27.7939 193.475 25.4884 194.457 23.4951C195.44 21.5018 196.82 19.9408 198.55 18.788C200.281 17.6593 202.293 17.0829 204.538 17.0829C206.83 17.0829 208.818 17.6593 210.572 18.788C212.303 19.9168 213.683 21.5018 214.665 23.5191C215.647 25.5364 216.139 27.8179 216.139 30.3875C216.139 30.4836 216.115 30.5797 216.115 30.6757H220.138C220.138 30.5797 220.138 30.4836 220.138 30.3875C220.138 27.962 219.764 25.7045 218.992 23.6152C218.22 21.5498 217.144 19.7246 215.741 18.1876C214.338 16.6266 212.7 15.4259 210.783 14.5853C208.888 13.7208 206.807 13.3125 204.514 13.3125C202.269 13.3125 200.188 13.7448 198.293 14.5853C196.399 15.4499 194.738 16.6507 193.335 18.1876C191.931 19.7487 190.855 21.5498 190.107 23.6152C189.359 25.6805 188.984 27.938 188.984 30.3875C188.984 30.4836 188.984 30.5797 188.984 30.6757H192.96C193.007 30.5797 192.984 30.4836 192.984 30.3875Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M209.006 30.6758C208.959 33.1253 208.491 35.3107 207.532 37.256C206.55 39.2733 205.17 40.8343 203.439 41.987C201.709 43.1157 199.697 43.6921 197.405 43.6921C195.16 43.6921 193.148 43.1157 191.418 41.987C189.687 40.8583 188.307 39.2733 187.325 37.256C186.366 35.3348 185.898 33.1253 185.851 30.6758H181.875C181.898 33.0053 182.273 35.1667 182.998 37.1599C183.746 39.2253 184.822 41.0504 186.225 42.5874C187.629 44.1484 189.266 45.3492 191.184 46.1897C193.078 47.0543 195.16 47.4625 197.405 47.4625C199.697 47.4625 201.779 47.0303 203.673 46.1897C205.568 45.3252 207.228 44.1244 208.632 42.5874C210.035 41.0264 211.111 39.2253 211.883 37.1599C212.631 35.1667 213.005 33.0053 213.029 30.6758H209.006Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M210.925 27.167C212.049 27.167 212.96 26.2315 212.96 25.0776C212.96 23.9237 212.049 22.9883 210.925 22.9883C209.802 22.9883 208.891 23.9237 208.891 25.0776C208.891 26.2315 209.802 27.167 210.925 27.167Z",
      "fill": "#07B53B"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter5_d_504_50065)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M135.591 105.636C130.992 104.397 126.174 103.831 121.07 103.918C98.2253 104.385 75.9476 114.845 57.1731 127.162C39.6499 138.614 24.5205 153.502 11.9273 170.238C-26.3852 221.168 -40.4764 300.763 9.00826 349.355C19.9947 360.133 33.3637 368.398 47.8514 373.674C101.829 393.216 155.406 363.122 179.159 313.584C204.367 260.94 205.368 188.334 174.733 137.781C165.458 122.418 152.297 110.056 135.591 105.636Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M147.391 111.467C143.027 108.84 138.311 106.79 133.292 105.451C128.736 104.236 123.905 103.645 118.831 103.736C96.1283 104.143 74.0115 114.495 55.349 126.66C49.1447 130.689 43.2478 135.172 37.6714 139.995",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M184.133 161.914C180.898 153.285 176.94 145.042 172.225 137.293C169.401 132.641 166.199 128.272 162.656 124.293",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M195.171 217.056C194.247 198.027 190.64 179.234 184.141 161.918",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M180.284 304.245C191.387 277.82 196.625 247.127 195.16 217.051",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M169.059 325.811C171.961 321.346 174.58 316.649 176.923 311.768C178.105 309.296 179.222 306.803 180.288 304.246",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M22.4873 358.939C29.9374 363.998 38.032 368.099 46.504 371.181C83.2037 384.498 119.691 374.811 146.597 351.644",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M-5.12109 330.75C-3.92399 332.224 -2.66962 333.669 -1.35798 335.084",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.3785 150.647C20.677 156.522 15.3542 162.779 10.4231 169.303C-21.3754 211.52 -36.2708 273.705 -11.4233 321.163",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_504_50065",
      "x": "155.299",
      "y": "122.379",
      "width": "139.769",
      "height": "170.625",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "5.28702"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2.64351"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_50065"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_50065",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_d_504_50065",
      "x": "242.683",
      "y": "190.066",
      "width": "112.509",
      "height": "143.204",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4.37151"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2.18576"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_50065"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_50065",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter2_d_504_50065",
      "x": "413.025",
      "y": "197.129",
      "width": "105.606",
      "height": "118.848",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "5.43964"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2.71982"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_50065"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_50065",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter3_d_504_50065",
      "x": "593.529",
      "y": "224.852",
      "width": "87.9847",
      "height": "92.3636",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "5.43964"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2.71982"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_50065"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_50065",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter4_d_504_50065",
      "x": "118.621",
      "y": "159.395",
      "width": "180.707",
      "height": "231.937",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.74206"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.87103"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_50065"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_50065",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter5_d_504_50065",
      "x": "-30.2558",
      "y": "103.91",
      "width": "235.656",
      "height": "290.777",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.49413"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.74706"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_504_50065"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_504_50065",
      "result": "shape"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }