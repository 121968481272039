var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "306",
      "height": "209",
      "viewBox": "0 0 306 209",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_504_49686",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M32.4493 0C14.528 0 0 14.5281 0 32.4493V141.749C0 159.67 14.528 174.198 32.4492 174.198H40.9453V208.45L71.5527 174.198H272.77C290.691 174.198 305.219 159.67 305.219 141.749V32.4493C305.219 14.528 290.691 0 272.77 0H32.4493Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M32.4493 0C14.528 0 0 14.5281 0 32.4493V141.749C0 159.67 14.528 174.198 32.4492 174.198H40.9453V208.45L71.5527 174.198H272.77C290.691 174.198 305.219 159.67 305.219 141.749V32.4493C305.219 14.528 290.691 0 272.77 0H32.4493Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M40.9453 174.198H44.9453V170.198H40.9453V174.198ZM40.9453 208.45H36.9453V218.929L43.9279 211.115L40.9453 208.45ZM71.5527 174.198V170.198H69.7627L68.57 171.533L71.5527 174.198ZM4 32.4493C4 16.7372 16.7372 4 32.4493 4V-4C12.3189 -4 -4 12.3189 -4 32.4493H4ZM4 141.749V32.4493H-4V141.749H4ZM32.4492 170.198C16.7372 170.198 4 157.461 4 141.749H-4C-4 161.88 12.3189 178.198 32.4492 178.198V170.198ZM40.9453 170.198H32.4492V178.198H40.9453V170.198ZM36.9453 174.198V208.45H44.9453V174.198H36.9453ZM43.9279 211.115L74.5353 176.864L68.57 171.533L37.9627 205.784L43.9279 211.115ZM272.77 170.198H71.5527V178.198H272.77V170.198ZM301.219 141.749C301.219 157.461 288.482 170.198 272.77 170.198V178.198C292.901 178.198 309.219 161.88 309.219 141.749H301.219ZM301.219 32.4493V141.749H309.219V32.4493H301.219ZM272.77 4C288.482 4 301.219 16.7372 301.219 32.4493H309.219C309.219 12.3189 292.901 -4 272.77 -4V4ZM32.4493 4H272.77V-4H32.4493V4Z",
      "fill": "#ED6400",
      "mask": "url(#path-1-inside-1_504_49686)"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }