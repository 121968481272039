var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "358",
      "height": "245",
      "viewBox": "0 0 358 245",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_504_49632",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M319.939 0C340.96 0 358 17.0404 358 38.0606V166.261C358 187.282 340.96 204.322 319.939 204.322H309.977V244.492L274.08 204.322H38.0606C17.0403 204.322 0 187.282 0 166.261V38.0606C0 17.0403 17.0403 0 38.0606 0H319.939Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M319.939 0C340.96 0 358 17.0404 358 38.0606V166.261C358 187.282 340.96 204.322 319.939 204.322H309.977V244.492L274.08 204.322H38.0606C17.0403 204.322 0 187.282 0 166.261V38.0606C0 17.0403 17.0403 0 38.0606 0H319.939Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M309.977 204.322H305.977V200.322H309.977V204.322ZM309.977 244.492H313.977V254.971L306.994 247.157L309.977 244.492ZM274.08 204.322V200.322H275.87L277.062 201.657L274.08 204.322ZM354 38.0606C354 19.2495 338.751 4 319.939 4V-4C343.169 -4 362 14.8312 362 38.0606H354ZM354 166.261V38.0606H362V166.261H354ZM319.939 200.322C338.751 200.322 354 185.072 354 166.261H362C362 189.491 343.169 208.322 319.939 208.322V200.322ZM309.977 200.322H319.939V208.322H309.977V200.322ZM313.977 204.322V244.492H305.977V204.322H313.977ZM306.994 247.157L271.097 206.987L277.062 201.657L312.959 241.827L306.994 247.157ZM38.0606 200.322H274.08V208.322H38.0606V200.322ZM4 166.261C4 185.072 19.2495 200.322 38.0606 200.322V208.322C14.8312 208.322 -4 189.491 -4 166.261H4ZM4 38.0606V166.261H-4V38.0606H4ZM38.0606 4C19.2495 4 4 19.2495 4 38.0606H-4C-4 14.8312 14.8312 -4 38.0606 -4V4ZM319.939 4H38.0606V-4H319.939V4Z",
      "fill": "#ED6400",
      "mask": "url(#path-1-inside-1_504_49632)"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }