var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "314",
      "height": "222",
      "viewBox": "0 0 314 222",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_499_49277",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M33.0029 0C14.7759 0 0 14.7759 0 33.003V152.525C0 170.752 14.7759 185.528 33.0029 185.528H42.8044L74.2852 221.999V185.528H280.997C299.224 185.528 314 170.752 314 152.525V33.0029C314 14.7759 299.224 0 280.997 0H33.0029Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M33.0029 0C14.7759 0 0 14.7759 0 33.003V152.525C0 170.752 14.7759 185.528 33.0029 185.528H42.8044L74.2852 221.999V185.528H280.997C299.224 185.528 314 170.752 314 152.525V33.0029C314 14.7759 299.224 0 280.997 0H33.0029Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M42.8044 185.528L45.0754 183.568L44.1779 182.528H42.8044V185.528ZM74.2852 221.999L72.0142 223.959L77.2852 230.065V221.999H74.2852ZM74.2852 185.528V182.528H71.2852V185.528H74.2852ZM3 33.003C3 16.4328 16.4328 3 33.0029 3V-3C13.1191 -3 -3 13.1191 -3 33.003H3ZM3 152.525V33.003H-3V152.525H3ZM33.0029 182.528C16.4328 182.528 3 169.095 3 152.525H-3C-3 172.409 13.1191 188.528 33.0029 188.528V182.528ZM42.8044 182.528H33.0029V188.528H42.8044V182.528ZM76.5561 220.038L45.0754 183.568L40.5334 187.488L72.0142 223.959L76.5561 220.038ZM71.2852 185.528V221.999H77.2852V185.528H71.2852ZM280.997 182.528H74.2852V188.528H280.997V182.528ZM311 152.525C311 169.095 297.567 182.528 280.997 182.528V188.528C300.881 188.528 317 172.409 317 152.525H311ZM311 33.0029V152.525H317V33.0029H311ZM280.997 3C297.567 3 311 16.4328 311 33.0029H317C317 13.1191 300.881 -3 280.997 -3V3ZM33.0029 3H280.997V-3H33.0029V3Z",
      "fill": "#ED6400",
      "mask": "url(#path-1-inside-1_499_49277)"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }