var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "522",
      "height": "295",
      "viewBox": "0 0 522 295",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "filter": "url(#filter0_d_2004_42625)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M387.796 68.398C385.532 67.7812 383.16 67.4975 380.649 67.5363C369.409 67.7489 358.455 72.9048 349.227 78.9821C340.614 84.6322 333.182 91.9844 326.999 100.252C308.188 125.413 301.32 164.772 325.708 188.847C331.123 194.188 337.708 198.287 344.841 200.908C371.418 210.618 397.756 195.776 409.403 171.291C421.764 145.272 422.198 109.359 407.082 84.3298C402.505 76.7229 396.02 70.5977 387.796 68.398Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M390.203 71.2014C388.059 69.9017 385.74 68.887 383.274 68.2243C381.034 67.6227 378.66 67.3304 376.166 67.3757C365.008 67.5767 354.138 72.7001 344.966 78.7212C341.916 80.7152 339.018 82.934 336.277 85.3208",
      "stroke": "#07B53B",
      "stroke-width": "3.35666",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M408.263 96.1664C406.673 91.8954 404.727 87.8158 402.41 83.9809C401.022 81.6786 399.448 79.5163 397.707 77.5469",
      "stroke": "#07B53B",
      "stroke-width": "3.35666",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M413.687 123.461C413.233 114.043 411.46 104.742 408.266 96.1719",
      "stroke": "#07B53B",
      "stroke-width": "3.35666",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M406.372 166.611C411.829 153.533 414.403 138.342 413.684 123.457",
      "stroke": "#07B53B",
      "stroke-width": "3.35666",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M400.854 177.282C402.28 175.072 403.567 172.747 404.719 170.332C405.3 169.108 405.849 167.874 406.373 166.609",
      "stroke": "#07B53B",
      "stroke-width": "3.35666",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M328.814 193.682C332.475 196.185 336.454 198.215 340.618 199.74C358.655 206.331 376.588 201.537 389.812 190.071",
      "stroke": "#07B53B",
      "stroke-width": "3.35666",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M315.246 179.727C315.834 180.456 316.451 181.171 317.096 181.872",
      "stroke": "#07B53B",
      "stroke-width": "3.35666",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M330.725 90.593C327.923 93.5007 325.307 96.5974 322.883 99.8265C307.255 120.72 299.934 151.497 312.146 174.985",
      "stroke": "#07B53B",
      "stroke-width": "3.35666",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter1_d_2004_42625)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M429.093 42.1531C426.935 42.9671 424.883 44.108 422.906 45.5865C414.078 52.2351 408.327 62.6544 404.482 72.813C400.88 82.2769 399.2 92.4156 399.042 102.563C398.567 133.442 415.785 168.742 449.083 173.855C456.473 174.986 464.079 174.454 471.27 172.43C498.03 164.843 510.448 137.842 505.609 111.632C500.454 83.7877 480.098 54.9406 453.634 43.7225C445.605 40.3032 436.91 39.1643 429.093 42.1531Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M432.638 43.0165C430.182 43.2177 427.751 43.746 425.405 44.64C423.275 45.4518 421.216 46.5875 419.256 48.0612C410.486 54.653 404.784 64.9986 400.951 75.0803C399.672 78.4258 398.643 81.8632 397.836 85.3437",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M461.408 52.4922C457.68 50.0077 453.779 47.8804 449.723 46.1626C447.291 45.1291 444.791 44.3144 442.27 43.75",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M481.461 71.0971C475.671 63.8596 468.898 57.4754 461.414 52.4922",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M500.514 109.676C497.321 96.1162 490.614 82.5359 481.461 71.0977",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M502.267 121.357C502.129 118.775 501.814 116.182 501.339 113.595C501.096 112.286 500.822 110.987 500.509 109.677",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M454.354 175.935C458.713 175.818 463.051 175.141 467.246 173.955C485.408 168.806 496.924 154.652 500.846 137.899",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M435.508 172.645C436.397 172.886 437.3 173.1 438.217 173.287",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M396.459 92.7386C395.903 96.6692 395.605 100.643 395.537 104.611C395.135 130.258 407.046 158.985 430.309 170.649",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "387.168",
      "cy": "61.8477",
      "r": "2.52743",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "497.598",
      "cy": "220.883",
      "r": "2.52743",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "493.316",
      "cy": "208.828",
      "r": "2.13859",
      "stroke": "#07B53B",
      "stroke-width": "0.77767"
    }
  }), _c('circle', {
    attrs: {
      "cx": "328.652",
      "cy": "53.4917",
      "r": "3.11068",
      "stroke": "#07B53B",
      "stroke-width": "0.77767"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter2_d_2004_42625)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M340.744 78.9463C338.322 79.0548 335.901 79.5247 333.442 80.3561C322.453 84.1155 313.308 92.6465 306.151 101.539C299.463 109.817 294.475 119.396 291.004 129.482C280.449 160.171 286.124 201.055 317.718 217.032C324.731 220.575 332.503 222.527 340.347 222.852C369.554 224.009 390.773 201.091 394.497 173.329C398.437 143.832 387.52 108.371 364.747 88.5256C357.842 82.4889 349.528 78.5126 340.744 78.9463Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M344.001 80.9506C341.481 80.3495 338.88 80.0836 336.245 80.2108C333.852 80.3264 331.424 80.7887 328.985 81.621C318.074 85.343 309 93.8158 301.879 102.635C299.51 105.56 297.36 108.658 295.418 111.871",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M369.642 99.8047C366.73 96.1058 363.528 92.7075 360.037 89.6675C357.945 87.8411 355.714 86.2113 353.379 84.8242",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M383.596 124.934C380.175 115.814 375.493 107.226 369.645 99.8047",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M390.036 169.69C391.273 155.103 389.007 139.348 383.598 124.934",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M387.977 181.932C388.682 179.309 389.213 176.615 389.583 173.876C389.768 172.489 389.918 171.102 390.034 169.691",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M322.301 220.814C326.693 222.12 331.247 222.86 335.825 223.045C355.649 223.831 371.773 213.451 381.159 197.996",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M304.547 211.371C305.356 211.903 306.188 212.411 307.044 212.897",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M291.62 118.809C289.782 122.554 288.187 126.426 286.823 130.368C278.049 155.855 280.569 188.44 300 207.686",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "306.66",
      "cy": "213.106",
      "r": "7.58229",
      "fill": "#ED6400"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter3_d_2004_42625)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M87.3849 76.456C85.2957 77.2438 83.31 78.348 81.396 79.7791C72.8517 86.2139 67.2863 96.2984 63.5647 106.13C60.0787 115.29 58.4523 125.103 58.2997 134.925C57.8395 164.81 74.5039 198.976 106.732 203.925C113.884 205.02 121.246 204.505 128.206 202.546C154.106 195.202 166.125 169.069 161.442 143.701C156.452 116.752 136.751 88.8325 111.137 77.9749C103.366 74.6656 94.9503 73.5632 87.3849 76.456Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M90.8165 77.2916C88.4386 77.4863 86.0858 77.9976 83.8151 78.8629C81.7535 79.6486 79.7609 80.7478 77.8639 82.1742C69.3765 88.5541 63.8572 98.5671 60.1471 108.325C58.9094 111.563 57.9135 114.89 57.1329 118.258",
      "stroke": "#07B53B",
      "stroke-width": "3.19365",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M118.664 86.4573C115.055 84.0526 111.28 81.9937 107.355 80.3311C105.001 79.3309 102.581 78.5424 100.141 77.9961",
      "stroke": "#07B53B",
      "stroke-width": "3.19365",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M138.071 104.468C132.466 97.463 125.911 91.284 118.668 86.4609",
      "stroke": "#07B53B",
      "stroke-width": "3.19365",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M156.511 141.803C153.42 128.679 146.93 115.535 138.07 104.465",
      "stroke": "#07B53B",
      "stroke-width": "3.19365",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M158.205 153.11C158.072 150.612 157.767 148.102 157.307 145.598C157.072 144.331 156.807 143.073 156.504 141.806",
      "stroke": "#07B53B",
      "stroke-width": "3.19365",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M111.836 205.936C116.055 205.822 120.254 205.167 124.314 204.019C141.892 199.036 153.039 185.336 156.834 169.122",
      "stroke": "#07B53B",
      "stroke-width": "3.19365",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M93.5938 202.75C94.4542 202.984 95.3282 203.191 96.2159 203.371",
      "stroke": "#07B53B",
      "stroke-width": "3.19365",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M55.8 125.412C55.2626 129.216 54.974 133.062 54.9076 136.903C54.5191 161.725 66.0471 189.529 88.5629 200.818",
      "stroke": "#07B53B",
      "stroke-width": "3.19365",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "105.409",
      "cy": "41.4085",
      "r": "3.40854",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "232.565",
      "cy": "256.858",
      "r": "3.40854",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "37.7312",
      "cy": "52.6101",
      "r": "4.19513",
      "stroke": "#07B53B",
      "stroke-width": "1.04878"
    }
  }), _c('circle', {
    attrs: {
      "cx": "226.795",
      "cy": "240.608",
      "r": "2.88415",
      "stroke": "#07B53B",
      "stroke-width": "1.04878"
    }
  }), _c('circle', {
    attrs: {
      "cx": "4.71952",
      "cy": "31.1101",
      "r": "4.19513",
      "stroke": "#07B53B",
      "stroke-width": "1.04878"
    }
  }), _c('circle', {
    attrs: {
      "cx": "99.6296",
      "cy": "260.266",
      "r": "6.29269",
      "stroke": "#07B53B",
      "stroke-width": "1.04878"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter4_d_2004_42625)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M180.037 129.818C178.205 129.59 176.327 129.634 174.376 129.945C165.646 131.365 157.692 136.603 151.184 142.365C145.108 147.726 140.141 154.281 136.249 161.41C124.409 183.103 123.453 214.52 145.133 230.545C149.946 234.099 155.531 236.556 161.379 237.801C183.158 242.396 202.012 227.898 208.349 207.532C215.07 185.89 211.399 157.876 196.834 140.072C192.421 134.659 186.687 130.613 180.037 129.818Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M182.233 131.754C180.418 130.981 178.5 130.45 176.505 130.209C174.694 129.99 172.813 130.028 170.875 130.341C162.209 131.743 154.316 136.946 147.845 142.659C145.693 144.552 143.684 146.603 141.816 148.768",
      "stroke": "#07B53B",
      "stroke-width": "2.64063",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M199.087 149.181C197.372 146.032 195.402 143.072 193.169 140.345C191.832 138.707 190.365 137.199 188.789 135.859",
      "stroke": "#07B53B",
      "stroke-width": "2.64063",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M206.354 169.829C204.949 162.546 202.53 155.501 199.086 149.184",
      "stroke": "#07B53B",
      "stroke-width": "2.64063",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M205.471 204.25C208.261 193.456 208.57 181.339 206.348 169.828",
      "stroke": "#07B53B",
      "stroke-width": "2.64063",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M202.364 213.174C203.228 211.294 203.971 209.34 204.598 207.331C204.914 206.313 205.204 205.291 205.47 204.247",
      "stroke": "#07B53B",
      "stroke-width": "2.64063",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M148.101 233.985C151.231 235.526 154.556 236.663 157.969 237.385C172.751 240.505 186.18 234.769 195.198 224.365",
      "stroke": "#07B53B",
      "stroke-width": "2.64063",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M135.977 224.629C136.516 225.131 137.076 225.619 137.656 226.093",
      "stroke": "#07B53B",
      "stroke-width": "2.64063",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M138.081 153.489C136.224 156.066 134.532 158.77 133.005 161.555C123.167 179.57 120.901 204.354 133.033 221.281",
      "stroke": "#07B53B",
      "stroke-width": "2.64063",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter5_d_2004_42625)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M266.08 184.747C265.338 184.212 264.512 183.785 263.591 183.456C259.463 181.997 254.764 182.381 250.568 183.336C246.655 184.219 242.939 185.885 239.555 188.055C229.256 194.66 221.373 208.075 226.977 220.188C228.222 222.876 230.063 225.27 232.306 227.201C240.671 234.374 252.305 232.561 259.898 225.223C267.962 217.424 273.028 204.386 270.936 193.193C270.306 189.793 268.778 186.673 266.08 184.747Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M266.57 186.102C265.966 185.335 265.259 184.648 264.45 184.069C263.716 183.544 262.89 183.113 261.974 182.788C257.878 181.337 253.213 181.72 249.045 182.662C247.661 182.973 246.301 183.386 244.975 183.882",
      "stroke": "#07B53B",
      "stroke-width": "2.44953",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M269.746 197.674C269.75 195.899 269.598 194.146 269.277 192.43C269.086 191.401 268.807 190.398 268.441 189.441",
      "stroke": "#07B53B",
      "stroke-width": "2.44953",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M267.994 208.368C269.115 204.872 269.74 201.238 269.746 197.676",
      "stroke": "#07B53B",
      "stroke-width": "2.44953",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M259.428 223.102C263.206 219.078 266.221 213.89 267.992 208.363",
      "stroke": "#07B53B",
      "stroke-width": "2.44953",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M255.957 226.24C256.78 225.629 257.567 224.957 258.317 224.234C258.696 223.867 259.065 223.492 259.429 223.102",
      "stroke": "#07B53B",
      "stroke-width": "2.44953",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M227.445 222.379C228.438 223.793 229.612 225.076 230.922 226.202C236.599 231.07 243.794 231.772 250.183 229.398",
      "stroke": "#07B53B",
      "stroke-width": "2.44953",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M224.406 215.434C224.521 215.78 224.648 216.125 224.788 216.469",
      "stroke": "#07B53B",
      "stroke-width": "2.44953",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M242.235 185.043C240.816 185.72 239.438 186.492 238.113 187.338C229.558 192.822 222.683 203.046 223.926 213.28",
      "stroke": "#07B53B",
      "stroke-width": "2.44953",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-66-inside-1_2004_42625",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M174.308 15C168.615 15 164 19.6149 164 25.3077V60.0273C164 65.7201 168.615 70.335 174.308 70.335H177.004V81.212L186.724 70.335H250.647C256.34 70.335 260.955 65.7201 260.955 60.0273V25.3077C260.955 19.6149 256.34 15 250.647 15H174.308Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M174.308 15C168.615 15 164 19.6149 164 25.3077V60.0273C164 65.7201 168.615 70.335 174.308 70.335H177.004V81.212L186.724 70.335H250.647C256.34 70.335 260.955 65.7201 260.955 60.0273V25.3077C260.955 19.6149 256.34 15 250.647 15H174.308Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M177.004 70.335H179.004V68.335H177.004V70.335ZM177.004 81.212H175.004V86.4516L178.495 82.5446L177.004 81.212ZM186.724 70.335V68.335H185.829L185.232 69.0024L186.724 70.335ZM166 25.3077C166 20.7195 169.719 17 174.308 17V13C167.51 13 162 18.5103 162 25.3077H166ZM166 60.0273V25.3077H162V60.0273H166ZM174.308 68.335C169.719 68.335 166 64.6156 166 60.0273H162C162 66.8247 167.51 72.335 174.308 72.335V68.335ZM177.004 68.335H174.308V72.335H177.004V68.335ZM175.004 70.335V81.212H179.004V70.335H175.004ZM178.495 82.5446L188.215 71.6677L185.232 69.0024L175.513 79.8793L178.495 82.5446ZM250.647 68.335H186.724V72.335H250.647V68.335ZM258.955 60.0273C258.955 64.6155 255.235 68.335 250.647 68.335V72.335C257.444 72.335 262.955 66.8247 262.955 60.0273H258.955ZM258.955 25.3077V60.0273H262.955V25.3077H258.955ZM250.647 17C255.235 17 258.955 20.7195 258.955 25.3077H262.955C262.955 18.5103 257.444 13 250.647 13V17ZM174.308 17H250.647V13H174.308V17Z",
      "fill": "#ED6400",
      "mask": "url(#path-66-inside-1_2004_42625)"
    }
  }), _c('path', {
    attrs: {
      "d": "M205.862 41.8053C205.862 39.7018 206.257 37.832 207.049 36.2154C207.84 34.5988 208.951 33.3327 210.345 32.3978C211.739 31.4824 213.359 31.015 215.167 31.015C217.014 31.015 218.615 31.4824 220.028 32.3978C221.421 33.3133 222.533 34.5988 223.324 36.2349C224.115 37.8709 224.511 39.7213 224.511 41.8053C224.511 41.8832 224.492 41.9612 224.492 42.0391H227.732C227.732 41.9612 227.732 41.8832 227.732 41.8053C227.732 39.8381 227.431 38.0073 226.809 36.3128C226.187 34.6377 225.321 33.1574 224.191 31.9109C223.06 30.6449 221.742 29.671 220.197 28.9893C218.671 28.2881 216.995 27.957 215.149 27.957C213.34 27.957 211.664 28.3076 210.138 28.9893C208.612 29.6905 207.275 30.6644 206.144 31.9109C205.014 33.1769 204.148 34.6377 203.545 36.3128C202.942 37.9878 202.641 39.8187 202.641 41.8053C202.641 41.8832 202.641 41.9612 202.641 42.0391H205.843C205.881 41.9612 205.862 41.8832 205.862 41.8053Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M218.765 42.0391C218.728 44.0257 218.351 45.7982 217.579 47.3758C216.787 49.0119 215.676 50.2779 214.282 51.2128C212.888 52.1283 211.268 52.5957 209.422 52.5957C207.614 52.5957 205.994 52.1283 204.6 51.2128C203.206 50.2974 202.094 49.0119 201.303 47.3758C200.531 45.8176 200.154 44.0257 200.116 42.0391H196.914C196.933 43.9284 197.234 45.6813 197.818 47.2979C198.421 48.9729 199.288 50.4532 200.418 51.6998C201.548 52.9658 202.867 53.9396 204.411 54.6213C205.937 55.3225 207.614 55.6536 209.422 55.6536C211.268 55.6536 212.945 55.303 214.47 54.6213C215.996 53.9202 217.334 52.9463 218.464 51.6998C219.594 50.4337 220.461 48.9729 221.082 47.2979C221.685 45.6813 221.987 43.9284 222.005 42.0391H218.765Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M220.311 39.1937C221.216 39.1937 221.95 38.4351 221.95 37.4992C221.95 36.5633 221.216 35.8047 220.311 35.8047C219.406 35.8047 218.672 36.5633 218.672 37.4992C218.672 38.4351 219.406 39.1937 220.311 39.1937Z",
      "fill": "#07B53B"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-71-inside-2_2004_42625",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M390.558 0C394.668 0 398 3.3319 398 7.44199V32.5091C398 36.6192 394.668 39.9511 390.558 39.9511H388.61V47.8057L381.591 39.9511H335.442C331.332 39.9511 328 36.6192 328 32.5091V7.44199C328 3.33189 331.332 0 335.442 0H390.558Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M390.558 0C394.668 0 398 3.3319 398 7.44199V32.5091C398 36.6192 394.668 39.9511 390.558 39.9511H388.61V47.8057L381.591 39.9511H335.442C331.332 39.9511 328 36.6192 328 32.5091V7.44199C328 3.33189 331.332 0 335.442 0H390.558Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M388.61 39.9511H386.61V37.9511H388.61V39.9511ZM388.61 47.8057H390.61V53.0454L387.119 49.1384L388.61 47.8057ZM381.591 39.9511V37.9511H382.486L383.083 38.6184L381.591 39.9511ZM396 7.44199C396 4.43646 393.564 2 390.558 2V-2C395.773 -2 400 2.22733 400 7.44199H396ZM396 32.5091V7.44199H400V32.5091H396ZM390.558 37.9511C393.564 37.9511 396 35.5146 396 32.5091H400C400 37.7238 395.773 41.9511 390.558 41.9511V37.9511ZM388.61 37.9511H390.558V41.9511H388.61V37.9511ZM390.61 39.9511V47.8057H386.61V39.9511H390.61ZM387.119 49.1384L380.1 41.2837L383.083 38.6184L390.102 46.4731L387.119 49.1384ZM335.442 37.9511H381.591V41.9511H335.442V37.9511ZM330 32.5091C330 35.5146 332.437 37.9511 335.442 37.9511V41.9511C330.228 41.9511 326 37.7237 326 32.5091H330ZM330 7.44199V32.5091H326V7.44199H330ZM335.442 2C332.437 2 330 4.43646 330 7.44199H326C326 2.22732 330.228 -2 335.442 -2V2ZM390.558 2H335.442V-2H390.558V2Z",
      "fill": "#ED6400",
      "mask": "url(#path-71-inside-2_2004_42625)"
    }
  }), _c('path', {
    attrs: {
      "d": "M358.443 19.3537C358.443 17.835 358.728 16.485 359.3 15.3179C359.871 14.1507 360.673 13.2367 361.68 12.5617C362.686 11.9007 363.856 11.5632 365.161 11.5632C366.494 11.5632 367.65 11.9007 368.67 12.5617C369.677 13.2226 370.479 14.1507 371.05 15.3319C371.622 16.5132 371.907 17.8491 371.907 19.3537C371.907 19.41 371.894 19.4662 371.894 19.5225H374.233C374.233 19.4662 374.233 19.41 374.233 19.3537C374.233 17.9335 374.015 16.6116 373.566 15.3882C373.118 14.1788 372.492 13.1101 371.676 12.2101C370.86 11.2961 369.908 10.5929 368.793 10.1008C367.691 9.59453 366.481 9.35547 365.148 9.35547C363.842 9.35547 362.632 9.60859 361.53 10.1008C360.428 10.607 359.463 11.3101 358.647 12.2101C357.831 13.1242 357.205 14.1788 356.77 15.3882C356.335 16.5975 356.117 17.9194 356.117 19.3537C356.117 19.41 356.117 19.4662 356.117 19.5225H358.429C358.456 19.4662 358.443 19.41 358.443 19.3537Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M367.761 19.5234C367.734 20.9578 367.462 22.2374 366.904 23.3765C366.333 24.5577 365.53 25.4718 364.524 26.1468C363.517 26.8077 362.348 27.1452 361.015 27.1452C359.709 27.1452 358.54 26.8077 357.533 26.1468C356.527 25.4858 355.724 24.5577 355.153 23.3765C354.596 22.2515 354.324 20.9578 354.296 19.5234H351.984C351.998 20.8875 352.216 22.1531 352.637 23.3202C353.072 24.5296 353.698 25.5983 354.514 26.4983C355.33 27.4124 356.282 28.1155 357.397 28.6077C358.499 29.1139 359.709 29.353 361.015 29.353C362.348 29.353 363.558 29.0998 364.66 28.6077C365.761 28.1014 366.727 27.3983 367.543 26.4983C368.359 25.5843 368.985 24.5296 369.434 23.3202C369.869 22.1531 370.086 20.8875 370.1 19.5234H367.761Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M368.875 17.4664C369.528 17.4664 370.058 16.9186 370.058 16.2429C370.058 15.5673 369.528 15.0195 368.875 15.0195C368.221 15.0195 367.691 15.5673 367.691 16.2429C367.691 16.9186 368.221 17.4664 368.875 17.4664Z",
      "fill": "#07B53B"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter6_d_2004_42625)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M94.8972 107.02C91.9454 107.177 88.9973 107.775 86.0088 108.813C72.6488 113.508 61.5849 124.002 52.9488 134.917C44.8793 145.078 38.8945 156.808 34.7658 169.139C22.208 206.664 29.5424 256.453 68.2242 275.612C76.8102 279.86 86.3057 282.161 95.8728 282.478C131.495 283.592 157.134 255.435 161.391 221.549C165.896 185.546 152.226 142.421 124.259 118.456C115.78 111.166 105.602 106.402 94.8972 107.02Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M98.8622 109.431C95.7838 108.724 92.6101 108.426 89.3982 108.608C86.4821 108.773 83.5272 109.362 80.562 110.401C67.2959 115.05 56.3189 125.472 47.727 136.297C44.8676 139.887 42.2777 143.686 39.9427 147.623",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M130.325 132.158C126.736 127.678 122.797 123.567 118.511 119.896C115.941 117.691 113.205 115.726 110.344 114.059",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M147.59 162.657C143.325 151.572 137.531 141.149 130.324 132.16",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M155.894 217.159C157.254 199.361 154.332 180.175 147.59 162.656",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M153.507 232.104C154.34 228.898 154.961 225.609 155.384 222.265C155.596 220.572 155.765 218.879 155.891 217.159",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M73.8292 280.175C79.1979 281.723 84.758 282.579 90.3408 282.758C114.518 283.515 134.073 270.696 145.36 251.758",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M52.0859 268.848C53.0778 269.488 54.0977 270.099 55.1455 270.683",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M35.3848 156.121C33.1821 160.706 31.2765 165.443 29.6535 170.263C19.2155 201.427 22.6185 241.13 46.5043 264.398",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter7_d_2004_42625)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M410.136 108.153C407.978 108.967 405.926 110.108 403.949 111.587C395.121 118.235 389.37 128.654 385.525 138.813C381.923 148.277 380.243 158.416 380.085 168.563C379.61 199.442 396.828 234.742 430.126 239.855C437.516 240.986 445.122 240.454 452.313 238.43C479.073 230.843 491.491 203.842 486.652 177.632C481.497 149.788 461.141 120.941 434.677 109.722C426.648 106.303 417.953 105.164 410.136 108.153Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M413.681 109.02C411.225 109.222 408.794 109.75 406.448 110.644C404.318 111.456 402.259 112.591 400.299 114.065C391.529 120.657 385.827 131.002 381.994 141.084C380.715 144.43 379.686 147.867 378.879 151.348",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M442.451 118.496C438.723 116.012 434.822 113.884 430.766 112.166C428.334 111.133 425.834 110.318 423.312 109.754",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M462.504 137.101C456.714 129.863 449.941 123.479 442.457 118.496",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M481.557 175.68C478.364 162.12 471.657 148.54 462.504 137.102",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M483.31 187.361C483.172 184.779 482.857 182.186 482.381 179.599C482.139 178.29 481.865 176.991 481.552 175.681",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M435.397 241.939C439.756 241.822 444.094 241.145 448.289 239.959C466.451 234.81 477.967 220.655 481.889 203.903",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M416.551 238.648C417.44 238.89 418.343 239.104 419.26 239.29",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M377.502 158.742C376.946 162.673 376.648 166.647 376.579 170.615C376.178 196.262 388.089 224.989 411.352 236.653",
      "stroke": "#07B53B",
      "stroke-width": "3.2997",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_2004_42625",
      "x": "305.536",
      "y": "67.5312",
      "width": "117.498",
      "height": "145.334",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4.47555"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2.23777"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42625"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42625",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_d_2004_42625",
      "x": "394.632",
      "y": "40.4297",
      "width": "116.405",
      "height": "142.807",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4.3996"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2.1998"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42625"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42625",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter2_d_2004_42625",
      "x": "281.525",
      "y": "78.9141",
      "width": "118.388",
      "height": "153.228",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4.62362"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2.31181"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42625"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42625",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter3_d_2004_42625",
      "x": "54.0309",
      "y": "74.7891",
      "width": "112.661",
      "height": "138.216",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4.2582"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2.1291"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42625"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42625",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter4_d_2004_42625",
      "x": "124.421",
      "y": "129.676",
      "width": "90.694",
      "height": "116.022",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "3.52084"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "1.76042"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42625"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42625",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter5_d_2004_42625",
      "x": "221.851",
      "y": "182.488",
      "width": "52.825",
      "height": "55.7899",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "3.26604"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "1.63302"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42625"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42625",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter6_d_2004_42625",
      "x": "22.9012",
      "y": "106.965",
      "width": "145.569",
      "height": "188.018",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "6.2355"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.11775"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42625"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42625",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter7_d_2004_42625",
      "x": "375.675",
      "y": "106.43",
      "width": "116.405",
      "height": "142.807",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4.3996"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2.1998"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42625"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42625",
      "result": "shape"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }