var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "365",
      "height": "250",
      "viewBox": "0 0 365 250",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_504_49614",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M38.8048 0C17.3735 0 0 17.3735 0 38.8048V169.512C0 190.944 17.3735 208.317 38.8048 208.317H48.9603V249.271L85.557 208.317H326.195C347.626 208.317 365 190.944 365 169.512V38.8048C365 17.3735 347.626 0 326.195 0H38.8048Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M38.8048 0C17.3735 0 0 17.3735 0 38.8048V169.512C0 190.944 17.3735 208.317 38.8048 208.317H48.9603V249.271L85.557 208.317H326.195C347.626 208.317 365 190.944 365 169.512V38.8048C365 17.3735 347.626 0 326.195 0H38.8048Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M48.9603 208.317H52.9603V204.317H48.9603V208.317ZM48.9603 249.271H44.9603V259.75L51.9429 251.936L48.9603 249.271ZM85.557 208.317V204.317H83.7671L82.5744 205.652L85.557 208.317ZM4 38.8048C4 19.5827 19.5826 4 38.8048 4V-4C15.1644 -4 -4 15.1644 -4 38.8048H4ZM4 169.512V38.8048H-4V169.512H4ZM38.8048 204.317C19.5826 204.317 4 188.734 4 169.512H-4C-4 193.153 15.1644 212.317 38.8048 212.317V204.317ZM48.9603 204.317H38.8048V212.317H48.9603V204.317ZM44.9603 208.317V249.271H52.9603V208.317H44.9603ZM51.9429 251.936L88.5397 210.982L82.5744 205.652L45.9777 246.605L51.9429 251.936ZM326.195 204.317H85.557V212.317H326.195V204.317ZM361 169.512C361 188.734 345.417 204.317 326.195 204.317V212.317C349.836 212.317 369 193.153 369 169.512H361ZM361 38.8048V169.512H369V38.8048H361ZM326.195 4C345.417 4 361 19.5826 361 38.8048H369C369 15.1644 349.836 -4 326.195 -4V4ZM38.8048 4H326.195V-4H38.8048V4Z",
      "fill": "#ED6400",
      "mask": "url(#path-1-inside-1_504_49614)"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }