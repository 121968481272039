var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "459",
      "height": "452",
      "viewBox": "0 0 459 452",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_2004_42624",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M270.01 0C277.079 0 282.809 5.72991 282.809 12.7981V55.9064C282.809 62.9746 277.079 68.7045 270.01 68.7045H266.664V82.2137L254.592 68.7045H175.227C168.159 68.7045 162.429 62.9746 162.429 55.9064V12.7981C162.429 5.72991 168.159 0 175.227 0H270.01Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M270.01 0C277.079 0 282.809 5.72991 282.809 12.7981V55.9064C282.809 62.9746 277.079 68.7045 270.01 68.7045H266.664V82.2137L254.592 68.7045H175.227C168.159 68.7045 162.429 62.9746 162.429 55.9064V12.7981C162.429 5.72991 168.159 0 175.227 0H270.01Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M266.664 68.7045H263.829V65.8697H266.664V68.7045ZM266.664 82.2137H269.499V89.6403L264.55 84.1026L266.664 82.2137ZM254.592 68.7045V65.8697H255.861L256.706 66.8156L254.592 68.7045ZM279.974 12.7981C279.974 7.29553 275.513 2.8348 270.01 2.8348V-2.8348C278.644 -2.8348 285.643 4.16429 285.643 12.7981H279.974ZM279.974 55.9064V12.7981H285.643V55.9064H279.974ZM270.01 65.8697C275.513 65.8697 279.974 61.409 279.974 55.9064H285.643C285.643 64.5402 278.644 71.5393 270.01 71.5393V65.8697ZM266.664 65.8697H270.01V71.5393H266.664V65.8697ZM269.499 68.7045V82.2137H263.829V68.7045H269.499ZM264.55 84.1026L252.478 70.5934L256.706 66.8156L268.778 80.3248L264.55 84.1026ZM175.227 65.8697H254.592V71.5393H175.227V65.8697ZM165.264 55.9064C165.264 61.409 169.724 65.8697 175.227 65.8697V71.5393C166.593 71.5393 159.594 64.5402 159.594 55.9064H165.264ZM165.264 12.7981V55.9064H159.594V12.7981H165.264ZM175.227 2.8348C169.724 2.8348 165.264 7.29553 165.264 12.7981H159.594C159.594 4.16429 166.593 -2.8348 175.227 -2.8348V2.8348ZM270.01 2.8348H175.227V-2.8348H270.01V2.8348Z",
      "fill": "#ED6400",
      "mask": "url(#path-1-inside-1_2004_42624)"
    }
  }), _c('path', {
    attrs: {
      "d": "M214.765 30.8836C214.765 28.2899 215.256 25.9844 216.239 23.9911C217.221 21.9979 218.601 20.4369 220.332 19.2841C222.062 18.1554 224.074 17.579 226.319 17.579C228.611 17.579 230.599 18.1554 232.353 19.2841C234.084 20.4128 235.464 21.9979 236.446 24.0152C237.429 26.0325 237.92 28.3139 237.92 30.8836C237.92 30.9796 237.896 31.0757 237.896 31.1718H241.919C241.919 31.0757 241.919 30.9796 241.919 30.8836C241.919 28.458 241.545 26.2006 240.773 24.1112C240.001 22.0459 238.926 20.2207 237.522 18.6837C236.119 17.1227 234.482 15.9219 232.564 15.0814C230.669 14.2169 228.588 13.8086 226.296 13.8086C224.05 13.8086 221.969 14.2409 220.074 15.0814C218.18 15.946 216.519 17.1467 215.116 18.6837C213.713 20.2447 212.637 22.0459 211.888 24.1112C211.14 26.1765 210.766 28.434 210.766 30.8836C210.766 30.9796 210.766 31.0757 210.766 31.1718H214.742C214.788 31.0757 214.765 30.9796 214.765 30.8836Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M230.787 31.1719C230.74 33.6214 230.273 35.8068 229.314 37.7521C228.331 39.7694 226.951 41.3304 225.221 42.4831C223.49 43.6119 221.478 44.1882 219.186 44.1882C216.941 44.1882 214.93 43.6119 213.199 42.4831C211.468 41.3544 210.088 39.7694 209.106 37.7521C208.147 35.8309 207.679 33.6214 207.632 31.1719H203.656C203.68 33.5014 204.054 35.6628 204.779 37.656C205.527 39.7214 206.603 41.5465 208.007 43.0835C209.41 44.6445 211.047 45.8453 212.965 46.6858C214.859 47.5504 216.941 47.9586 219.186 47.9586C221.478 47.9586 223.56 47.5264 225.454 46.6858C227.349 45.8213 229.01 44.6205 230.413 43.0835C231.816 41.5225 232.892 39.7214 233.664 37.656C234.412 35.6628 234.787 33.5014 234.81 31.1719H230.787Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M232.707 27.6631C233.83 27.6631 234.742 26.7276 234.742 25.5737C234.742 24.4198 233.83 23.4844 232.707 23.4844C231.583 23.4844 230.672 24.4198 230.672 25.5737C230.672 26.7276 231.583 27.6631 232.707 27.6631Z",
      "fill": "#07B53B"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter0_d_2004_42624)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M188.619 163.232C184.834 162.212 180.869 161.747 176.668 161.818C157.868 162.203 139.534 170.811 124.083 180.948C109.662 190.372 97.2107 202.625 86.8468 216.398C55.3164 258.312 43.7197 323.817 84.4445 363.807C93.4861 372.677 104.488 379.479 116.412 383.821C160.834 399.904 204.927 375.137 224.475 334.368C245.22 291.044 246.044 231.29 220.832 189.687C213.199 177.043 202.368 166.87 188.619 163.232Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M192.506 168.039C188.915 165.877 185.033 164.19 180.903 163.088C177.153 162.088 173.178 161.602 169.002 161.677C150.318 162.011 132.117 170.531 116.758 180.543C111.652 183.859 106.799 187.548 102.21 191.517",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M222.745 209.551C220.083 202.449 216.826 195.666 212.945 189.289C210.621 185.46 207.986 181.865 205.07 178.59",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M231.832 254.936C231.071 239.275 228.103 223.81 222.754 209.559",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M219.577 326.689C228.715 304.942 233.026 279.682 231.82 254.93",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M210.34 344.435C212.729 340.761 214.884 336.895 216.812 332.879C217.785 330.844 218.704 328.793 219.581 326.688",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M89.7163 371.699C95.8476 375.862 102.509 379.237 109.482 381.773C139.685 392.733 169.713 384.761 191.856 365.695",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M66.9922 348.496C67.9774 349.709 69.0097 350.898 70.0891 352.063",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M92.9196 200.281C88.2275 205.116 83.8469 210.265 79.7887 215.635C53.6192 250.378 41.3607 301.555 61.8096 340.612",
      "stroke": "#07B53B",
      "stroke-width": "5.6206",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter1_d_2004_42624)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M249.164 105.709C245.556 107.058 242.127 108.95 238.824 111.405C224.077 122.442 214.489 139.762 208.086 156.654C202.088 172.39 199.306 189.254 199.07 206.137C198.361 257.511 227.256 316.292 282.963 324.89C295.326 326.793 308.046 325.929 320.068 322.58C364.803 310.031 385.497 265.141 377.332 221.519C368.633 175.178 334.507 127.126 290.214 108.388C276.775 102.677 262.229 100.758 249.164 105.709Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M255.245 107.276C251.132 107.611 247.061 108.489 243.133 109.976C239.566 111.326 236.119 113.214 232.837 115.665C218.153 126.626 208.604 143.829 202.186 160.594C200.044 166.157 198.321 171.873 196.971 177.66",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M303.417 123.025C297.175 118.894 290.643 115.356 283.852 112.5C279.779 110.782 275.594 109.427 271.371 108.488",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M336.994 153.961C327.298 141.926 315.957 131.31 303.426 123.023",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M368.895 218.115C363.548 195.567 352.319 172.985 336.992 153.965",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M371.828 237.539C371.597 233.246 371.07 228.934 370.274 224.632C369.868 222.455 369.409 220.295 368.886 218.118",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M291.606 328.298C298.905 328.104 306.168 326.978 313.193 325.006C343.605 316.445 362.888 292.907 369.454 265.051",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M260.043 322.824C261.532 323.226 263.044 323.582 264.58 323.892",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M194.659 189.944C193.729 196.48 193.23 203.088 193.115 209.686C192.443 252.332 212.387 300.102 251.341 319.497",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter2_d_2004_42624)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M215.863 205.651C212.255 206.999 208.827 208.892 205.523 211.346C190.776 222.384 181.188 239.704 174.785 256.595C168.787 272.331 166.005 289.195 165.769 306.079C165.06 357.453 193.955 416.233 249.662 424.831C262.025 426.734 274.746 425.87 286.767 422.522C331.503 409.972 352.197 365.082 344.032 321.46C335.332 275.119 301.206 227.067 256.913 208.33C243.474 202.619 228.928 200.7 215.863 205.651Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M221.937 207.218C217.823 207.552 213.753 208.431 209.824 209.917C206.257 211.267 202.81 213.156 199.528 215.606C184.844 226.567 175.296 243.771 168.877 260.535C166.736 266.098 165.013 271.814 163.662 277.602",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M270.108 222.967C263.866 218.835 257.335 215.298 250.543 212.441C246.471 210.723 242.285 209.368 238.062 208.43",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M303.685 253.902C293.989 241.867 282.648 231.251 270.117 222.965",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M335.587 318.056C330.24 295.508 319.011 272.926 303.684 253.906",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M338.52 337.481C338.289 333.188 337.761 328.876 336.965 324.574C336.559 322.397 336.1 320.237 335.577 318.059",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M258.297 428.236C265.596 428.041 272.86 426.915 279.884 424.944C310.296 416.382 329.58 392.845 336.146 364.988",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M226.734 422.766C228.223 423.168 229.735 423.524 231.271 423.833",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M161.35 289.885C160.421 296.421 159.921 303.029 159.806 309.628C159.134 352.274 179.078 400.043 218.032 419.438",
      "stroke": "#07B53B",
      "stroke-width": "5.52522",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "179.111",
      "cy": "141.724",
      "r": "4.23208",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "364.021",
      "cy": "408.021",
      "r": "4.23208",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "122.114",
      "cy": "154.418",
      "r": "5.20871",
      "stroke": "#07B53B",
      "stroke-width": "1.30218"
    }
  }), _c('circle', {
    attrs: {
      "cx": "356.853",
      "cy": "387.838",
      "r": "3.58099",
      "stroke": "#07B53B",
      "stroke-width": "1.30218"
    }
  }), _c('circle', {
    attrs: {
      "cx": "81.1254",
      "cy": "127.731",
      "r": "5.20871",
      "stroke": "#07B53B",
      "stroke-width": "1.30218"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter3_d_2004_42624)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M357.726 179.688C353.589 179.872 349.452 180.669 345.253 182.079C326.482 188.456 310.861 202.927 298.635 218.011C287.212 232.053 278.691 248.302 272.764 265.41C254.734 317.469 264.428 386.819 318.394 413.922C330.372 419.931 343.648 423.242 357.047 423.794C406.937 425.756 443.182 386.88 449.542 339.788C456.272 289.753 437.625 229.6 398.725 195.937C386.932 185.697 372.73 178.952 357.726 179.688Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M363.326 183.085C359.021 182.065 354.579 181.614 350.077 181.83C345.99 182.026 341.844 182.81 337.678 184.222C319.039 190.536 303.539 204.908 291.377 219.869C287.329 224.829 283.657 230.084 280.34 235.535",
      "stroke": "#07B53B",
      "stroke-width": "5.92332",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M407.14 215.064C402.164 208.789 396.695 203.025 390.732 197.868C387.158 194.77 383.348 192.005 379.359 189.652",
      "stroke": "#07B53B",
      "stroke-width": "5.92332",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M430.968 257.697C425.124 242.227 417.127 227.658 407.137 215.07",
      "stroke": "#07B53B",
      "stroke-width": "5.92332",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M441.962 333.615C444.075 308.871 440.205 282.146 430.965 257.695",
      "stroke": "#07B53B",
      "stroke-width": "5.92332",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M438.445 354.385C439.65 349.935 440.558 345.366 441.19 340.719C441.506 338.366 441.762 336.013 441.96 333.621",
      "stroke": "#07B53B",
      "stroke-width": "5.92332",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M326.27 420.334C333.772 422.55 341.552 423.805 349.37 424.118C383.232 425.452 410.775 407.844 426.808 381.629",
      "stroke": "#07B53B",
      "stroke-width": "5.92332",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M295.938 404.312C297.32 405.214 298.741 406.077 300.202 406.901",
      "stroke": "#07B53B",
      "stroke-width": "5.92332",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M273.858 247.301C270.718 253.654 267.993 260.222 265.664 266.908C250.678 310.143 254.982 365.416 288.172 398.063",
      "stroke": "#07B53B",
      "stroke-width": "5.92332",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter4_d_2004_42624)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M101.368 166.991C97.3125 167.172 93.2571 167.953 89.1412 169.335C70.7404 175.587 55.4267 189.773 43.442 204.559C32.2442 218.324 23.8912 234.253 18.0805 251.024C0.406104 302.056 9.90909 370.04 62.8112 396.608C74.5537 402.498 87.5674 405.744 100.702 406.285C149.609 408.209 185.14 370.1 191.374 323.936C197.972 274.887 179.692 215.92 141.559 182.92C129.998 172.882 116.076 166.27 101.368 166.991Z",
      "fill": "white"
    }
  })]), _c('path', {
    attrs: {
      "d": "M106.815 170.333C102.595 169.333 98.2402 168.891 93.8272 169.102C89.8207 169.295 85.7561 170.064 81.6722 171.447C63.4009 177.637 48.2071 191.726 36.2844 206.391C32.3166 211.254 28.7165 216.405 25.4648 221.749",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M149.752 201.684C144.875 195.533 139.513 189.882 133.668 184.827C130.165 181.79 126.429 179.08 122.52 176.773",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M173.12 243.47C167.39 228.305 159.552 214.023 149.758 201.684",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M183.902 317.892C185.973 293.635 182.179 267.437 173.121 243.469",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M180.457 338.253C181.638 333.89 182.528 329.412 183.147 324.856C183.457 322.55 183.709 320.243 183.902 317.898",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M70.4844 402.903C77.8393 405.075 85.4653 406.305 93.1299 406.613C126.324 407.92 153.324 390.659 169.041 364.961",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M40.7539 387.203C42.1088 388.087 43.5023 388.933 44.9346 389.74",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M19.1078 233.285C16.0304 239.513 13.3593 245.952 11.0754 252.506C-3.61514 294.888 0.604287 349.072 33.1403 381.075",
      "stroke": "#07B53B",
      "stroke-width": "5.80655",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "44.3095",
      "cy": "394.997",
      "r": "12.6962",
      "fill": "#ED6400"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_2004_42624",
      "x": "50.8067",
      "y": "161.812",
      "width": "196.59",
      "height": "241.953",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.49413"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.74706"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42624"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42624",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_d_2004_42624",
      "x": "191.692",
      "y": "102.867",
      "width": "194.753",
      "height": "237.75",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.36696"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.68348"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42624"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42624",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter2_d_2004_42624",
      "x": "158.391",
      "y": "202.809",
      "width": "194.753",
      "height": "237.75",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.36696"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.68348"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42624"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42624",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter3_d_2004_42624",
      "x": "256.571",
      "y": "179.633",
      "width": "202.221",
      "height": "260.026",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.89776"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.94888"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42624"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42624",
      "result": "shape"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter4_d_2004_42624",
      "x": "2.20716",
      "y": "166.938",
      "width": "198.234",
      "height": "254.902",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "7.74206"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "3.87103"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_2004_42624"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_2004_42624",
      "result": "shape"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }