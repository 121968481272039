<template>
  <div class="py-3">
    <!-- Application Form -->
    <b-form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="textarea">
          {{ $t("help.form.new-client.nameAndSurname") }}*
        </label>
        <input
          id="name"
          class="input"
          type="text"
          placeholder="Eg.John Doe"
          v-model="name"
          :class="$v.name.$error ? 'is-invalid' : ''"
        />
        <span v-if="!$v.name.required & $v.name.$dirty" class="red-info">
          Please complete this required field.
        </span>
      </div>

      <div class="form-group">
        <label for="textarea">{{ $t("help.form.new-client.email") }}*</label>
        <b-form-input
          v-model="email"
          id="email"
          type="email"
          class="input"
          placeholder="Eg.john.doe@gmail.com"
          :class="$v.email.$error ? 'is-invalid' : ''"
        />
        <span v-if="!$v.email.required & $v.email.$dirty" class="red-info">
          Please complete this required field.
        </span>
        <span v-if="$v.email.required & !$v.email.email" class="red-info">
          Your email is not valid
        </span>
      </div>

      <div class="form-group">
        <label>{{ $t("help.form.new-client.mobile") }}*</label>
        <MazPhoneNumberInput
          type="tel"
          v-model="phoneNumber"
          @update="results = $event"
          class="mb-32px"
          :class="$v.phoneNumber.$error ? 'is-invalid-1' : ''"
        />
        <span
          v-if="!$v.phoneNumber.required & $v.phoneNumber.$dirty"
          class="red-info"
        >
          Please complete this required field.
        </span>
      </div>
      <div class="form-group">
        <label for="textarea">Cover letter*</label>
        <textarea
          id="textarea"
          class="input"
          v-model="cover_letter"
          rows="8"
          style="height: 156px !important"
          max-rows="6"
          :class="$v.cover_letter.$error ? 'is-invalid' : ''"
        ></textarea>
        <span
          v-if="!$v.cover_letter.required & $v.cover_letter.$dirty"
          class="red-info"
        >
          Please complete this required field.
        </span>
      </div>

      <div class="form-group d-flex flex-column">
        <label class="cvUpload"> Upload your CV / Resume* </label>
        <div class="d-flex">
          <b-form-file
            v-model="file"
            placeholder="Choose file"
            drop-placeholder="Drop file here..."
            accept=".pdf,.doc,.docx"
            @change="handleFileUpload($event)"
            :class="$v.file.$error ? 'is-invalid-2' : ''"
          >
          </b-form-file>
          <div v-if="!file" style="margin: 8px auto auto 8px !important">
            <NoFile style="margin-right: 4px" />
            <span class="noFile">
              {{ $t("careers.form.no-file") }}
            </span>
          </div>
        </div>
        <span v-if="!$v.file.required & $v.file.$dirty" class="red-info-file">
          Please complete this required field.
        </span>
      </div>

      <div
        class="apply-buttons d-flex flex-column flex-md-row mt-5 justify-content-center"
        style="gap: 14px"
      >
        <b-button
          variant="outline-primary"
          class="round-btn-16-24-outline order-1 order-md-0 text-nowrap"
          @click="
            $emit('hideTitle');
            handleChangeStep();
          "
        >
          <p class="button-medium">
            {{ $t("careers.current-jobs.buttons.back") }}
          </p>
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          class="round-btn-16-24 order-0 order-md-1"
        >
          <p class="text-white-button button-medium text-nowrap">
            {{ $t("careers.form.submit") }}
          </p>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import NoFile from "../svg/icons/NoFile.vue";
import { countriess } from "../../../../FLAGS_TEST/countries";
import MazPhoneNumberInput from "maz-ui/lib/maz-phone-number-input";
import "maz-ui/lib/css/base.css";
import "maz-ui/lib/css/maz-phone-number-input.css";
import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");
import RedInfo from "../svg/job-application-form/RedInfo.vue";

export default {
  components: {
    NoFile,
    MazPhoneNumberInput,
    RedInfo,
  },
  props: ["currentStep", "title", "index"],
  data() {
    return {
      name: "",
      email: "",
      mobilePrefix: "",
      mobile: "",
      cover_letter: "",
      file: null,
      options: "",
      noFileWarning: false,
      phoneNumber: null,
      results: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    name: { required },
    email: { required, email },
    mobile: { required },
    cover_letter: { required },
    type: { required },
    phoneNumber: { required },
    file: { required },
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async handleSubmit() {
      if (!this.file) {
        this.noFileWarning = true;
        // return;
      }
      this.$v.name.$touch();
      this.$v.email.$touch();
      // this.$v.mobile.$touch();
      this.$v.cover_letter.$touch();
      this.$v.phoneNumber.$touch();
      this.$v.file.$touch();

      if (this.$v.email.$pending || this.$v.email.$error) return;
      if (this.$v.name.$pending || this.$v.name.$error) return;
      if (this.$v.cover_letter.$pending || this.$v.cover_letter.$error) return;
      if (this.$v.phoneNumber.$pending || this.$v.phoneNumber.$error) return;
      if (this.$v.file.$pending || this.$v.file.$error) return;

      try {
        let form = new FormData();
        form.append("file", this.file);
        form.append("full_name", this.name);
        form.append("email", this.email);
        form.append("mobile_phone", this.phoneNumber);
        form.append("cover_letter", this.cover_letter);
        form.append("title", this.title);
        const res = await this.$axios.post(`job_applications`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$bvModal.hide(`modal-${this.index}`);
        this.$bvModal.show("job-application-form");
      }
    },

    handleChangeStep() {
      this.$emit("changeStep");
    },
  },
  created() {
    this.$emit("modifyTitle");
    this.options = countriess.map((c) => ({
      ...c,
      imageFile: `${c.name.toLowerCase()}.png`,
    }));
  },

  filters: {
    url(value) {
      return require("@/FLAGS_TEST/" + value);
    },
  },
};
</script>

<style lang="scss" scoped>
.is-invalid {
  border: 2px solid var(--danger);
}
.is-invalid-1 ::v-deep .input-phone-number {
  border: 2px solid var(--danger);
}

.is-invalid-2 ::v-deep .custom-file-label {
  border: 2px solid var(--danger);
}
.red-info {
  display: flex;
  align-items: center;
  color: red;
  margin-top: -30px;
  margin-bottom: 20px;
}
.red-info-file {
  display: flex;
  align-items: center;
  color: red;
  margin-top: 15px;
}

::v-deep label {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12.1211px;
  line-height: 100%;
  text-transform: uppercase;
  color: #888f94;
  margin-bottom: 12.12px !important;
}
::v-deep input:not(.vs__search),
textarea {
  outline: none !important;
  background: #fcfcfd;

  box-sizing: border-box;
  border-radius: 12px;
  color: #777e90;
  width: 100%;

  &:focus {
    border-color: #b1b5c3 !important;
  }
}

::v-deep .form-group {
  margin-bottom: 33px;
}
::v-deep .custom-file {
  max-width: 166px !important;
  /* background-color: red; */
}
::v-deep .custom-file-label::after {
  display: none;
}
::v-deep .custom-file-label {
  background: #fcfcfd;
  /* Neutrals/6 */
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 12px;
  max-width: 166px;
  height: 48px;
  vertical-align: middle;
  font-size: 16.1615px;
  line-height: 120%;
}
::v-deep .custom-file-label {
  display: flex;
  align-items: center;
}
::v-deep .form-file-text {
  text-transform: none !important;
}
.b {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16.1615px;
  line-height: 100%;
  /* or 16px */

  text-align: center;
  letter-spacing: 0.02em;
}
.a {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16.1615px;
  line-height: 100%;
  /* or 16px */

  text-align: center;
  letter-spacing: 0.02em;
}

.apply-buttons {
  @media (max-width: 767px) {
    max-width: 272px;
    margin: 40px auto 40px auto !important;
    gap: 16px !important;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px !important;
    height: 50px !important;
  }
}

.cvUpload {
  @media (min-width: 768px) {
    width: 100%;
  }
}

.noFile {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  letter-spacing: -0.01em;

  color: #777e90;
  @media (max-width: 767px) {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */

    letter-spacing: -0.01em;

    color: #777e90;
  }
}

.round-btn-16-24-outline #leftArr {
  color: var(--primary);
}
.round-btn-16-24-outline:hover #leftArr {
  color: #fcfcfd;
}
.round-btn-16-24-outline {
  height: 50px !important;
  @media (min-width: 768px) {
    width: 197px !important;
  }
}
.round-btn-16-24 {
  height: 50px !important;
  @media (min-width: 768px) {
    max-width: 272px !important;
  }
}
.mb-32px {
  margin-bottom: 32px;
}
::v-deep .maz-select__options-list__items {
  overflow-y: scroll;
  border-bottom: 16px solid #ffffff;
  border-right: 9px solid #ffffff;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #07b53b;
    height: 15px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #9ce1b1;
    width: 5px !important;
  }
}
</style>
