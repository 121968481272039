var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "737",
      "height": "713",
      "viewBox": "0 0 737 713",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_504_49610",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M510.352 90C520.099 90 528 97.9015 528 107.648V167.094C528 176.841 520.099 184.742 510.352 184.742H505.734V203.365L489.092 184.742H379.647C369.9 184.742 361.999 176.841 361.999 167.094V107.648C361.999 97.9014 369.9 90 379.647 90H510.352Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M510.352 90C520.099 90 528 97.9015 528 107.648V167.094C528 176.841 520.099 184.742 510.352 184.742H505.734V203.365L489.092 184.742H379.647C369.9 184.742 361.999 176.841 361.999 167.094V107.648C361.999 97.9014 369.9 90 379.647 90H510.352Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M505.734 184.742H502.312V181.32H505.734V184.742ZM505.734 203.365H509.157V212.332L503.182 205.646L505.734 203.365ZM489.092 184.742V181.32H490.624L491.645 182.462L489.092 184.742ZM524.577 107.648C524.577 99.7918 518.208 93.4227 510.352 93.4227V86.5773C521.989 86.5773 531.423 96.0112 531.423 107.648H524.577ZM524.577 167.094V107.648H531.423V167.094H524.577ZM510.352 181.32C518.208 181.32 524.577 174.951 524.577 167.094H531.423C531.423 178.731 521.989 188.165 510.352 188.165V181.32ZM505.734 181.32H510.352V188.165H505.734V181.32ZM509.157 184.742V203.365H502.312V184.742H509.157ZM503.182 205.646L486.54 187.023L491.645 182.462L508.287 201.085L503.182 205.646ZM379.647 181.32H489.092V188.165H379.647V181.32ZM365.421 167.094C365.421 174.951 371.79 181.32 379.647 181.32V188.165C368.01 188.165 358.576 178.731 358.576 167.094H365.421ZM365.421 107.648V167.094H358.576V107.648H365.421ZM379.647 93.4227C371.79 93.4227 365.421 99.7917 365.421 107.648H358.576C358.576 96.0111 368.01 86.5773 379.647 86.5773V93.4227ZM510.352 93.4227H379.647V86.5773H510.352V93.4227Z",
      "fill": "#ED6400",
      "mask": "url(#path-1-inside-1_504_49610)"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-3-inside-2_504_49610",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M471.986 533C478.621 533 484 538.379 484 545.014V585.48C484 592.115 478.621 597.494 471.986 597.494H468.843V610.173L457.513 597.494H383.012C376.377 597.494 370.999 592.115 370.999 585.48V545.014C370.999 538.379 376.377 533 383.012 533H471.986Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M471.986 533C478.621 533 484 538.379 484 545.014V585.48C484 592.115 478.621 597.494 471.986 597.494H468.843V610.173L457.513 597.494H383.012C376.377 597.494 370.999 592.115 370.999 585.48V545.014C370.999 538.379 376.377 533 383.012 533H471.986Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M468.843 597.494H465.908V594.558H468.843V597.494ZM468.843 610.173H471.778V617.862L466.655 612.129L468.843 610.173ZM457.513 597.494V594.558H458.826L459.701 595.538L457.513 597.494ZM481.065 545.014C481.065 540 477 535.935 471.986 535.935V530.065C480.242 530.065 486.935 536.758 486.935 545.014H481.065ZM481.065 585.48V545.014H486.935V585.48H481.065ZM471.986 594.558C477 594.558 481.065 590.494 481.065 585.48H486.935C486.935 593.736 480.242 600.429 471.986 600.429V594.558ZM468.843 594.558H471.986V600.429H468.843V594.558ZM471.778 597.494V610.173H465.908V597.494H471.778ZM466.655 612.129L455.324 599.449L459.701 595.538L471.032 608.217L466.655 612.129ZM383.012 594.558H457.513V600.429H383.012V594.558ZM373.934 585.48C373.934 590.494 377.998 594.558 383.012 594.558V600.429C374.756 600.429 368.063 593.736 368.063 585.48H373.934ZM373.934 545.014V585.48H368.063V545.014H373.934ZM383.012 535.935C377.998 535.935 373.934 540 373.934 545.014H368.063C368.063 536.758 374.756 530.065 383.012 530.065V535.935ZM471.986 535.935H383.012V530.065H471.986V535.935Z",
      "fill": "#ED6400",
      "mask": "url(#path-3-inside-2_504_49610)"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-5-inside-3_504_49610",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M163.695 637C157.236 637 152 642.236 152 648.695V688.086C152 694.545 157.236 699.78 163.695 699.78H166.754V712.121L177.782 699.78H250.305C256.764 699.78 262 694.545 262 688.086V648.695C262 642.236 256.764 637 250.305 637H163.695Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M163.695 637C157.236 637 152 642.236 152 648.695V688.086C152 694.545 157.236 699.78 163.695 699.78H166.754V712.121L177.782 699.78H250.305C256.764 699.78 262 694.545 262 688.086V648.695C262 642.236 256.764 637 250.305 637H163.695Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M166.754 699.78H170.754V695.78H166.754V699.78ZM166.754 712.121H162.754V722.601L169.737 714.787L166.754 712.121ZM177.782 699.78V695.78H175.992L174.799 697.115L177.782 699.78ZM156 648.695C156 644.445 159.445 641 163.695 641V633C155.027 633 148 640.027 148 648.695H156ZM156 688.086V648.695H148V688.086H156ZM163.695 695.78C159.445 695.78 156 692.336 156 688.086H148C148 696.754 155.027 703.78 163.695 703.78V695.78ZM166.754 695.78H163.695V703.78H166.754V695.78ZM162.754 699.78V712.121H170.754V699.78H162.754ZM169.737 714.787L180.765 702.446L174.799 697.115L163.771 709.456L169.737 714.787ZM250.305 695.78H177.782V703.78H250.305V695.78ZM258 688.086C258 692.336 254.555 695.78 250.305 695.78V703.78C258.973 703.78 266 696.754 266 688.086H258ZM258 648.695V688.086H266V648.695H258ZM250.305 641C254.555 641 258 644.445 258 648.695H266C266 640.027 258.973 633 250.305 633V641ZM163.695 641H250.305V633H163.695V641Z",
      "fill": "#ED6400",
      "mask": "url(#path-5-inside-3_504_49610)"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M572.856 195.903C577.852 195.903 581.903 191.852 581.903 186.856C581.903 181.859 577.852 177.809 572.856 177.809C567.859 177.809 563.809 181.859 563.809 186.856C563.809 191.852 567.859 195.903 572.856 195.903ZM578.02 184.782C578.373 184.429 578.373 183.856 578.02 183.503C577.667 183.149 577.094 183.149 576.74 183.503L571.952 188.291L569.877 186.217C569.524 185.864 568.951 185.864 568.598 186.217C568.245 186.57 568.245 187.143 568.598 187.496L571.312 190.21C571.665 190.564 572.238 190.564 572.592 190.21L578.02 184.782Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M655.856 565.903C660.852 565.903 664.903 561.852 664.903 556.856C664.903 551.859 660.852 547.809 655.856 547.809C650.859 547.809 646.809 551.859 646.809 556.856C646.809 561.852 650.859 565.903 655.856 565.903ZM661.02 554.782C661.373 554.429 661.373 553.856 661.02 553.503C660.667 553.149 660.094 553.149 659.74 553.503L654.952 558.291L652.877 556.217C652.524 555.864 651.951 555.864 651.598 556.217C651.245 556.57 651.245 557.143 651.598 557.496L654.312 560.21C654.665 560.564 655.238 560.564 655.592 560.21L661.02 554.782Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M347.856 405.903C352.852 405.903 356.903 401.852 356.903 396.856C356.903 391.859 352.852 387.809 347.856 387.809C342.859 387.809 338.809 391.859 338.809 396.856C338.809 401.852 342.859 405.903 347.856 405.903ZM353.02 394.782C353.373 394.429 353.373 393.856 353.02 393.503C352.667 393.149 352.094 393.149 351.74 393.503L346.952 398.291L344.877 396.217C344.524 395.864 343.951 395.864 343.598 396.217C343.245 396.57 343.245 397.143 343.598 397.496L346.312 400.21C346.665 400.564 347.238 400.564 347.592 400.21L353.02 394.782Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M101.856 688.903C106.852 688.903 110.903 684.852 110.903 679.856C110.903 674.859 106.852 670.809 101.856 670.809C96.8591 670.809 92.8086 674.859 92.8086 679.856C92.8086 684.852 96.8591 688.903 101.856 688.903ZM107.02 677.782C107.373 677.429 107.373 676.856 107.02 676.503C106.667 676.149 106.094 676.149 105.74 676.503L100.952 681.291L98.8774 679.217C98.5241 678.864 97.9513 678.864 97.598 679.217C97.2447 679.57 97.2447 680.143 97.598 680.496L100.312 683.21C100.665 683.564 101.238 683.564 101.592 683.21L107.02 677.782Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M704.577 153.629C716.204 153.629 725.629 144.204 725.629 132.577C725.629 120.95 716.204 111.525 704.577 111.525C692.95 111.525 683.525 120.95 683.525 132.577C683.525 144.204 692.95 153.629 704.577 153.629ZM704.577 158.892C719.11 158.892 730.892 147.11 730.892 132.577C730.892 118.043 719.11 106.262 704.577 106.262C690.043 106.262 678.262 118.043 678.262 132.577C678.262 147.11 690.043 158.892 704.577 158.892Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M709.176 148.831C711.12 144.943 712.474 139.204 712.474 132.577C712.474 125.95 711.12 120.211 709.176 116.323C707.075 112.12 705.145 111.525 704.579 111.525C704.014 111.525 702.084 112.12 699.983 116.323C698.039 120.211 696.685 125.95 696.685 132.577C696.685 139.204 698.039 144.943 699.983 148.831C702.084 153.034 704.014 153.629 704.579 153.629C705.145 153.629 707.075 153.034 709.176 148.831ZM704.579 158.892C711.846 158.892 717.737 147.11 717.737 132.577C717.737 118.043 711.846 106.262 704.579 106.262C697.313 106.262 691.422 118.043 691.422 132.577C691.422 147.11 697.313 158.892 704.579 158.892Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M730.762 135.208C730.848 134.343 730.892 133.465 730.892 132.577C730.892 131.689 730.848 130.811 730.762 129.945H678.392C678.306 130.811 678.262 131.689 678.262 132.577C678.262 133.465 678.306 134.343 678.392 135.208H730.762Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M126.577 277.629C138.204 277.629 147.629 268.204 147.629 256.577C147.629 244.95 138.204 235.525 126.577 235.525C114.95 235.525 105.525 244.95 105.525 256.577C105.525 268.204 114.95 277.629 126.577 277.629ZM126.577 282.892C141.11 282.892 152.892 271.11 152.892 256.577C152.892 242.043 141.11 230.262 126.577 230.262C112.043 230.262 100.262 242.043 100.262 256.577C100.262 271.11 112.043 282.892 126.577 282.892Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M131.176 272.831C133.12 268.943 134.474 263.204 134.474 256.577C134.474 249.95 133.12 244.211 131.176 240.323C129.075 236.12 127.145 235.525 126.579 235.525C126.014 235.525 124.084 236.12 121.983 240.323C120.039 244.211 118.685 249.95 118.685 256.577C118.685 263.204 120.039 268.943 121.983 272.831C124.084 277.034 126.014 277.629 126.579 277.629C127.145 277.629 129.075 277.034 131.176 272.831ZM126.579 282.892C133.846 282.892 139.737 271.11 139.737 256.577C139.737 242.043 133.846 230.262 126.579 230.262C119.313 230.262 113.422 242.043 113.422 256.577C113.422 271.11 119.313 282.892 126.579 282.892Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M152.762 259.208C152.848 258.343 152.892 257.465 152.892 256.577C152.892 255.689 152.848 254.811 152.762 253.945H100.392C100.306 254.811 100.262 255.689 100.262 256.577C100.262 257.465 100.306 258.343 100.392 259.208H152.762Z",
      "fill": "#07B53B"
    }
  }), _c('circle', {
    attrs: {
      "cx": "549.943",
      "cy": "31.9432",
      "r": "11.0245",
      "stroke": "#07B53B",
      "stroke-width": "1.83742"
    }
  }), _c('circle', {
    attrs: {
      "cx": "213.972",
      "cy": "233.972",
      "r": "5.0529",
      "stroke": "#07B53B",
      "stroke-width": "1.83742"
    }
  }), _c('circle', {
    attrs: {
      "cx": "342.972",
      "cy": "585.972",
      "r": "5.0529",
      "stroke": "#07B53B",
      "stroke-width": "1.83742"
    }
  }), _c('circle', {
    attrs: {
      "cx": "367.972",
      "cy": "502.972",
      "r": "5.0529",
      "stroke": "#07B53B",
      "stroke-width": "1.83742"
    }
  }), _c('circle', {
    attrs: {
      "cx": "317.972",
      "cy": "281.972",
      "r": "5.97161",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "16.9727",
      "cy": "280",
      "r": "16",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "496",
      "cy": "487",
      "r": "16",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "715",
      "cy": "611.5",
      "r": "7.5",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "443",
      "cy": "8",
      "r": "8",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M718.103 657.396H683.397C680.658 657.396 678.439 659.615 678.439 662.354V687.144C678.439 689.883 680.658 692.102 683.397 692.102H718.103C720.842 692.102 723.061 689.883 723.061 687.144V662.354C723.061 659.615 720.842 657.396 718.103 657.396ZM683.397 652.438C677.92 652.438 673.48 656.877 673.48 662.354V687.144C673.48 692.621 677.92 697.06 683.397 697.06H718.103C723.58 697.06 728.02 692.621 728.02 687.144V662.354C728.02 656.877 723.58 652.438 718.103 652.438H683.397Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M703.227 674.751C703.227 667.905 708.776 662.355 715.622 662.355H725.538C726.907 662.355 728.017 663.465 728.017 664.835C728.017 666.204 726.907 667.314 725.538 667.314H715.622C711.514 667.314 708.185 670.643 708.185 674.751C708.185 678.858 711.514 682.188 715.622 682.188H725.538C726.907 682.188 728.017 683.298 728.017 684.667C728.017 686.036 726.907 687.146 725.538 687.146H715.622C708.776 687.146 703.227 681.596 703.227 674.751Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M718.107 674.749C718.107 676.118 716.997 677.228 715.627 677.228C714.258 677.228 713.148 676.118 713.148 674.749C713.148 673.379 714.258 672.27 715.627 672.27C716.997 672.27 718.107 673.379 718.107 674.749Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }