var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.25 8.75C5.25 4.88401 8.38401 1.75 12.25 1.75H26.8505C28.707 1.75 30.4875 2.4875 31.8003 3.80025L34.6997 6.69975C36.0125 8.0125 36.75 9.79298 36.75 11.6495V33.25C36.75 37.116 33.616 40.25 29.75 40.25H12.25C8.38401 40.25 5.25 37.116 5.25 33.25V8.75ZM33.25 14V33.25C33.25 35.183 31.683 36.75 29.75 36.75H12.25C10.317 36.75 8.75 35.183 8.75 33.25V8.75C8.75 6.817 10.317 5.25 12.25 5.25H24.5V8.75C24.5 11.6495 26.8505 14 29.75 14H33.25ZM33.0559 10.5C32.884 10.0058 32.6019 9.55163 32.2249 9.17462L29.3254 6.27513C28.9484 5.89812 28.4942 5.616 28 5.44415V8.75C28 9.7165 28.7835 10.5 29.75 10.5H33.0559Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.7626 23.2587C12.0791 23.9421 12.0791 25.0501 12.7626 25.7335L16.2626 29.2335C16.946 29.9169 18.054 29.9169 18.7374 29.2335C19.4209 28.5501 19.4209 27.4421 18.7374 26.7587L16.4749 24.4961L18.7374 22.2335C19.4209 21.5501 19.4209 20.4421 18.7374 19.7587C18.054 19.0752 16.946 19.0752 16.2626 19.7587L12.7626 23.2587Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M25.7374 19.7587L29.2374 23.2587C29.9209 23.9421 29.9209 25.0501 29.2374 25.7335L25.7374 29.2335C25.054 29.9169 23.946 29.9169 23.2626 29.2335C22.5791 28.5501 22.5791 27.4421 23.2626 26.7587L25.5251 24.4961L23.2626 22.2335C22.5791 21.5501 22.5791 20.4421 23.2626 19.7587C23.946 19.0752 25.054 19.0752 25.7374 19.7587Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }