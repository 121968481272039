var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Hero', {
    attrs: {
      "gotoJobs": _vm.gotoJobs
    }
  }), _c('Principles'), _c('JobOpenings', {
    ref: "jobs"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }