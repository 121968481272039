var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M2.99988 5C2.99988 2.79086 4.79074 1 6.99988 1H15.343C16.4039 1 17.4213 1.42143 18.1715 2.17157L19.8283 3.82843C20.5785 4.57857 20.9999 5.59599 20.9999 6.65685V19C20.9999 21.2091 19.209 23 16.9999 23H6.99988C4.79074 23 2.99988 21.2091 2.99988 19V5ZM18.9999 8V19C18.9999 20.1046 18.1044 21 16.9999 21H6.99988C5.89531 21 4.99988 20.1046 4.99988 19V5C4.99988 3.89543 5.89531 3 6.99988 3H13.9999V5C13.9999 6.65685 15.343 8 16.9999 8H18.9999ZM18.8889 6C18.7907 5.7176 18.6295 5.45808 18.4141 5.24264L16.7572 3.58579C16.5418 3.37035 16.2823 3.20914 15.9999 3.11094V5C15.9999 5.55228 16.4476 6 16.9999 6H18.8889Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M8 3.5C8 3.22386 8.22386 3 8.5 3H9.5C9.77614 3 10 3.22386 10 3.5V4.5C10 4.77614 9.77614 5 9.5 5H8.5C8.22386 5 8 4.77614 8 4.5V3.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M8 7.5C8 7.22386 8.22386 7 8.5 7H9.5C9.77614 7 10 7.22386 10 7.5V8.5C10 8.77614 9.77614 9 9.5 9H8.5C8.22386 9 8 8.77614 8 8.5V7.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M10 9.5C10 9.22386 10.2239 9 10.5 9H11.5C11.7761 9 12 9.22386 12 9.5V10.5C12 10.7761 11.7761 11 11.5 11H10.5C10.2239 11 10 10.7761 10 10.5V9.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.5 11C8.22386 11 8 11.2239 8 11.5V12.5C8 12.7761 8.22386 13 8.5 13H9.5C9.77614 13 10 12.7761 10 12.5V11.5C10 11.2239 9.77614 11 9.5 11H8.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M10 13.5C10 13.2239 10.2239 13 10.5 13H11.5C11.7761 13 12 13.2239 12 13.5V14.5C12 14.7761 11.7761 15 11.5 15H10.5C10.2239 15 10 14.7761 10 14.5V13.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.5 15C8.22386 15 8 15.2239 8 15.5V16.5C8 16.7761 8.22386 17 8.5 17H9.5C9.77614 17 10 16.7761 10 16.5V15.5C10 15.2239 9.77614 15 9.5 15H8.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M10 17.5C10 17.2239 10.2239 17 10.5 17H11.5C11.7761 17 12 17.2239 12 17.5V18.5C12 18.7761 11.7761 19 11.5 19H10.5C10.2239 19 10 18.7761 10 18.5V17.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.5 19C8.22386 19 8 19.2239 8 19.5V20.5C8 20.7761 8.22386 21 8.5 21H9.5C9.77614 21 10 20.7761 10 20.5V19.5C10 19.2239 9.77614 19 9.5 19H8.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.5 5C10.2239 5 10 5.22386 10 5.5V6.5C10 6.77614 10.2239 7 10.5 7H11.5C11.7761 7 12 6.77614 12 6.5V5.5C12 5.22386 11.7761 5 11.5 5H10.5Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }