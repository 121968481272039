var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "38",
      "height": "38",
      "viewBox": "0 0 38 38",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M22.5787 12.4928C23.1784 13.0925 23.1784 14.0649 22.5787 14.6646L18.2896 18.9537L22.5787 23.2428C23.1784 23.8425 23.1784 24.8149 22.5787 25.4146C21.9789 26.0143 21.0066 26.0143 20.4068 25.4146L15.0318 20.0396C14.4321 19.4399 14.4321 18.4675 15.0318 17.8678L20.4068 12.4928C21.0066 11.893 21.9789 11.893 22.5787 12.4928Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }