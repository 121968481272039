<template>
  <div class="jobs section">
    <h2 class="light-theme-title text-center">
      {{ $t("careers.current-jobs.title") }}
    </h2>
    <div class="jobs-items">
      <div
        v-for="(job, index) in $t('careers.current-jobs.jobs')"
        :key="index"
        class="jobs-item d-flex align-items-center justify-content-between"
        v-b-modal="`modal-${index}`"
      >
        <span class="d-flex align-items-center">
          <span>
            <component class="icon" v-bind:is="`Icon${index}`"></component>
          </span>
          <h3 class="h3trans light-theme-title">
            {{ job.title }}
          </h3>
        </span>
        <span class="d-none d-md-inline-block" style="margin-right: 0">
          <font-awesome-icon
                      icon="chevron-right"
                      style="height: 18px; width: 11px"
                    />
        </span>
      </div>
    </div>

    <h3 class="text-center light-theme-title let">
      {{ $t("careers.current-jobs.subtitle") }}
      <span class="text-primary">
        {{ $t("careers.current-jobs.subtitle2") }}
      </span>
    </h3>

    <b-modal
      v-for="(job, index) in $t('careers.current-jobs.jobs')"
      :key="index"
      :id="`modal-${index}`"
      size="lg"
      :hide-header="true"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <span
        @click="handleCloseModal(`modal-${index}`)"
        class="position-absolute close-icon d-flex justify-content-center align-items-center close-icon"
      >
        <CloseIcon />
      </span>
      <div class="jobs-description">
        <div class="d-none d-md-block">
          <p class="light-theme-title d-none d-md-block subtitle-bodyLarge" style="margin-bottom: 8px !important">
            <span class="text-primary">{{ job.title }}</span>
          </p>
          
        </div>
        <div class="d-md-none">
          <h1 class="text-primary">
            {{ job.title }}
          </h1>
        </div>
        <div class="body-paragraph-1 text-grey-primary-main-500 text-description" style="margin-bottom: 35px !important">
          <p style="margin-bottom: 0 !important">{{ job.location }}</p>
          <p style="margin-bottom: 0 !important">{{job.contract_type}}</p>
        </div>
        <div>
          <p class="body-paragraph-1 text-description">{{job.description_0}}</p>
          <p class="body-paragraph-1 text-description">{{job.description_1}}</p>
          <p class="body-paragraph-1 text-description">{{job.description_2}}</p>
        </div>
        <div v-if="currentStep === 'jobs-description'">
          <div class="jobs-requirements" style="margin-top: 35px !important">
            <h3 class="text-primary text-subheader">
              {{ job.requirements.title }}
            </h3>
            <p
              v-for="requirement in job.requirements.all"
              :key="requirement"
              class="text-grey-primary-main-500 body-paragraph-1 text-content"
            >
              <span>
                <SubtractIcon />
              </span>
              {{ requirement }}
            </p>
          </div>

          <div
            class="jobs-responsibilities"
            style="margin-top: 24px !important"
          >
            <h3 class="text-primary text-subheader">
              {{ job.responsibilities.title }}
            </h3>
            <p
              v-for="responsibility in job.responsibilities.all"
              :key="responsibility"
              class="text-grey-primary-main-500 body-paragraph-1 text-content"
              
            >
              <span>
                <SubtractIcon />
              </span>
              {{ responsibility }}
            </p>
          </div>

          <div class="jobs-benefits" style="margin-top: 24px !important">
            <h3 class="text-primary text-subheader">
              {{ job.benefits.title }}
            </h3>
            <p
              v-for="ben in job.benefits.all"
              :key="ben"
              class="text-grey-primary-main-500 body-paragraph-1 text-content"
            >
              <span>
                <SubtractIcon />
              </span>
              {{ ben }}
            </p>
          </div>
          <div
            class="jobs-buttons d-flex justify-content-center"
            style="gap: 16px"
          >
            <b-button
              variant="primary"
              class="round-btn-16-24"
              @click="
                modifiedTitle = true;
                currentStep = 'apply';
              "
            >
              <span class="text-white-button button-medium">
                {{ $t("careers.current-jobs.buttons.apply") }}
                <span class="d-none d-md-inline">{{
                  $t("careers.current-jobs.buttons.apply2")
                }}</span>
              </span>
            </b-button>
          </div>
        </div>
        <div v-if="currentStep === 'apply'">
          <ApplicationForm
            :title="job.title"
            :index="index"
            currentStep="jobs-descriptions"
            @changeStep="handleChangeStep"
            @modifyTitle="modifiedTitle = true"
            @hideTitle="modifiedTitle = false"
          />
        </div>
      </div>
    </b-modal>
    <b-modal
      centered
      id="job-application-form"
      size="md"
      :hide-header="true"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      class="job-application-form"
    >
      <p @click="$bvModal.hide('job-application-form')" class="text-right">
        <CloseForm />
      </p>

      <div class="text-center">
        <JobApplication class="mb-22px"></JobApplication>
        <p class="job-application-form__title roboto">Thank you!</p>
        <p class="job-application-form__body">
          Your resume has been successfully submitted.
        </p>
        <b-button
          variant="primary"
          class="button-done"
          @click="$bvModal.hide('job-application-form')"
        >
          <span class="text-white-button button-medium"> Done </span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Chevron from "../svg/icons/Chevron.vue";
import CloseForm from "../svg/job-application-form/CloseForm.vue";
import JobApplication from "../svg/job-application-form/JobApplicationFormSuccess.vue";
import Icon0 from "../svg/icons/Icon0.vue";
import Icon1 from "../svg/icons/Icon1.vue";
import Icon2 from "../svg/icons/Icon2.vue";
import Icon3 from "../svg/icons/Icon3.vue";
import Icon4 from "../svg/icons/Icon4.vue";

import CloseIcon from "@/components/icons/CloseIcon.vue";
import SubtractIcon from "@/components/icons/SubtractIcon.vue";
import ChevronLeft from "@/components/icons/ChevronLeft.vue";
import ApplicationForm from "./ApplicationForm.vue";
export default {
  components: {
    JobApplication,
    CloseForm,
    Chevron,
    Icon1,
    Icon2,
    Icon3,
    Icon0,
    Icon4,
    SubtractIcon,
    ChevronLeft,
    CloseIcon,
    ApplicationForm,
  },
  data() {
    return {
      currentStep: "jobs-description",
      modifiedTitle: false,
      hover: false,
    };
  },
  methods: {
    handleChangeStep() {
      this.currentStep = "jobs-description";
    },
    handleCloseModal(id) {
      this.$bvModal.hide(id);
      this.currentStep = "jobs-description";
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        for (let i = 0; i < this.$t("careers.current-jobs.jobs").length; i++) {
          this.handleCloseModal(`modal-${i}`);
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>

.text-subheader {
  @media (min-width: 1023px) {
    font-size: 20px !important;
    margin-bottom: 8px !important;
  }
}

.text-description {
  color: #616a70;
  @media (min-width: 1023px) {
    font-size: 16px !important;
    margin-bottom: 12px !important;
  }
}

.text-content {
  @media (min-width: 1023px) {
    font-size: 16px !important;
    margin-bottom: 0px !important;
  }
}
::v-deep .modal-content {
  border-radius: 24px;
  @media (max-width: 767px) {
    width: 340px;
    margin: auto;
  }
}
::v-deep .modal-body {
  padding: 28px;
  @media (max-width: 767px) {
    padding: 24px;
  }
}
.mb-22px {
  margin-bottom: 22px;
}
.job-application-form__title {
  height: 29px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #39454c;
}
.job-application-form__body {
  margin: auto;
  width: 255px;
  height: 38px;
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #616a70;
  margin-bottom: 22px;
}
.button-done {
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 164px;
  height: 48px;
  background: #07b53b;
  border-radius: 90px;
  margin-bottom: 0;
}

.jobs {
  @media (max-width: 767px) {
    margin-bottom: 64px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 150px;
    margin-bottom: 61px;
  }
  @media (min-width: 1200px) {
    margin-top: 100px;
    margin-bottom: 96px;
  }
  .jobs-items {
    .jobs-item {
      cursor: pointer;
      /* border-bottom: 2px solid var(--primary); */
      border-bottom: 1px solid #e6e8ec;
      @media (max-width: 767px) {
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        padding-bottom: 15px;
        margin-bottom: 15px;
      }
      @media (min-width: 1200px) {
        padding-bottom: 22px;
        margin-bottom: 22px;
      }
      &:hover{
        color: var(--primary);
      }
    }
  }
}

#small-location {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Light Theme/Title Color */

  color: #39454c;
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
}

.icon {
  @media (min-width: 768px) {
    width: 37px;
    height: 37px;
  }
  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
  }
}

.round-btn-16-24-outline {
  @media (min-width: 768px) {
    height: 50px !important;
    width: 197px !important;
  }
}
::v-deep .modal-content {
  max-width: 850px;
  margin-top: calc(20vh);
  background: #ffffff;
  border-radius: 24px;
}
h2 {
  @media (max-width: 767px) {
    margin-top: 60px;
    margin-bottom: 44px !important;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin-top: 57px !important;
    margin-bottom: 72px !important;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    margin-top: 114px !important;
    margin-bottom: 76px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
.h3trans {
  @media (max-width: 767px) {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-left: 8px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-left: 16px;
  }
  @media (min-width: 1200px) {
    margin-left: 21px;
  }
}

.jobs-buttons {
  margin-bottom: 40px !important;
  margin-top: 64px;
}
.round-btn-16-24-outline {
  @media (max-width: 767px) {
    width: 130px !important;
  }
}
.round-btn-16-24 {
  @media (max-width: 767px) {
    width: 100% !important;
  }
}
h1 {
  @media (max-width: 767px) {
    margin-bottom: 8px !important;
    margin-top: 65px !important;
    font-size: 24px !important;
    font-weight: 500 !important;
  }
}
.jobs-requirements {
  @media (max-width: 767px) {
    margin-top: 32px !important;
  }
  @media (min-width: 768px) {
    margin-top: 33px !important;
  }
}
h3 {
  @media (max-width: 767px) {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
}
h3[class="text-primary"] {
  margin-bottom: 16px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 24px !important;
  line-height: 120% !important;
}
.subtitle-bodyLarge {
  @media (min-width: 768px) {
    margin-top: 0 !important;
    margin-bottom: 3px !important;
  }
}
.close-icon {
  &:hover {
    cursor: pointer;
  }
  right: 21px !important;
  top: 21px !important;
  width: 40px;
  height: 40px;

  /* Neutrals/6 */

  border: 2px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 48px;
}
.jobs-description {
  @media (min-width: 768px) {
    margin-top: 40px !important;
    margin-left: 34px !important;
    margin-right: 34px !important;
  }
}
.text-grey-primary-main-500 {
  margin-bottom: 4px !important;
  @media (max-width: 767px) {
    margin-bottom: 8px !important;
  }
}
.let {
  @media (max-width: 767px) {
    margin-top: 64px !important;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 45px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 76px !important;
  }
}
</style>
