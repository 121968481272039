<template>
  <div class="principles section">
    <h2 class="text-center light-theme-title">
      {{ $t("careers.focus.title") }}
    </h2>
    <h4
      class="text-center subtitle-bodyLarge text-grey-primary-main-500"
      :class="$i18n.locale == 'en' ? 'maxWidth440' : 'maxWidth640'"
    >
      {{ $t("careers.focus.subtitle") }}
    </h4>

    <div class="principles-content position-relative">
      <div class="d-none d-md-flex d-xl-none justify-content-center">
        <PrinciplesTabletIcon />
      </div>
      <div class="d-none d-xl-flex justify-content-center">
        <PrinciplesPCIcon />
      </div>
      <div
        class="d-md-none w-100 d-flex justify-content-center position-absolute"
        style="top: 20%; z-index: -1"
      >
        <PrinciplesMobileIcon style="margin: -5px auto auto auto" />
      </div>

      <div class="principles-item career-path">
        <div class="pc pc-ca" :class="$i18n.locale == 'ja' ? 'pc-ca-JP' : ''">
          <h3 class="text-center" :class="$i18n.locale == 'ja' ? 'h3-JP' : ''">
            {{ $t("careers.message.path.title") }}
          </h3>
          <p
            class="body-paragraph-1-to-mobile-body-regular text-center text-grey-primary-main-500"
          >
            {{ $t("careers.message.path.text") }}
          </p>
        </div>
        <LeftLeft class="d-md-none w-100" />
        <LeftLeftEx class="d-none d-md-block" />
      </div>

      <div class="principles-item freedom">
        <div class="pc pc-fe" :class="$i18n.locale == 'ja' ? 'pc-fe-JP' : ''">
          <h3 class="text-center" :class="$i18n.locale == 'ja' ? 'h3-JP' : ''">
            {{ $t("careers.message.freedom.title") }}
          </h3>
          <p
            class="body-paragraph-1-to-mobile-body-regular text-center text-grey-primary-main-500"
          >
            {{ $t("careers.message.freedom.text") }}
          </p>
        </div>
        <LeftRight class="d-md-none w-100" />
        <RightRightFreedom class="d-none d-md-block" />
      </div>

      <div class="principles-item diversity">
        <div class="pc pc-di" :class="$i18n.locale == 'ja' ? 'pc-di-JP' : ''">
          <h3 class="text-center" :class="$i18n.locale == 'ja' ? 'h3-JP' : ''">
            {{ $t("careers.message.culture.title") }}
          </h3>
          <p
            class="body-paragraph-1-to-mobile-body-regular text-center text-grey-primary-main-500"
          >
            {{ $t("careers.message.culture.text") }}
          </p>
        </div>
        <LeftLeft class="d-md-none w-100" />
        <RightRight class="d-none d-md-block" />
      </div>

      <div class="principles-item collaboration">
        <div class="pc pc-co" :class="$i18n.locale == 'ja' ? 'pc-co-JP' : ''">
          <h3 class="text-center" :class="$i18n.locale == 'ja' ? 'h3-JP' : ''">
            {{ $t("careers.message.collaboration.title") }}
          </h3>
          <p
            class="body-paragraph-1-to-mobile-body-regular text-center text-grey-primary-main-500"
          >
            {{ $t("careers.message.collaboration.text") }}
          </p>
        </div>
        <LeftRight class="d-md-none w-100" />
        <LeftLeftCollab class="d-none d-md-block" />
      </div>
    </div>
  </div>
</template>

<script>
import PrinciplesPCIcon from "../svg/principles/PrinciplesPCIcon.vue";
import PrinciplesTabletIcon from "../svg/principles/PrinciplesTabletIcon.vue";
import PrinciplesMobileIcon from "../svg/principles/PrinciplesMobileIcon.vue";
import LeftLeftCollab from "../svg/icons/LeftLeftCollab.vue";
import LeftRight from "../svg/icons/LeftRight.vue";
import RightRight from "../svg/icons/RightRight.vue";
import RightRightFreedom from "../svg/icons/RightRightFreedom.vue";
import LeftLeftEx from "../svg/icons/LeftLeftEx.vue";
import LeftLeft from "../svg/icons/LeftLeft.vue";
export default {
  components: {
    PrinciplesPCIcon,
    PrinciplesTabletIcon,
    PrinciplesMobileIcon,
    LeftLeftCollab,
    LeftRight,
    RightRight,
    RightRightFreedom,
    LeftLeftEx,
    LeftLeft,
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: #484c57;
  @media (min-width: 1200px) {
    font-size: 32px !important;
  }
}
h4 {
  @media (min-width: 768px) {
    margin: 24px auto 0 auto !important;
  }
}
.principles {
  @media (max-width: 767px) {
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 250px;
  }
  @media (min-width: 1200px) {
    margin-top: 15px;
  }
  .principles-content {
    @media (max-width: 767px) {
    }

    @media (max-width: 1199px) and (min-width: 768px) {
      margin-top: 77px;
    }
    @media (min-width: 1200px) {
      margin-top: 125px;
    }
    .principles-item {
      .pc {
        position: absolute;
        width: 254px;
        left: 50%;
        top: 80px;
        transform: translate(-50%, -50%);
        h3 {
          @media (max-width: 767px) {
            margin-bottom: 16px !important;
            font-weight: 500 !important;
            font-size: 24px !important;
            line-height: 100% !important;
          }

          @media (max-width: 1199px) and (min-width: 768px) {
            margin-bottom: 16px !important;
          }
          @media (min-width: 1200px) {
            margin-bottom: 8px !important;
          }
        }

        .h3-JP {
          @media (max-width: 1199px) {
            margin-bottom: 8px !important;
          }
        }

        h3,
        p {
          @media (max-width: 767px) {
          }

          @media (max-width: 1199px) and (min-width: 768px) {
            text-align: left !important;
          }
          @media (min-width: 1200px) {
            text-align: left !important;
          }
        }
      }
      position: relative;

      margin: 0 auto 45px auto;
      @media screen and (min-width: 768px) {
        position: absolute !important;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .collaboration {
        top: -3%;
        left: 7%;
      }

      .career-path {
        top: 50%;
        left: 0;
      }

      .diversity {
        top: 35%;
        right: 2%;
      }

      .freedom {
        bottom: -24%;
        right: 20%;
      }
    }

    @media screen and (min-width: 1200px) {
      .collaboration {
        top: -11%;
        left: 17%;
      }

      .career-path {
        top: 43%;
        left: 1%;
      }

      .diversity {
        top: 34%;
        right: -2%;
      }

      .freedom {
        bottom: -15%;
        right: 29%;
      }
    }
  }
}
h4 {
  @media (max-width: 767px) {
    margin-top: 24px !important;
    margin-bottom: 36px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 230px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
    max-width: 464px !important;
  }
}
.pc-co {
  @media (max-width: 767px) {
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 10px !important;
    /* width: 310px !important; */
  }
  @media (min-width: 1200px) {
    margin-top: 10px !important;
    width: 250px !important;
  }
}
.pc-co-JP {
  @media (max-width: 767px) {
    margin-top: 20px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 12px !important;
    /* width: 310px !important; */
  }
  @media (min-width: 1200px) {
    margin-top: 12px !important;
  }
}
.pc-ca {
  @media (max-width: 767px) {
    margin-top: 10px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    width: 310px !important;
    margin-top: 10px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 10px !important;
    width: 310px !important;
  }
}
.pc-ca-JP {
  @media (max-width: 767px) {
    margin-top: 15px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 30px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 30px !important;
  }
}
.pc-di {
  @media (max-width: 767px) {
    margin-top: 10px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    width: 294px !important;
    margin-top: 10px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 12px !important;
    width: 294px !important;
  }
}
.pc-di-JP {
  @media (max-width: 767px) {
    margin-top: 10px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 30px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 30px !important;
  }
}
.pc-fe {
  /* @media (max-width: 767px) {
    margin-top: 10px !important;
  } */

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 10px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 10px !important;
    width: 248px !important;
  }
}
.pc-fe-JP {
  @media (max-width: 767px) {
    margin-top: 12px !important;
  }
}
h3 {
  white-space: nowrap;
}
.maxWidth440 {
  max-width: 440px !important;
}
.maxWidth640 {
  max-width: 590px !important;
}

.marginShrink {
  margin-top: 0px !important;
}
</style>
